import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify, Auth } from "aws-amplify";

import { ThemeProvider } from "@aws-amplify/ui-react";
import { StateProvider } from "./StateContext";

import "@aws-amplify/ui-react/styles.css";
//import { studioTheme } from "./ui-components";
import { BrowserRouter } from "react-router-dom";

import config from "./aws-exports";
//Amplify.configure(config);      // HCSNOTE:  we need to configure our own API endpoint, so we can't use this, but we can use the variables in the file for the user pool

// have to do the configuration manually and keep this updated with data from aws-exports because
//  we are importing our own external serverless API instead of using Amplify to create it
Amplify.configure({
  Auth: {
    region: config.aws_project_region, // "us-east-2",
    userPoolId: config.aws_user_pools_id, // "us-east-2_zVOmWMGYj",
    userPoolWebClientId: config.aws_user_pools_web_client_id, // "6ipl392934uvl0nt0e3hufrsot"
  },
  API: {
    endpoints: [
      {
        name: "idpro",
        endpoint: process.env.REACT_APP_INSIGHT_API,

        // "https://sb81c31zi1.execute-api.us-east-2.amazonaws.com/dev"
      },
    ],
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.NODE_ENV == "production") console.log = () => {}; // turn off console.log in production

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <StateProvider>
        <App />
      </StateProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
