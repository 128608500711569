import styled from "styled-components";
import { TabList } from "react-tabs";
import { Card } from "@aws-amplify/ui-react";

export const StyledTabList = styled(TabList)`
  padding: 0;
  margin-bottom: 0;

  li {
    font-size: 18px;
    font-weight: bold;
  }

  li:not(:first-child) {
    margin-left: 10px;
  }

  .react-tabs__tab {
    background-color: white;
    border-radius: 8px 8px 0 0;
  }

  .react-tabs__tab--selected {
    background-color: #0b97ac;
    color: white;
    border-color: #0b97ac;
    border-radius: 8px 8px 0 0;
  }
`;

export const StyledTabColumn = styled.div`
  display: flex;
  flex-direction: column;

  & > div:first-child {
    margin-top: 0;
  }
`;

export const StyledCard = styled(Card)``;
