import { API } from "aws-amplify";
import { getApiRequestInfo } from "../../utils";

interface fetchRankingDataArgs {
  chatHistoryId?: string;
  userMessage?: string;
  isConsecutiveRequest?: boolean;
  logHistory?: boolean
}

export async function fetchGptChatResponseNew({
  chatHistoryId,
  userMessage,
  isConsecutiveRequest,
  logHistory,
}: fetchRankingDataArgs): Promise<any> {
  // remove parts o the chat history that are not needed for chatGPT eg. tableData

  const requestInfo = await getApiRequestInfo({
    chatHistoryId: chatHistoryId ?? null,
    userMessage: userMessage ?? null,
    isConsecutiveRequest: isConsecutiveRequest ?? null,
    logHistory: logHistory ?? null,
  });
  const url = "/aiinsight";

  try {
    return await API.post("idpro", url, requestInfo);
  } catch (error) {
    console.error("Error querying based on GPT's response: ", error);
    throw new Error("Failed to fetch Redshift Insights");
  }
}
