import { styled } from "styled-components";
import { ScrollView } from "@aws-amplify/ui-react";

export const TableCardWrapper = styled.div<{ fullHeight?: boolean }>`
  margin-top: 0rem;
  margin-right: 0.625rem;
  box-sizing: border-box;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  height: ${({ fullHeight }) => (fullHeight ? "100vh" : "auto")};
`;

export const CardTitle = styled.div`
  font-size: 18px;
  color: #0b97ac;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
`;

export const ChartWrapper = styled.div<{ fullHeight?: boolean }>`
  position: relative;
  overflow: hidden;
  margin-top: 0.25rem;
  flex: 1;
  display: flex;
  height: ${({ fullHeight }) => (fullHeight ? "calc(100vh - 40px - 1.25rem)" : "auto")}; // Adjust the height as needed
`;

export const StyledScrollView = styled(ScrollView)<{ noData?: boolean, fullHeight?: boolean }>`
  width: 100%;
  height: ${({ fullHeight }) => (fullHeight ? "100%" : "500px")};
  overflow-y: auto;
  flex: 1;
  ${({ noData }) =>
    noData &&
    `
    height: 100px;
    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export const StyledPlaceholder = styled.div`
  height: 40px;
  width: 40px;
`;

export const StyledNoData = styled.p`
  text-align: center;
  margin-top: 0;
  font-weight: bold;
`;

