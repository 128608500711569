import { API } from "aws-amplify";
import { getApiRequestInfo } from "../../utils";

export async function postUserAuthLogging(): Promise<any> {

  const requestInfo = await getApiRequestInfo(null);
  const url = "/postuserauthlogs";

  try {
    await API.post("idpro", url, requestInfo);
  } catch (error) {
    console.error("Error requesting postUserAuthLogging... ", error);
    throw new Error("Error requesting postUserAuthLogging");
  }
}

