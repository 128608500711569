import React from 'react';

const MFAMethodChoiceView = ({ handleMfaMethodChange, handleSignOut }) => {
  return (
    <div className="amplify-flex" style={{ flexDirection: 'column', gap: '1rem' }}>
      <h3 className="amplify-heading amplify-heading--3">Setup Multi-Factor Authentication</h3>
      <p className="amplify-paragraph">
        Multi-Factor Authentication (MFA) adds an extra layer of security to your PFC Insight account by requiring
        additional verification steps during the login process. By setting up MFA, you can protect your
        account from unauthorized access and ensure the safety of your sensitive information.
      </p>
      <p className="amplify-paragraph">
        There are two methods available to set up MFA:
      </p>
      <p className="amplify-paragraph">
        <strong>SMS MFA:</strong> This method requires phone number verification. You will receive a verification code via SMS to your registered phone number.
      </p>

      <p className="amplify-paragraph">
        <strong>App-based MFA:</strong> This method requires a mobile app such as Google Authenticator or Authy. You will need to scan a QR code or manually enter a secret key to set up the app-based MFA.
      </p>
      <button
        className="amplify-button amplify-button--primary"
        onClick={() => handleMfaMethodChange('SMS')}
      >
        Set up SMS MFA
      </button>
      <button
        className="amplify-button amplify-button--primary"
        onClick={() => handleMfaMethodChange('TOTP')}
      >
        Set up App-based MFA
      </button>
      <button
        className="amplify-button amplify-field-group__control amplify-button--link amplify-button--small"
        onClick={handleSignOut}
      >
        Back to Sign In
      </button>
    </div>
  );
};

export default MFAMethodChoiceView;
