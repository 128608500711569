import React, { useState, useEffect, useRef } from "react";
import { ChatHistoryType, ChatHistoryMessageType, BackendChatHistoryMessageType } from "./types";
import { fetchGptChatResponse } from "../../api/utils/fetchGptChatResponse/fetchGptChatResponse";
import { fetchGptChatHistoryById } from "../../api/utils/fetchGptChatHistoryById/fetchGptChatHistoryById";
import { addChatMessage, removeLoadingChatMessage, isAIInsightContentType } from "./utils";
import ChatInput from "./ChatInput/ChatInput";
import {
  ChatClientContainer,
  StyledTopBar,
  StyledHeading,
  ChatScrollContainer,
  StyledLinearProgress,
} from "./styles";
import { ChatMessage } from "./ChatMessage/ChatMessage";
import { WelcomeMessage } from "./WelcomeMessage/WelcomeMessage";

const ChatClient: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chatHistoryUIMessages, setChatHistoryUIMessages] = useState<ChatHistoryMessageType[]>([]);
  const [historyId, setHistoryId] = useState<string>('');

  const chatHistoryId = useRef<string>('');
  const conversationTitle = useRef<string>('Title');

  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollDown = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const triggerChatError = (errorMessage?: string) => {
    addChatMessage(
      "assistant",
      errorMessage || "Error: please try again or edit your query",
      setChatHistoryUIMessages,
      undefined,
      undefined,
      "gptResponseError",
    );
  };

  const handleSubmit = async (userMessage: string) => {
    setIsLoading(true);

    addChatMessage(
      "user",
      userMessage,
      setChatHistoryUIMessages,
      undefined,
      undefined,
      "userMessage",
    );

    try {
      let requestIsRunning = true;
      let isConsecutiveRequest = false;
      let loopCounter = 0;
      while (requestIsRunning && loopCounter < 2) {
        const response = await fetchGptChatResponse({ userMessage, isConsecutiveRequest, logHistory: true, chatHistoryId: historyId});
        const { chatHistoryTitle, query, chatResponse, needConsecutiveRequest, hadFailure } = response;

        // if backend responded - note chatResponse was previously not set if it was a consecutive request
        if ((chatResponse || isConsecutiveRequest)) { // } && query) {
          chatHistoryId.current = response.chatHistoryId;
          setHistoryId(response.chatHistoryId);

          conversationTitle.current = chatHistoryTitle;
          
          addChatMessage(
            "assistant",
            chatResponse,
            setChatHistoryUIMessages,
            query,
            response?.queryResults,
            "gptResponse",
          );
        }

        if (needConsecutiveRequest) {
          let inBetweenResponse = hadFailure ? loopCounter > 1 ? "Let my try one last time..." : "Something went wrong! Let me try again" : "Let me fetch the Database Results...";
          addChatMessage(
            "assistant",
            inBetweenResponse,
            setChatHistoryUIMessages,
            "",
            undefined,
            "gptResponse",
          );
        } else {
          requestIsRunning = false;
        }

        isConsecutiveRequest = needConsecutiveRequest as boolean;
        loopCounter++;
      }
    } catch (error) {
      // Handle error
      triggerChatError();
    } finally {
      setIsLoading(false);
    }
  };

  const isJSONParsable = (str: string): boolean => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const fetchChatHistory = async (historyId: string) => {
    setIsLoading(true);
    try {
      const fetchedChatHistory = await fetchGptChatHistoryById({chatHistoryId: historyId});
      fetchedChatHistory.messages.forEach((historyMessage: BackendChatHistoryMessageType) => {
        const chatContent: string = isJSONParsable(historyMessage.content) && isAIInsightContentType(JSON.parse(historyMessage.content)) ? JSON.parse(historyMessage.content).chatResponse : historyMessage.content;
        addChatMessage( 
          historyMessage.role,
          chatContent,
          setChatHistoryUIMessages,
          isAIInsightContentType(historyMessage.content) ? historyMessage.content.query : "",
          undefined,
          "gptResponse",
        );
      });
      conversationTitle.current = fetchedChatHistory.title;
    } catch (error) {
      console.error('error: ', error);
      triggerChatError();
    } finally {
      setIsLoading(false);
    }
  };
  /** 
  useEffect(() => {
    if (historyId) {
      localStorage.setItem('chatHistoryId', historyId);
      chatHistoryUIMessages.length === 0 && fetchChatHistory(historyId);
    }
  }, [historyId]);
**/

  useEffect(() => {
    const storedHistoryId = localStorage.getItem('chatHistoryId');
    if (storedHistoryId) {
      setHistoryId(storedHistoryId);
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      addChatMessage(
        "assistant",
        "loading",
        setChatHistoryUIMessages,
        "",
        undefined,
        "loading",
      );
    }
    if (!isLoading) {
      removeLoadingChatMessage(setChatHistoryUIMessages);
    }
    scrollDown();
  }, [isLoading]);

  return (
    <div>
      <StyledTopBar>
        <StyledHeading level={4} placeholder={null}>
        
        </StyledHeading>
      </StyledTopBar>

      <ChatClientContainer>
        <ChatScrollContainer placeholder={null} ref={scrollRef}>
          {chatHistoryUIMessages.length === 0 && <WelcomeMessage />}
          {chatHistoryUIMessages.map((chat, index) => (
            <ChatMessage
              key={index}
              conversationTitle={conversationTitle.current}
              chatItem={chat}
            />
          ))}

        </ChatScrollContainer>
        {isLoading && <StyledLinearProgress variant={"indeterminate"} />}
        <ChatInput
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </ChatClientContainer>
    </div>
  );
};

export default ChatClient;
