import { Auth } from "aws-amplify";

export const getApiRequestInfo = async (body) => {
  try {
    // Get the current session which will refresh the token if it's expired
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    // Return the request info with the fresh token
    const requestInfo = {
      body: body,
      headers: {
        Authorization: token,
      },
    };
    // console.log('requestInfo:\n', requestInfo);
    return requestInfo;
  } catch (error) {
    console.error("Error getting the token", error);
    // Handle the error accordingly. You might want to redirect to sign-in page or show an error message
    return {};
  }
};



