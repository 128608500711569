import React from "react";
import ExportTableDataIcon from "./ExportTableDataIcon";
import { Card } from "@aws-amplify/ui-react";
import {
  CardTitle,
  TableCardWrapper,
  ChartWrapper,
  StyledScrollView,
  StyledPlaceholder, StyledNoData
} from "./styles";
import { Dayjs } from "dayjs";

interface TableCardProps {
  title: string;
  children: React.ReactElement;
  selectedDate: Dayjs;
  backgroundColor?: string;
  tableDataProp?: any;
  noData?: boolean;
  isLoading?: boolean;
  fullHeight?: boolean;
  excelExport?: boolean;
}

function TableCard({
  title,
  children,
  selectedDate,
  tableDataProp = null,
  backgroundColor = "#FFF",
  noData = false,
  isLoading = false,
  fullHeight = false,
  excelExport = true
}: TableCardProps) {
  return (
    <TableCardWrapper fullHeight={fullHeight}>
      <Card
        height="100%"
        borderRadius="15px"
        backgroundColor={backgroundColor}
        placeholder={undefined}
      >
        <CardTitle>
          {title}
          {tableDataProp && tableDataProp.length > 0 && excelExport ? (
            <ExportTableDataIcon
              tableData={tableDataProp}
              title={title}
              selectedDate={selectedDate}
            />
          ) : <StyledPlaceholder />}
        </CardTitle>
        <ChartWrapper fullHeight={fullHeight}>
          <StyledScrollView noData={noData} fullHeight={fullHeight} placeholder={undefined}>
            {children}
            {noData && !isLoading && <StyledNoData>{`No activity on ${selectedDate.format("MM/DD/YYYY")}`}</StyledNoData>}
          </StyledScrollView>
        </ChartWrapper>
      </Card>
    </TableCardWrapper>
  );
}

export default TableCard;

