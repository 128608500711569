import { NumberFormat } from "@aws-amplify/ui-react";
const dayjs = require("dayjs");
const customParseFormat = require("dayjs/plugin/customParseFormat");

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

// Function to calculate working days in a month using Day.js
export function getTotalWorkingDaysInMonth(date) {
  const startDate = dayjs(date).startOf("month");
  const endDate = dayjs(date).endOf("month");
  let workingDays = 0;

  for (
    let d = startDate;
    d.isBefore(endDate) || d.isSame(endDate, "day");
    d = d.add(1, "day")
  ) {
    if (d.day() !== 0 && d.day() !== 6) {
      workingDays++;
    }
  }

  return workingDays;
}

export function getTotalMonthWorkingDaysUntilDate(date) {
  const startDate = dayjs(date).startOf("month");
  const endDate = dayjs(date);
  let workingDays = 0;

  for (
    let d = startDate;
    d.isBefore(endDate) || d.isSame(endDate, "day");
    d = d.add(1, "day")
  ) {
    if (d.day() !== 0 && d.day() !== 6) {
      workingDays++;
    }
  }

  return workingDays;
}

export function DollarValue({ value, color = "black", fractionDigits = 0 }) {
  const formattedValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: fractionDigits,
  }).format(value);

  const displayValue = formattedValue !== "$NaN" ? formattedValue : 0;
  const displayColor = formattedValue !== "$NaN" ? color : "black";

  return <span style={{ color: `${displayColor}` }}>{displayValue}</span>;
}

export function DollarValueAgentMaps({ value, color = "black" }) {
  const formattedValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(value);

  const displayValue = formattedValue !== "$NaN" ? formattedValue : 0;
  const displayColor = formattedValue !== "$NaN" ? color : "black";

  return <span style={{ color: `${displayColor}` }}>{displayValue}</span>;
}

export function DollarString(value) {
  var formattedValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(value);
  // now remove any decimal places
  formattedValue = formattedValue.replace(".00", "");
  return formattedValue;
}

export function utcISOToEasternDatetime(utcISODatetimeString) {
  const etDatetime = dayjs(utcISODatetimeString)
    .utc()               
    .tz('America/New_York'); 
  return etDatetime.format('YYYY-MM-DDTHH:mm:ss');
}

export function toTitleCase (str) {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};