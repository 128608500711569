import React from "react";
import { Table, TableBody } from "@aws-amplify/ui-react";
import { DataType } from "../types";
import { TableRows } from "./TableRows/TableRows";
import TableHeaders from "./TableHeaders/TableHeaders";
import "../../../pages/pfc_dashboard/Dashboard.css";

interface TableDisplayProps {
  type: DataType;
  headerBgColor?: string;
  timeframe?: string;
  showLastYear: boolean;
  dataArray: any[];
  hideAgentName: boolean;
  topN: number;
}

const TableDisplay = ({
  timeframe = "day",
  type,
  showLastYear,
  dataArray,
  hideAgentName,
  topN,
}: TableDisplayProps) => {
  return (
    <Table
      caption=""
      highlightOnHover={true}
      placeholder={undefined}
      size={"small"}
    >
      {(dataArray.length !== 0) &&
          (          <>
      <TableHeaders
        type={type}
        showLastYear={showLastYear}
      />

       <TableBody placeholder={undefined}>
        <TableRows
          data={dataArray}
          hideAgentName={hideAgentName}
          topN={topN}
          showLastYear={showLastYear || timeframe === "year"}
          type={type}
        />
      </TableBody>
          </>)}

    </Table>
  );
};

export default TableDisplay;
