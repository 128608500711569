import { API } from "aws-amplify";
import { getApiRequestInfo } from "../../utils";
import dayjs, { Dayjs } from "dayjs";

interface fetchIncomeDataArgs {
  selectedDate: Dayjs;
  startYearMonth: Dayjs;
}

export async function fetchMonthlySegmentData({
  selectedDate,
  startYearMonth,
}: fetchIncomeDataArgs): Promise<any> {
  const requestInfo = await getApiRequestInfo(null);
  const url = `/monthlysegmentvalues/${dayjs(selectedDate).format("YYYYMMDD")}?start_year_month=${dayjs(startYearMonth).format("YYYYMM")}`;

  console.log("url: " + url);
  try {
    let segmentData = await API.get("idpro", url, requestInfo);
    // Example usage with your JSON data:
 //   console.log("segmentData: " + JSON.stringify(segmentData));
    var updatedData = addGrossRevenue(segmentData);
    return updatedData;
  } catch (error) {
    console.error("error: " + JSON.stringify(error));
    throw new Error("Failed to fetch Income data");
  }

  return null;
}

type Values = {
  amount_financed: number;
  cancel_fee: number;
  interest: number;
  late_fee: number;
  nsf_fee: number;
  setup_fee?: number;
  number_contracts: string;
  premium: number;
  vsc_fee: number;
  gross_revenue?: number; // Optional since it will be added dynamically
};

type Month = {
  year: number;
  month: number;
  values: Values;
};

type Segment = {
  segment: string;
  months: Month[];
};

function addGrossRevenue(data: Segment[]) {
  data.forEach(segment => {
      segment.months.forEach(month => {
          const values = month.values;
          // Calculate gross revenue as the sum of the specified fees
          const grossRevenue = (Number(values.cancel_fee) || 0) + (Number(values.interest) || 0) + (Number(values.late_fee) || 0) + (Number(values.nsf_fee) || 0) + (Number(values.vsc_fee) || 0);
          // Add the gross_revenue to the values object
            values.gross_revenue = Number(grossRevenue.toFixed(2));
      });
  });
  return data;
}


