import styled from "styled-components";

export const WelcomeMessageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  h1 {
    margin-top: 0;
  }
  
  & > img {
    height: auto;
    width: 100px;
  }
`;
