import { API } from "aws-amplify";
import { getApiRequestInfo } from "../../utils";

export async function postWarmUpRequest(): Promise<any> {

  const requestInfo = await getApiRequestInfo(null);
  const url = "/warmup";

  try {
    console.log('Sending WarmUp Request...');
    return await API.post("idpro", url, requestInfo);
  } catch (error) {
    console.error("Error querying based on GPT's response: ", error);
    throw new Error("Failed to warmUp Redshift");
  }
}

