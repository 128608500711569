import dayjs from "dayjs";

export const processFiscalYearBudget = (
  budgetData: {
    months: {
      amount_financed: any;
      premium: any;
      number_contracts: { total: any };
    }[];
  },
  setPlanData: (arg0: any) => void,
  setMonthPlanDataIndex: (arg0: number) => void,
  setMonthPremiumPlan: (arg0: any) => void,
  setMonthAmountFinancedPlan: (arg0: any) => void,
  setMonthNumberContractsPlan: (arg0: any) => void,
  setYearPremiumPlan: (arg0: number) => void,
  setYearAmountFinancedPlan: (arg0: number) => void,
  setYearNumberContractsPlan: (arg0: number) => void,
  selectedDate: string | number | Date | dayjs.Dayjs | null | undefined,
  fiscalStart: string | number | Date | dayjs.Dayjs | null | undefined,
) => {
  setPlanData(budgetData);
  dayjs(selectedDate).diff(dayjs(fiscalStart), "month");
  const monthIndex = dayjs(selectedDate).diff(dayjs(fiscalStart), "month");
  setMonthPlanDataIndex(monthIndex);
  setMonthPremiumPlan(budgetData.months[monthIndex].premium.total ?? 0);
  setMonthAmountFinancedPlan(
    budgetData.months[monthIndex].amount_financed.total ?? 0,
  );
  setMonthNumberContractsPlan(
    budgetData.months[monthIndex].number_contracts.total ?? 0,
  );
  var yPremiumPlan = 0,
    yAmountFinancedPlan = 0,
    yNumberContractsPlan = 0;
  for (var i = 0; i <= monthIndex; i++) {
    yPremiumPlan += budgetData.months[i].premium.total ?? 0;
    yAmountFinancedPlan += budgetData.months[i].amount_financed.total ?? 0;
    yNumberContractsPlan += budgetData.months[i].number_contracts.total ?? 0;
  }
  setYearPremiumPlan(yPremiumPlan);
  setYearAmountFinancedPlan(yAmountFinancedPlan);
  setYearNumberContractsPlan(yNumberContractsPlan);
};
