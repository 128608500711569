// CustomAuthenticator.js

import React from 'react';
import { I18n } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';

// Define custom messages
const myAuthenticatorLabels = {
    en: {
        'Confirm TOTP Code': 'Multi-Factor Authentication',
        'Confirm SMS Code': 'Multi-Factor Authentication',
        'Code *': 'Your account is secured using multi-factor authentication (MFA). Please enter the SMS- or App code from your MFA mobile device to finish signing in.',
    },
};

// Overwrite default messages
I18n.putVocabularies(myAuthenticatorLabels);

const CustomAuthenticator = ({ hideSignUp, variation }) => {
    // return (
    //     <Authenticator hideSignUp={hideSignUp} variation={variation}></Authenticator>
    // );
    return (
        <>
            <style>
                {`
                   .amplify-input[type='text'] {
                        text-transform: lowercase;
                    }
                    
                    .amplify-input[type='text']::placeholder {
                        text-transform: none;
                    }
                `}
            </style>
            <Authenticator 
                    hideSignUp={hideSignUp} 
                    variation={variation}                    
                    formFields={{
                        signIn: {
                            username: {
                                placeholder: 'Enter your Email Address',  // Custom placeholder text
                            },
                        },
                    }}></Authenticator>
        </>
    );
};

export default CustomAuthenticator;