import React from "react";
import { Flex, Placeholder } from "@aws-amplify/ui-react";

const TableLoadingSkeleton = () => {
  return (
    <Flex direction="column" minHeight="285px" placeholder={undefined}>
      <Placeholder size="small" placeholder={undefined} />
      <Placeholder size="small" placeholder={undefined} />
      <Placeholder size="small" placeholder={undefined} />
      <Placeholder size="small" placeholder={undefined} />
    </Flex>
  );
};

export default TableLoadingSkeleton;
