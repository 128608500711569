import { API } from "aws-amplify";
import { getApiRequestInfo } from "../../utils";

export async function fetchGptChatHistories(): Promise<any> {

  const requestInfo = await getApiRequestInfo(null);
  const url = "/chathistories";

  try {
    return await API.get("idpro", url, requestInfo);
  } catch (error) {
    console.error("Error querying chat histories: ", error);
    throw new Error("Failed to fetch chat histories");
  }
}
