import React, { useState } from "react";
import { Formik, Form } from "formik";
import { Button, Box, Snackbar, Alert } from "@mui/material";
import { validationSchema } from "./validationSchema";
import { FormikField } from "./FormikField";
import { handleChangePassword } from "./utils";

export interface FormValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePasswordForm: React.FC = () => {
  const initialValues: FormValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleChangePassword(values, setSubmitting).then(() => {
            setOpenSnackbar(true);
            resetForm();
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormikField
              name="oldPassword"
              label="Old Password"
              type="password"
            />
            <FormikField
              name="newPassword"
              label="New Password"
              type="password"
            />
            <FormikField
              name="confirmPassword"
              label="Confirm New Password"
              type="password"
            />
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              sx={{
                marginTop: 2,
                backgroundColor: "var(--amplify-components-link-color)",
                '&:hover': {
                  backgroundColor: "var(--amplify-components-link-color)", 
                }
              }}
            >
              Change Password
            </Button>

          </Form>
        )}
      </Formik>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Your changes have been saved successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ChangePasswordForm;
