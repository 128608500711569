import React from "react";
import {Field, FieldProps} from "formik";
import {TextField} from "@mui/material";

interface FormikFieldProps {
    name: string;
    label: string;
    type?: string;
}

// Custom TextField for Formik
export const FormikField: React.FC<FormikFieldProps> = ({ name, label, type }) => (
    <Field name={name}>
        {({ field, form: { touched, errors }, meta }: FieldProps) => (
            <TextField
                type={type}
                label={label}
                {...field}
                fullWidth
                margin="normal"
                error={Boolean(meta.touched && meta.error)}
                helperText={meta.touched && meta.error}
            />
        )}
    </Field>
);
