const amountFinancedSelected = [
  { title: "Amount Financed", selector: "amount_financed" },
];
const lateFeeSelected = [{ title: "Late Fee", selector: "late_fee" }];
const interestSelected = [{ title: "Interest", selector: "interest" }];
const nsfFeeSelected = [{ title: "NSF Fee", selector: "nsf_fee" }];
const setupFeeSelected = [{ title: "Setup Fee", selector: "setup_fee" }];
const cancelFeeSelected = [{ title: "Cancel Fee", selector: "cancel_fee" }];
const otherFeeSelected = [{ title: "Other Fee", selector: "other_fee" }];
const vehicleServiceFeeSelected = [
  { title: "Vehicle Service Fee", selector: "vsc_fee" },
];
const grossRevenueSelected = [{ title: "Gross Revenue", selector: "gross_revenue" }];

//  const colorSequencePurple = ["#0465af", "#6864bb", "#a65eb9", "#d856a7", "#fc568a", "#ff6666", "#ff833e", "#ffa600"];
const colorSequence = [
  "#0b97ac",
  "#00a2a5",
  "#00ab92",
  "#2eb375",
  "#67b753",
  "#98b72e",
  "#cbb200",
  "#ffa600",
];
const colorSequence2 = [
  "#003f5c",
  "#2f4b7c",
  "#665191",
  "#a05195",
  "#d45087",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
];
const colorLastYear = "#ffa600";

const amountFinancedOptions = {
  colors: [colorSequence[0], colorLastYear, colorSequence2[1]],
};
const interestOptions = {
  colors: [colorSequence[1], colorLastYear, colorSequence2[3]],
};
const lateFeeOptions = {
  colors: [colorSequence[2], colorLastYear, colorSequence2[4]],
};
const nsfFeeOptions = {
  colors: [colorSequence[3], colorLastYear, colorSequence2[5]],
};
const setupFeeOptions = {
  colors: [colorSequence[4], colorLastYear, colorSequence2[6]],
};
const cancelFeeOptions = {
  colors: [colorSequence[5], colorLastYear, colorSequence2[1]],
};
const otherFeeOptions = {
  colors: [colorSequence[6], colorLastYear, colorSequence2[2]],
};
const vehicleServiceFeeOptions = {
  colors: [colorSequence2[5], colorLastYear, colorSequence2[3]],
};
const grossRevenueOptions = {
  colors: [colorSequence[7], colorLastYear, colorSequence2[7]],
};

export const getGraphs = (includeOtherFees: boolean, includeVSCFees: boolean) => {
  return [
    { options: amountFinancedOptions, selected: amountFinancedSelected },
    { options: interestOptions, selected: interestSelected },
    { options: lateFeeOptions, selected: lateFeeSelected },
    { options: nsfFeeOptions, selected: nsfFeeSelected },
    { options: setupFeeOptions, selected: setupFeeSelected },
    { options: cancelFeeOptions, selected: cancelFeeSelected },
    ...(includeOtherFees
      ? [
          {
            options: otherFeeOptions,
            selected: otherFeeSelected,
          },
        ]
      : []),
      ...(includeVSCFees
        ? [
            {
              options: vehicleServiceFeeOptions,
              selected: vehicleServiceFeeSelected,
            },
          ]
        : [])
        
   // need to update IncomeYearCompare to sum up the values { options: grossRevenueOptions, selected: grossRevenueSelected },
  ];
};
