import React, { useEffect, useState } from "react";
import { API } from 'aws-amplify';
//import { NumberFormat } from '@aws-amplify/ui-react';
import dayjs from 'dayjs';
import { StyledHeading } from "./styles.ts";

import {
  View,
  Grid,
  Flex,
  Placeholder,
  useTheme,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@aws-amplify/ui-react";


import { DollarValue } from "../../utils";
import { getApiRequestInfo } from "../../api/utils";
import TableCard from "../../components/DashboardCard/TableCard";

import "../pfc_dashboard/Dashboard.css";

const headerColor = "#a3eadd" // "#eeeeee"; // "#aadae5"; // "#9de2f2";


export function ConcentrationReport() {
  const startDate = dayjs().subtract(1, 'day');
  const [selectedDate, setSelectedDate] =  useState(startDate);
  const { tokens } = useTheme();

  const [apiCarrierData, setApiCarrierData] = React.useState("");
  const [apiCoverageData, setApiCoverageData] = React.useState("");
  const [apiMGAData, setApiMGAData] = React.useState("");
  const [apiAgentData, setApiAgentData] = React.useState("");


  useEffect(() => {
    const getConcentrationData = async () => {
      const requestInfo = await getApiRequestInfo(null);

      const url = "/concentrationdata";

      API.get("idpro", url, requestInfo)
      .then(response => {
        //console.log("returned from concentrationdata: " + JSON.stringify(response.by_carrier));
          setApiCarrierData(response.by_carrier);
          setApiCoverageData(response.by_coverage_type);
          setApiMGAData(response.by_mga);
          setApiAgentData(response.by_agent);

      }).catch(error => {
          console.log("error: " + JSON.stringify(error));
          if( error.response && error.response.status === 404 ) {
            console.log("404 error");
          }
      });
    }
    getConcentrationData();
  });

  return (
    <>
      <div>
        <StyledHeading level={4}>Concentration as of {selectedDate.format('MM/DD/YYYY')}</StyledHeading>
      </div>
      <View borderRadius="6px" maxWidth="100%" padding="0rem" minHeight="100vh">
      <Grid
          templateColumns={{ base: "1fr", xxl: "1fr 1fr 1fr" }}
          templateRows={{ base: "repeat(3, 10rem)", large: "repeat(3, 8rem)" }}
          gap={tokens.space.xl}
        >

          <View columnSpan={{base: 1, xxl: 3}} rowSpan={{ base: 5, large: 5 }}>
            <TableCard title="Concentration by Carrier" selectedDate={selectedDate}>
              {apiCarrierData ? (
              <Table className="compact-table" caption="" highlightOnHover={true}>
                  <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: headerColor }}>
                      <TableRow>
                          <TableCell as="th">Rank</TableCell>
                          <TableCell as="th">Carrier</TableCell>
                          <TableCell as="th"># Policies</TableCell>
                          <TableCell as="th" className='money'>Amount Financed</TableCell>
                          <TableCell as="th" className='money'>Outstanding Balance</TableCell>
                          <TableCell as="th" className='money'>Percent of Total</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {apiCarrierData.slice(0).map((item, index) => (
                          <TableRow key={index}>
                              <TableCell>{index+1}</TableCell>
                              <TableCell>{item.name} ({item.city}, {item.state_or_region})</TableCell>
                              <TableCell>{item.policy_count}</TableCell>
                              <TableCell className='money'><DollarValue value={item.amount_financed}/></TableCell>
                              <TableCell className='money'><DollarValue value={item.balance_due}/></TableCell>
                              <TableCell className='money'>{parseFloat(item.percent_of_total_balance_due).toFixed(2)}%</TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          ) : (
                      <Flex direction="column" minHeight="285px">
                          <Placeholder size="small" />
                          <Placeholder size="small" />
                          <Placeholder size="small" />
                          <Placeholder size="small" />
                      </Flex>
                  )}
            </TableCard>
            <p></p>
            <TableCard title="Concentration by MGA" selectedDate={selectedDate}>
              {apiMGAData ? (
              <Table className="compact-table" caption="" highlightOnHover={true}>
                  <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: headerColor }}>
                      <TableRow>
                          <TableCell as="th">Rank</TableCell>
                          <TableCell as="th">MGA</TableCell>
                          <TableCell as="th"># Policies</TableCell>
                          <TableCell as="th" className='money'>Amount Financed</TableCell>
                          <TableCell as="th" className='money'>Outstanding Balance</TableCell>
                          <TableCell as="th" className='money'>Percent of Total</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {apiMGAData.slice(0).map((item, index) => (
                          <TableRow key={index}>
                              <TableCell>{index+1}</TableCell>
                              <TableCell>{item.name} ({item.city}, {item.state_or_region})</TableCell>
                              <TableCell>{item.policy_count}</TableCell>
                              <TableCell className='money'><DollarValue value={item.amount_financed}/></TableCell>
                              <TableCell className='money'><DollarValue value={item.balance_due}/></TableCell>
                              <TableCell className='money'>{parseFloat(item.percent_of_total_balance_due).toFixed(2)}%</TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          ) : (
                      <Flex direction="column" minHeight="285px">
                          <Placeholder size="small" />
                          <Placeholder size="small" />
                          <Placeholder size="small" />
                          <Placeholder size="small" />
                      </Flex>
                  )}
            </TableCard>
            <p></p>
            <TableCard title="Concentration by Coverage Type" selectedDate={selectedDate}>
              {apiCoverageData ? (
              <Table className="compact-table" caption="" highlightOnHover={true}>
                  <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: headerColor }}>
                      <TableRow>
                          <TableCell as="th">Rank</TableCell>
                          <TableCell as="th">Coverage</TableCell>
                          <TableCell as="th"># Policies</TableCell>
                          <TableCell as="th" className='money'>Amount Financed</TableCell>
                          <TableCell as="th" className='money'>Outstanding Balance</TableCell>
                          <TableCell as="th" className='money'>Percent of Total</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {apiCoverageData.slice(0).map((item, index) => (
                          <TableRow key={index}>
                              <TableCell>{index+1}</TableCell>
                              <TableCell>{item.coverage_type}</TableCell>
                              <TableCell>{item.policy_count}</TableCell>
                              <TableCell className='money'><DollarValue value={item.amount_financed}/></TableCell>
                              <TableCell className='money'><DollarValue value={item.balance_due}/></TableCell>
                              <TableCell className='money'>{parseFloat(item.percent_of_total_balance_due).toFixed(2)}%</TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          ) : (
                      <Flex direction="column" minHeight="285px">
                          <Placeholder size="small" />
                          <Placeholder size="small" />
                          <Placeholder size="small" />
                          <Placeholder size="small" />
                      </Flex>
                  )}
            </TableCard>
            <p></p>

            <TableCard title="Concentration by Agent" selectedDate={selectedDate}>
              {apiAgentData ? (
              <Table className="compact-table" caption="" highlightOnHover={true}>
                  <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: headerColor }}>
                      <TableRow>
                          <TableCell as="th">Rank</TableCell>
                          <TableCell as="th">Agent</TableCell>
                          <TableCell as="th"># Accounts</TableCell>
                          <TableCell as="th" className='money'>Amount Financed</TableCell>
                          <TableCell as="th" className='money'>Outstanding Balance</TableCell>
                          <TableCell as="th" className='money'>Percent of Total</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {apiAgentData.slice(0).map((item, index) => (
                          <TableRow key={index}>
                              <TableCell>{index+1}</TableCell>
                              <TableCell>{item.name}-{item.agent_code} ({item.city}, {item.state_or_region})</TableCell>
                              <TableCell>{item.loan_count}</TableCell>
                              <TableCell className='money'><DollarValue value={item.amount_financed}/></TableCell>
                              <TableCell className='money'><DollarValue value={item.balance_due}/></TableCell>
                              <TableCell className='money'>{parseFloat(item.percent_of_total_balance_due).toFixed(2)}%</TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          ) : (
                      <Flex direction="column" minHeight="285px">
                          <Placeholder size="small" />
                          <Placeholder size="small" />
                          <Placeholder size="small" />
                          <Placeholder size="small" />
                      </Flex>
                  )}
            </TableCard>
          </View>


        </Grid>
      </View>
    </>
  );
};

