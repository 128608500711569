import React from "react";
import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Amplify, Auth, API } from "aws-amplify";
import { Flex, Placeholder } from "@aws-amplify/ui-react";
import { DollarString } from "../../utils";
import { getApiRequestInfo } from "../../api/utils";
import { buildDashboardUrl } from "../../api/utils/buildDashboardUrl/buildDashboardUrl";
const defaultOptions = {

  chart: {
    fontFamily: "inherit",
    parentHeightOffset: 0,
    toolbar: {
      show: false,
    },
    animations: {
      enabled: true,
      speed: 400,
    },
    stacked: true,
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  stroke: {
    curve: "smooth",
    width: 3,
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 1,
  },
  grid: {
    padding: {
      top: -20,
      right: 0,
      left: -4,
      bottom: -4,
    },
    strokeDashArray: 4,
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
  xaxis: {
    tooltip: {
      enabled: false,
    },
    axisBorder: {
      show: false,
    },
    type: "datetime",
  },
  yaxis: {
    forceNiceScale: true,
    min: 0,
    labels: {
      formatter: (value) => {
        return DollarString(value);
      },
    },
  },
  tooltip: {
    enabled: true,
    shared: true,
    intersect: false,
    followCursor: true, // Makes the tooltip follow the cursor
    offsetX: 10,
    offsetY: 10,
    y: {
      formatter: (value) => {
        return DollarString(value);
      },
    }
  },
  markers: {
    size: 3,
    hover: {
      sizeOffset: 6
    },
  },
  //colors: ["#406abf", "#40aabf", "#81e391", "#81aabf", "#e39181", "#e3c981", "#d2ff11", "#555555"],

  // array of colors to use for the chart
  colors: [
    "#0b97ac",
    "#00a2a5",
    "#00ab92",
    "#2eb375",
    "#67b753",
    "#98b72e",
    "#cbb200",
    "#ffa600",
  ],

  //  colors: ["#0465af", "#6864bb", "#a65eb9", "#d856a7", "#fc568a", "#ff6666", "#ff833e", "#ffa600"],
  legend: {
    show: true,
  },
};

function IncomeYearCompareOld({
  startDate,
  incomeTypes,
  options,
  agentCode,
  state,
  solicitorCode,
  territoryCode,
  title,
}) {
  const [barChartData, setBarChartData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [baseDate, setBaseDate] = useState(startDate);

  var showBudget = true;
  // console.log("in IncomeYearCompare with date: " + startDate + " and incomeTypes: " + JSON.stringify(incomeTypes) + " and options: " + JSON.stringify(options));
  // if agentCode, state, solictorCode or territoryCode have a value, then don't add the budget line
  if (agentCode != "All" || state != "All" || solicitorCode != "All" || territoryCode != "All") {
    showBudget = false;
  }
  useEffect(() => {
    // console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxin useEffect and startDate: " + startDate);

    const getChartData = async () => {
      const requestInfo = await getApiRequestInfo(null);
      // console.log("-------------------------------------startdate: " + startDate);




      const currentYear = startDate.getFullYear();
      const currentMonth = (startDate.getMonth() + 1).toString().padStart(2, "0");
      const currentYearMonth = `${currentYear}${currentMonth}`;
      //console.log("currentYearMonth: " + currentYearMonth);
      
      // Calculate one year ago year and month
      let oneYearAgoYear = currentYear - 2;     // Subtract 1 for the current year, another 1 for the prior year
      let oneYearAgoMonth = startDate.getMonth() + 2; // Adding 1 for zero-based month, another 1 for next month
      
      if (oneYearAgoMonth > 12) {
        oneYearAgoMonth = 1;
        oneYearAgoYear++;
      }
      
      oneYearAgoMonth = oneYearAgoMonth.toString().padStart(2, "0");
      const oneYearAgoYearMonth = `${oneYearAgoYear}${oneYearAgoMonth}`;
    //  console.log("oneYearAgoYearMonth: " + oneYearAgoYearMonth);



  // console.log("---------- before API call to monthlysummarydata with currentYearMonth: " + currentYearMonth + " and oneYearAgoYearMonth: " + oneYearAgoYearMonth);

      var url =
        "/monthlysummarydata?start_year_month=" +
        oneYearAgoYearMonth +
        "&end_year_month=" +
        currentYearMonth;

      url = buildDashboardUrl(url, agentCode, state, solicitorCode, territoryCode);
// console.log("url: " + url);
      const barChartData = [];
      const yearMonths = [];
      API.get("idpro", url, requestInfo)
        .then((response) => {
          const data = response;
       //   console.log("data: " + JSON.stringify(data));

          var lineChartDataObj = {};
          lineChartDataObj.name = "Total (prior year)";
          lineChartDataObj.type = "line";
          lineChartDataObj.data = [];

          //      console.log("data.length: " + data.length);

          // get the data for the prior 12 month line chart
          for (let i = 11; i >= 0; i--) {
            var total = 0;
            for (let j = 0; j < incomeTypes.length; j++) {
              total += parseFloat(
                data[i].monthly_income.find(
                  (item) => item.receivabletype === incomeTypes[j].selector,
                )?.amount ?? 0,
              );
            }
            lineChartDataObj.data.push(total.toFixed(0));
            yearMonths.push(data[i].year_month);
           // console.log("data[i].year_month: " + data[i].year_month + " and total: " + total.toFixed(0));
          }

          // now get the totals for this year
          var lineChartCurDataObj = {};
          lineChartCurDataObj.name = "Total (this year)";
          lineChartCurDataObj.type = "line";
          lineChartCurDataObj.data = [];

          //      console.log("data.length: " + data.length);

          // get the data for the prior 12 month line chart
          for (let i = data.length - 1; i >= 12; i--) {
            var total = 0;
            for (let j = 0; j < incomeTypes.length; j++) {
              total += parseFloat(
                data[i].monthly_income.find(
                  (item) => item.receivabletype === incomeTypes[j].selector,
                )?.amount ?? 0,
              );
            }
            lineChartCurDataObj.data.push(total.toFixed(0));
            yearMonths.push(data[i-12].year_month);
           // console.log("data[i].year_month: " + data[i].year_month + " and total: " + total.toFixed(0));
          }

          for (let j = 0; j < incomeTypes.length; j++) {
            const barChartDataObj = {
              name: incomeTypes[j].title,
              type: "bar",
              data: [],
            };
          // console.log("processing income type: " + incomeTypes[j].title);
            //  get the last 12 months of data (including current month) and store it for the bar chart
            for (let i = data.length - 1; i >= 12; i--) {
              var value = parseFloat(
                data[i].monthly_income.find(
                  (item) => item.receivabletype === incomeTypes[j].selector,
                )?.amount ?? 0,
              );
              barChartDataObj.data.push(value);
             // console.log("data[i].year_month: " + data[i].year_month + " and value: " + value)
            }
            barChartData.push(barChartDataObj);
          }

          // console.log("barChartData: " + JSON.stringify(barChartData));
          // now call the API to get the budget plan data to compare to for the last 12 months

          barChartData.push(lineChartDataObj);
          barChartData.push(lineChartCurDataObj);

          setBarChartData(barChartData);

          var months = [];

          var workingYear = startDate.getFullYear();
          var workingMonth = startDate.getMonth() + 1; // getMonth() is zero-based, so add 1

          for (let i = 0; i < 12; i++) {
            var strMonthYear = `${workingYear}-${workingMonth.toString().padStart(2, "0")}`;
            months.push(strMonthYear);

            // Move to the previous month
            workingMonth--;
            if (workingMonth === 0) {
              workingMonth = 12;
              workingYear--;
            }
          }
          setLabels(months);
        })
        .catch((error) => {
          console.log("error: " + JSON.stringify(error));
          if (error.response && error.response.status === 404) {
            console.log("404 error");
          }
        });
    };

    getChartData();
  }, [startDate, agentCode, solicitorCode, territoryCode, state]);

  if (!barChartData || barChartData.length === 0) {
    return (
      <Flex direction="column" minHeight="285px">
        <Placeholder size="small" />
        <Placeholder size="small" />
        <Placeholder size="small" />
        <Placeholder size="small" />
      </Flex>
    );
  }
  const chartOptions = Object.assign({}, defaultOptions, options, { labels });
  //console.log("chartOptions: " + JSON.stringify(chartOptions));

  // console.log({ barChartData, labels });

  return (
    <Chart
      series={barChartData}
      type="bar"
      height={284}
      width="100%"
      options={Object.assign({}, defaultOptions, options, { labels })}
    />
  );
}

export default IncomeYearCompareOld;
