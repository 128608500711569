import { DollarString } from "../../utils";

export const defaultOptions = {
  chart: {
    fontFamily: "inherit",
    parentHeightOffset: 0,
    toolbar: {
      show: false,
    },
    animations: {
      enabled: true,
      speed: 400,
    },
    stacked: true,
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  stroke: {
    curve: "smooth",
    width: 3,
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    enabled: true,
    shared: true,
    intersect: false,
    fixed: {
      enabled: false,
      position: "bottom",
      offsetX: 0,
      offsetY: 0,
    },
  },
  grid: {
    padding: {
      top: -20,
      right: 0,
      left: -4,
      bottom: -4,
    },
    strokeDashArray: 4,
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
  xaxis: {
    tooltip: {
      enabled: false,
    },
    axisBorder: {
      show: false,
    },
    type: "datetime",
  },
  yaxis: {
    forceNiceScale: true,
    min: 0,
    labels: {
      padding: 4,
      formatter: (value: any) => {
        return DollarString(value);
      },
    },
  },
  markers: {
    size: 3,
    hover: {
      sizeOffset: 6,
    },
  },
  //colors: ["#406abf", "#40aabf", "#81e391", "#81aabf", "#e39181", "#e3c981", "#d2ff11", "#555555"],

  // array of colors to use for the chart
  colors: [
    "#0b97ac",
    "#00a2a5",
    "#00ab92",
    "#2eb375",
    "#67b753",
    "#98b72e",
    "#cbb200",
    "#ffa600",
  ],

  //  colors: ["#0465af", "#6864bb", "#a65eb9", "#d856a7", "#fc568a", "#ff6666", "#ff833e", "#ffa600"],
  legend: {
    show: true,
  },
};
