import React, { useState, useEffect, useRef } from 'react';
import { API } from "aws-amplify";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { set } from 'lodash';
import { getApiRequestInfo } from '../../api/utils';

const AdminEditUserPopUp = ({ isOpen, setIsOpen, selectedUser, currentUser, updateUserList }) => {

  const [requireCompanyCode, setRequireCompanyCode] = useState(false);
  const [editedUser, setEditedUser] = useState(selectedUser);
  const [resetPassword, setResetPassword] = useState(false);
  const [disableAccess, setDisableAccess] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [phoneWarning, setPhoneWarning] = useState('');
  const [hasSMSMFASetup, setHasSMSMFASetup] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const [isRequestingEdit, setIsRequestingEdit] = useState(false);

  const latestPhoneNumber = useRef('');

  useEffect(() => {
    const getRelevantEditedUserFields = (requiresCompanyCode) => {
      const relevantEditedUser = {
        username: selectedUser.username,
        attributes: {
          name: selectedUser.attributes.name,
          email: selectedUser.attributes.email,
          'custom:role': selectedUser.attributes['custom:role'],
        }
      }

      if (selectedUser.attributes.phone_number) {
        relevantEditedUser.attributes.phone_number = selectedUser.attributes.phone_number;
      } else {
        relevantEditedUser.attributes.phone_number = '';
      }

      if (requiresCompanyCode) {
        relevantEditedUser.attributes['custom:company_code'] = selectedUser.attributes['custom:company_code'];
      }
    
      return relevantEditedUser;
    }

    const requiresCompanyCode = isAdminOrEmployee(selectedUser.attributes['custom:role']) ? false : true;
    
    setEditedUser(getRelevantEditedUserFields(requiresCompanyCode));

    setRequireCompanyCode(requiresCompanyCode);

    if (selectedUser.preferredMFA === 'SMS') {
      setHasSMSMFASetup(true);
    }

    if (!selectedUser.enabled) {
      setIsEnabled(false);
      setDisableAccess(true);
    }

    latestPhoneNumber.current = selectedUser.attributes.phone_number;
  }, [selectedUser]);

  const createRequestBody = (editedUser) => {
    //removes empty fields from editedUser
    // const userWithNonEmptyFields = {};
    // Object.keys(editedUser.attributes).forEach(key => {
    //   if (editedUser.attributes[key] !== '' && editedUser.attributes[key]) {
    //     userWithNonEmptyFields[key] = editedUser.attributes[key];
    //   }
    // });

    const body = {
      ...editedUser,
      actions: {
        reset_password: resetPassword,
        disable_access: disableAccess,
      }
    };
    return body;
  };

  const isValidPhoneNumber = (phoneNumber) => {
    if (phoneNumber === '') {
      return true;
    }
    const phoneNumberRegex = /^\+[0-9]{5,20}$/;
    return phoneNumberRegex.test(phoneNumber);
  };

  const handleClickedSave = async () => {
    if (!isValidPhoneNumber(editedUser.attributes.phone_number)) {
      setPhoneError('Invalid phone number format');
      return;
    }

    const url = '/users';

    const body = createRequestBody(editedUser);
    const requestInfo = await getApiRequestInfo(body);

    console.log('edit body: ', body);
    console.log('edit requestInfo: ', requestInfo);

    setIsRequestingEdit(true);

    API.put("idpro", url, requestInfo)
    .then(response => {
      console.log("Edited User: ", response);
      updateUserList('Fetching Updated User...');
      handleCloseDialog();
      setIsRequestingEdit(false);
    })
    .catch(error => {
      console.error("Could not edit User: ", error);
      //TODO: display error message
      setIsRequestingEdit(false);
    });
  }

  const isAdminOrEmployee = (role) => {
    return role === 'admin' || role === 'employee';
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone_number') {
      setPhoneError('');
      // if (value === '' && hasSMSMFASetup) {
      if (hasSMSMFASetup && latestPhoneNumber.current !== value) {
        setPhoneWarning('! WARNING: This will remove the user\'s SMS MFA setup');
      } else {
        setPhoneWarning('');
      }
    }

    if (name === 'custom:role') {
      if (isAdminOrEmployee(value)) {
        setRequireCompanyCode(false);
      } else {
        setRequireCompanyCode(true);
      }
    }

    const user = {
        ...editedUser,
        attributes: {
            ...editedUser.attributes,
            [name]: value,
        },
    }

    setEditedUser(user);
  };

  const handleCloseDialog = () => {
    setResetPassword(false);
    setPhoneError('');
    setPhoneWarning('');
    setIsOpen(false);
    setResetPassword(false);
    setDisableAccess(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          value={editedUser.attributes.name}
          onChange={handleInputChange}
          name="name"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Phone Number"
          value={editedUser.attributes.phone_number ? editedUser.attributes.phone_number : ''}
          onChange={handleInputChange}
          name="phone_number"
          fullWidth
          margin="normal"
          type="tel"
          color={phoneError ? 'error' : phoneWarning ? 'warning' : 'primary'}
          error={!!phoneError}
          helperText={phoneError ? phoneError : phoneWarning}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="role-label">Role</InputLabel>
          <Select
            labelId="role-label"
            name="custom:role"
            value={editedUser.attributes['custom:role']}
            label="Role"
            onChange={handleInputChange}
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="employee">Employee</MenuItem>
            <MenuItem value="agent">Agent</MenuItem>
            <MenuItem value="sales">Sales</MenuItem>
            {/* Add more roles as needed */}
          </Select>
        </FormControl>
        {requireCompanyCode && (
            <TextField
                label="Company Code"
                value={editedUser.attributes['custom:company_code']}
                onChange={handleInputChange}
                name="custom:company_code"
                fullWidth
                margin="normal"
                // type="tel"
            />
        )}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '8px' }}>
          <FormControlLabel
            fullWidth
            control={
              <Checkbox
                checked={resetPassword}
                onChange={(event) => {setResetPassword(event.target.checked)}}
                name="resetPassword"
                color="primary"
              />
            }
            label="Reset Password and MFA"
          />
          <FormControlLabel
            fullWidth
            control={
              <Checkbox
                checked={disableAccess}
                onChange={(event) => {setDisableAccess(event.target.checked)}}
                name="disableAccess"
                color="primary"
              />
            }
            label="Disable User Access"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Cancel</Button>
        {isRequestingEdit ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <CircularProgress size={20} color="inherit" />
            <label className="amplify-label">Updating...</label>
          </div>
        ) : (
          <Button onClick={() => handleClickedSave(editedUser)} disabled={requireCompanyCode && editedUser.attributes['custom:company_code'] === ''}>Save</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AdminEditUserPopUp;
