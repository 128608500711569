export const colors = {
    day: "#9de2f2",
    week: "#aadae5",
    month: "#b5d2d9",
    dayPremium: "#a3eadd",
    weekPremium: "#8ae2d2",
    monthPremium: "#6fd9c7",
};


export const tableHeaderColor = colors.dayPremium;
