import { useState, useEffect, useRef, useCallback } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { debounce } from "lodash";

import { Auth, API } from "aws-amplify";
import { getApiRequestInfo } from "../../api/utils";

const useDebouncedSearch = (searchFunction) => {
  const [inputValue, setInputValue] = useState("");

  // Debounce the original search async function
  const debouncedSearchFunction = useCallback(
    debounce((prefix) => searchFunction(prefix), 500),
    [],
  );

  useEffect(() => {
    if (inputValue) {
      debouncedSearchFunction(inputValue);
    }
  }, [inputValue, debouncedSearchFunction]);

  return { inputValue, setInputValue };
};

const SearchBar = ({
  setSelectedItem,
  selectedItemDefaultVal,
  resetTrigger,
}) => {
  const loadMoreString = "Load more...";
  const pageSize = 5;
  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState([]);
  const agents = useRef([]);

  const paginationTokenCode = useRef(null);
  const paginationTokenName = useRef(null);
  const offset = useRef(0);
  const isComplete = useRef(false);

  const resetSearchBar = () => {
    setInputValue("");
    setOptions([]);
    agents.current = [];
    paginationTokenCode.current = null;
    paginationTokenName.current = null;
    offset.current = 0;
    isComplete.current = false;
  };

  useEffect(() => {
    resetSearchBar();
  }, [resetTrigger]);

  const { inputValue, setInputValue } = useDebouncedSearch(async (prefix) => {
    console.log("searching for prefix: ", prefix);
    if (prefix === "") {
      setOptions([]);
      agents.current = [];
    } else {
      setOptions([prefix + loadMoreString]);
      fetchNextItems(prefix);
    }
  });

  useEffect(() => {
    // Reset pagination tokens and completion flags when the prefix changes
    offset.current = 0;
    isComplete.current = false;
  }, [inputValue]);

  useEffect(() => {
    // Reset pagination tokens and completion flags when the prefix changes
    offset.current = options.length;
  }, [options]);

  const fetchNextItems = async (prefix) => {
    if (isComplete.current) {
      return;
    }

    const requestInfo = await getApiRequestInfo(null);

    let queryParams = `prefix=${prefix}&offset=${offset.current}`;

    const url = `/agents/search?${queryParams}`;

    try {
      setIsLoading(true);
      const response = await API.get("idpro", url, requestInfo);
      if (response) {
        // for each item in response.items, add the field display_name consisting of item.name + ' - ' + item.agent_code
        response.items.forEach((item) => {
          item.display_name =
            item.name +
            " (" +
            item.agent_code +
            ") (" +
            item.city +
            ", " +
            item.state_or_region +
            ")";
        });

        const newOptions = options
          .filter((option) => !option.endsWith(loadMoreString))
          .concat(response.items.map((item) => item.display_name));

        // Add 'Load more...' only if there are items in the response
        if (response.items.length >= pageSize) {
          newOptions.push(prefix + loadMoreString);
        }

        setOptions(newOptions);
        response.items.forEach((item) => agents.current.push(item));

        isComplete.current = response.isComplete;
      }
      setIsLoading(false);
    } catch (error) {
      console.error("error: " + JSON.stringify(error));
    }
  };

  const loadMoreItems = async () => {
    fetchNextItems(inputValue);
  };

  const getItemByDisplayName = (displayName) => {
    return agents.current.find((item) => item.display_name === displayName);
  };

  return (
    <Autocomplete
      freeSolo
      options={options}
      value={inputValue}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === "input") {
          setInputValue(newInputValue);
          setSelectedItem(selectedItemDefaultVal); // Clear selected option on new input
        }
      }}
      onChange={(event, newValue) => {
        if (newValue && newValue !== "") {
          const item = getItemByDisplayName(newValue);
          setSelectedItem(item.agent_code);
        } else {
          setSelectedItem(selectedItemDefaultVal);
        }
        setOptions([]); // Empty the options list upon selection
        agents.current = [];
      }}
      renderOption={(props, option) => {
        if (option.endsWith(loadMoreString)) {
          return (
            <li {...props} onClick={!isLoading ? loadMoreItems : undefined}>
              {isLoading ? <CircularProgress size={24} /> : loadMoreString}
            </li>
          );
        }
        return <li {...props}>{option}</li>;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Agent"
          placeholder="All - Type to search..." 
          variant="outlined"
          InputLabelProps={{
            shrink: true,  // This forces the label to always shrink (stay visible)
          }}
        />
      )}
      
    />
  );
};

export default SearchBar;
