import React from "react";
import { TableCell, TableRow } from "@aws-amplify/ui-react";
import { DollarValue } from "../../utils";

interface TableRowsProps {
  data: any[];
  showLastYear: boolean;
  type: string;
  hideAgentName: boolean;
  topN: number;
}

export const TableRows = ({
  data,
  showLastYear,
  type,
  hideAgentName,
  topN,
}: TableRowsProps) => {
  if (type === "revenue") {
    console.log({data});
    return (
      <>
        {data.slice(0, topN).map((item, index) => (
          <TableRow key={index} placeholder={undefined}>
            <TableCell placeholder={undefined}>
              {index + 1}
            </TableCell>
            <TableCell placeholder={undefined}>
              {hideAgentName
                ? "Agent " + Math.floor(1000 + Math.random() * 9000)
                : item.agent_name}
              &nbsp;({item.city},{item.state})
            </TableCell>
            <TableCell className="money" placeholder={undefined}>
              <DollarValue value={item.total_revenue} />
            </TableCell>
            {showLastYear && (
              <TableCell className={`money`} placeholder={undefined}>
                <DollarValue
                  value={item.prior_year_total_revenue}
                  color={
                    item.prior_year_total_revenue < item.total_revenue
                      ? "green"
                      : "red"
                  }
                />
                (
                {Math.round(
                    item.prior_year_total_revenue && ((item.total_revenue - item.prior_year_total_revenue) /
                    item.prior_year_total_revenue) *
                    100,
                )}
                %)
              </TableCell>
            )}
            <TableCell placeholder={undefined} className="money">
              <DollarValue value={item.interest} />
            </TableCell>
            <TableCell placeholder={undefined} className="money">
              <DollarValue value={item.late_fee} />
            </TableCell>
            <TableCell placeholder={undefined} className="money">
              <DollarValue value={item.nsf_fee} />
            </TableCell>
            <TableCell placeholder={undefined} className="money">
              <DollarValue value={item.cancel_fee} />
            </TableCell>
            <TableCell placeholder={undefined} className="money">
              <DollarValue value={item.setup_fee} />
            </TableCell>
            <TableCell placeholder={undefined} className="money">
              {item.loan_count}
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }
  if( type === "amount_financed" ) {
    return (
      <>
        {data.slice(0, topN).map((item, index) => (
          <TableRow key={index} placeholder={undefined}>
            <TableCell placeholder={undefined}>
              {index + 1}
            </TableCell>
            <TableCell placeholder={undefined}>
              {hideAgentName
                ? "Agent " + Math.floor(1000 + Math.random() * 9000)
                : item.agent_name}
              &nbsp;({item.city},{item.state})
            </TableCell>
            <TableCell className="money" placeholder={undefined}>
              {item.count}
            </TableCell>
            <TableCell className="money" placeholder={undefined}>
              <DollarValue value={item.amount_financed} />
            </TableCell>
            {showLastYear && (
              <TableCell className="money" placeholder={undefined}>
                <DollarValue value={item.prior_year_amount_financed} />(
                {item.prior_year_amount_financed && Math.round(
                  ((item.amount_financed - item.prior_year_amount_financed) /
                    item.prior_year_amount_financed) *
                    100,
                )}
                %)
              </TableCell>
            )}
          </TableRow>
        ))}
      </>
    );


  }
  return (
    <>
      {data.slice(0, topN).map((item, index) => (
        <TableRow key={index} placeholder={undefined}>
          <TableCell placeholder={undefined}>
            {index + 1}
          </TableCell>
          <TableCell placeholder={undefined}>
            {hideAgentName
              ? "Agent " + Math.floor(1000 + Math.random() * 9000)
              : item.agent_name}
            &nbsp;({item.city},{item.state})
          </TableCell>
          <TableCell className="money" placeholder={undefined}>
            {item.count}
          </TableCell>
          <TableCell className="money" placeholder={undefined}>
            <DollarValue value={item.premium} />
          </TableCell>
          {showLastYear && (
            <TableCell className="money" placeholder={undefined}>
              <DollarValue value={item.prior_year_premium} />(
              {item.prior_year_total_revenue && Math.round(
                ((item.premium - item.prior_year_premium) /
                  item.prior_year_premium) *
                  100,
              )}
              %)
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};
