import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";
import { ScrollView } from "@aws-amplify/ui-react";
import ExportSortableTableDataIcon from "./ExportSortableTableDataIcon";
import TablePagination from "@mui/material/TablePagination";
import { Select, MenuItem, TextField, Tooltip } from "@mui/material";
import { DollarValue } from "../../utils";
import { debounce } from "lodash";

import { styled } from '@mui/material/styles';

const StyledTableContainer = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  maxHeight: '500px',
  overflowY: 'auto'
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: "#a3eadd", // 'var(--amplify-colors-background-secondary)',
  zIndex: theme.zIndex.appBar - 1100 //TODO: Find a better way to set the z-index
}));

const SortableTable = ({
  tableData,
  columnMapping,
  selectableColumns,
  exportTitle,
  controlElements = [],
  columnDisplayInfo,
  fixedHeight,
  selectedColumnProp = "",
  updateSelectedColumnProp = null
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [selectedColumn, setSelectedColumn] = useState(selectedColumnProp);
  const [searchQuery, setSearchQuery] = useState("");
  const previousQuery = useRef("");
  const previousSelectedColumn = useRef("");

  // displayData differs from tableData when searchQuery is not empty
  const [displayTableData, setDisplayTableData] = useState(tableData);

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [tableData]);

  useEffect(() => {
    if (selectedColumnProp !== "") setSelectedColumn(selectedColumnProp);
  }, [selectedColumnProp]);

  useEffect(() => {
    if (updateSelectedColumnProp) {
      updateSelectedColumnProp(selectedColumn);
    } else if (!selectableColumns.includes(selectedColumn)) {
      setSelectedColumn(selectableColumns[0]);
    }
  }, [selectableColumns]);

  // Handler for changing page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handler for changing rows per page
  const handleChangeRowsPerPage = (event) => {
    // Check if the 'All' option was selected
    const newRowsPerPage =
      event.target.value === -1
        ? displayTableData.length
        : parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page
  };

  // Debounced search
  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query === '') {
        setDisplayTableData(tableData);
      } else {
        const filtered = tableData.filter((item) => {
          const itemValue = item[columnMapping[selectedColumn]]
            ?.toString()
            .toLowerCase();
          return itemValue?.includes(query.toLowerCase());
        });
        setDisplayTableData(filtered);
      }
    }, 500),
    [tableData, selectedColumn],
  );

  useEffect(() => {
    if (
      searchQuery !== previousQuery.current ||
      selectedColumn !== previousSelectedColumn.current
    ) {
      debouncedSearch(searchQuery);
    }
    previousQuery.current = searchQuery;
    previousSelectedColumn.current = selectedColumn;
  }, [searchQuery, selectedColumn, debouncedSearch]);

  const sortedData = useMemo(() => {
    let sortableItems = [...displayTableData];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        const columnName = columnMapping[sortConfig.key];
        if (a[columnName] < b[columnName]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[columnName] > b[columnName]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [displayTableData, sortConfig]);

  // Only display the rows for the current page
  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  const rowsPerPageOptions = [
    "1",
    "25",
    "50",
    "100",
    "500",
    "1000",
    // { label: "All", value: "-1" },
  ];

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getCellStyle = (columnName) => {
    const baseStyle = { whiteSpace: "nowrap", minWidth: "50px" };
    if (columnName.endsWith('__d')) {
      return { ...baseStyle, textAlign: "right" }; // Add right alignment for DollarValue fields
    }
    return baseStyle;
  };

  const replaceColumnNameSuffix = (columnName) => {
    return columnName.replace(/__d$/, "");
  };

  const TableCellComponent = React.memo(({ content, style, showHover }) => {
    return showHover ? (
      <Tooltip title={String(content)} placement="top" arrow>
        <TableCell style={style}>{content}</TableCell>
      </Tooltip>
    ) : (
      <TableCell style={style}>{content}</TableCell>
    );
  });  

  const TableRowComponent = React.memo(({ item, columnMapping, columnDisplayInfo }) => (
    <TableRow>
      {Object.entries(columnMapping).map(([columnName, fieldKey]) => {
        const cellContent = columnName.endsWith('__d') ? (
          <DollarValue value={item[fieldKey]} />
        ) : columnDisplayInfo[fieldKey]?.customAppearance ? (
          columnDisplayInfo[fieldKey]?.customAppearance(item, fieldKey, item[fieldKey])
        ) : (
          item[fieldKey]
        );
  
        const cellStyle = getCellStyle(columnName);
        const showHover = columnDisplayInfo[fieldKey]?.showHover;
  
        return (
          <TableCellComponent
            key={fieldKey}
            content={cellContent}
            style={cellStyle}
            showHover={showHover}
          />
        );
      })}
    </TableRow>
  ));

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        {controlElements.map((element, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            {element}
          </div>
        ))}
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "10px" }}
        >
          <Select
            value={selectedColumn}
            onChange={(e) => {
              setSelectedColumn(e.target.value);
            }}
            displayEmpty
            style={{ marginRight: "10px" }} // Added marginRight to create space
          >
            {selectableColumns.map((key) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
          <TextField
            label="Search"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <TablePagination
          component="div"
          count={sortedData.length} // Total number of rows
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={rowsPerPageOptions}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ExportSortableTableDataIcon tableData={sortedData} title={exportTitle} columnMapping={columnMapping}/>
      </div>
      <ScrollView
        style={{
          width: "100%",
          height: fixedHeight || "500px",
          overflow: "auto",
        }}
      >
        <StyledTableContainer>
        <Table highlightOnHover={true}>

          <StyledTableHead>
            <TableRow>
              {Object.entries(columnMapping).map(([columnName, _]) => (
                <TableCell
                  as="th"
                  key={replaceColumnNameSuffix(columnName)}
                  onClick={() => requestSort(columnName)}
                  style={{ cursor: "pointer" }}
                >
                  {replaceColumnNameSuffix(columnName)}
                </TableCell>
              ))}
            </TableRow>
          </StyledTableHead>

          <TableBody>
            {paginatedData.map((item, index) => (
              <TableRowComponent
                key={index}
                item={item}
                columnMapping={columnMapping}
                columnDisplayInfo={columnDisplayInfo}
              />
            ))}
          </TableBody>
        </Table>
        </StyledTableContainer>
      </ScrollView>
    </div>
  );
};

export default SortableTable;
