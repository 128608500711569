const amountFinancedSelected = [
  { title: "Amount Financed", selector: "amount_financed" },
];
const lateFeeSelected = [{ title: "Late Fee", selector: "late_fee" }];
const interestSelected = [{ title: "Interest", selector: "interest" }];
const nsfFeeSelected = [{ title: "NSF Fee", selector: "nsf_fee" }];
const setupFeeSelected = [{ title: "Setup Fee", selector: "setup_fee" }];
const cancelFeeSelected = [{ title: "Cancel Fee", selector: "cancel_fee" }];
const otherFeeSelected = [{ title: "Other Fee", selector: "other_fee" }];
const vehicleServiceFeeSelected = [
  { title: "Vehicle Service Fee", selector: "vsc_fee" },
];
const grossRevenueSelected = [{ title: "Gross Revenue", selector: "gross_revenue" }];

const colorSequence = [
  "#0b97ac",
  "#00a2a5",
  "#00ab92",
  "#2eb375",
  "#67b753",
  "#98b72e",
  "#cbb200",
  "#ffa600",
];
const colorSequence2 = [
  "#003f5c",
  "#2f4b7c",
  "#665191",
  "#a05195",
  "#d45087",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
];
const colorLastYear = "#ffa600";

const amountFinancedOptions = {
  colors: [colorSequence[0], colorLastYear, colorSequence2[1]],
};
const interestOptions = {
  colors: [colorSequence[1], colorLastYear, colorSequence2[3]],
};
const lateFeeOptions = {
  colors: [colorSequence[2], colorLastYear, colorSequence2[4]],
};
const nsfFeeOptions = {
  colors: [colorSequence[3], colorLastYear, colorSequence2[5]],
};
const setupFeeOptions = {
  colors: [colorSequence[4], colorLastYear, colorSequence2[6]],
};
const cancelFeeOptions = {
  colors: [colorSequence[5], colorLastYear, colorSequence2[1]],
};
const otherFeeOptions = {
  colors: [colorSequence[6], colorLastYear, colorSequence2[2]],
};
const vehicleServiceFeeOptions = {
  colors: [colorSequence2[5], colorLastYear, colorSequence2[3]],
};
const grossRevenueOptions = {
  colors: [colorSequence[7], colorLastYear, colorSequence2[7]],
};

export const getGraphs = (includeOtherFees: boolean) => {
  return [
    { options: amountFinancedOptions, selected: amountFinancedSelected },
    { options: interestOptions, selected: interestSelected },
    { options: lateFeeOptions, selected: lateFeeSelected },
    { options: nsfFeeOptions, selected: nsfFeeSelected },
    { options: setupFeeOptions, selected: setupFeeSelected },
    { options: cancelFeeOptions, selected: cancelFeeSelected },
    ...(includeOtherFees
      ? [
          {
            options: otherFeeOptions,
            selected: otherFeeSelected,
          },
        ]
      : []),
    { options: vehicleServiceFeeOptions, selected: vehicleServiceFeeSelected },
    { options: grossRevenueOptions, selected: grossRevenueSelected },
  ];
};

export const menuItems = [
  { title: "Gross Revenue", value: "gross_revenue", isFinancial: true },
  { title: "Amount Financed", value: "amount_financed", isFinancial: true },
  { title: "Cancel Fee", value: "cancel_fee", isFinancial: true },
  { title: "Interest", value: "interest", isFinancial: true },
  { title: "Late Fee", value: "late_fee", isFinancial: true },
  { title: "NSF Fee", value: "nsf_fee", isFinancial: true },
  { title: "Loan Count", value: "number_contracts", isFinancial: false },
  { title: "Premium", value: "premium", isFinancial: true },
  { title: "Setup Fee", value: "setup_fee", isFinancial: true },
  { title: "Vehicle Service Fee", value: "vsc_fee", isFinancial: true },

];
