import { TableCell } from "@aws-amplify/ui-react";
import React from "react";

export const StandardTableHead = ({
  showLastYear, dollarLabel
}: {
  showLastYear: boolean;
  dollarLabel: string;
}) => {
  return (
    <>
        <TableCell as="th" placeholder={undefined}>
            Rank
        </TableCell>
      <TableCell as="th" placeholder={undefined}>
        Agent
      </TableCell>
      <TableCell as="th" className="money" placeholder={undefined}>
        Count
      </TableCell>
      <TableCell as="th" className="money" placeholder={undefined}>
        {dollarLabel}
      </TableCell>
      {showLastYear && (
        <TableCell as="th" className="money" placeholder={undefined}>
          Last Year
        </TableCell>
      )}
    </>
  );
};
