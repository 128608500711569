import React from "react";
import { Formik } from "formik";
import { validationSchema } from "./validationSchema";
import { StyledField, StyledForm, StyledIconButton } from "./styles";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";

interface ChatInputProps {
  handleSubmit: (submissionMessage: string) => Promise<void>;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

interface FormValues {
  message: string;
}

const ChatInput = ({
  handleSubmit,
  isLoading,
  setIsLoading,
}: ChatInputProps) => {
  const initialValues = {
    message: "",
  };

  const onSubmit = async (values: FormValues, { resetForm }: any) => {
    setIsLoading(true);
    await handleSubmit(values.message);
    setIsLoading(false);
    resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue, isValid }) => (
        <StyledForm>
          <StyledField
            type="text"
            name="message"
            multiline
            rows={1}
            variant="outlined"
            placeholder="Ask your question here..."
            value={values.message}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue("message", e.target.value);
            }}
            disabled={isSubmitting || isLoading}
            onKeyPress={(event: {
              key: string;
              shiftKey: any;
              preventDefault: () => void;
            }) => {
              if (event.key === "Enter" && !event.shiftKey) {
                handleSubmit();
                event.preventDefault();
              }
            }}
            style={{ flex: 1, marginRight: "8px" }}
          />
          <StyledIconButton
            color="primary"
            onClick={() => handleSubmit()}
            disabled={isLoading} // Disable button while loading
            aria-label="send message"
          >
            {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
          </StyledIconButton>
        </StyledForm>
      )}
    </Formik>
  );
};

export default ChatInput;
