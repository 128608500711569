import React from "react";
import { StyledHeading, Wrapper } from "./styles";

export const ComingSoon = () => {
  return (
    <Wrapper>
      <StyledHeading level={2} placeholder={undefined}>
        Coming soon
      </StyledHeading>
    </Wrapper>
  );
};

export default ComingSoon;
