import React, { useEffect, useState, useContext} from "react";
import { Auth } from "aws-amplify";
import {
  DollarString,
  getTotalMonthWorkingDaysUntilDate,
  getTotalWorkingDaysInMonth,
} from "../../utils";
import dayjs from "dayjs";

import {
  Card,
  Flex,
  Grid,
  Placeholder,
  ScrollView,
  useTheme,
  View,
} from "@aws-amplify/ui-react";
import { StyledCard } from "./styles";

import {
  MdCalendarMonth,
  MdCalendarToday,
  MdOutlineAttachMoney,
  MdWbSunny,
} from "react-icons/md";

import MiniStatistics from "../../components/MiniStatistic/MiniStatistic";
import IncomeYearCompareOld from "./IncomeYearCompareOld";
import IncomeVPlanChart from "./IncomeVPlanChart";
import IncomeTable from "./IncomeTable";
import QuoteTable from "./QuoteTable";
import AgentRanking from "../../components/AgentRanking/AgentRanking";

import "./Dashboard.css";


import DashboardHeading from "../../components/DashboardHeading/DashboardHeading";
import DashboardFields from "../../components/DashboardFields/DashboardFields";
import { useQuery } from "react-query";
import { fetchIncomeData } from "../../api/utils/fetchIncomeData/fetchIncomeData";
import { processIncomeData } from "./utils/processIncomeData";
import { fetchFiscalYearStart } from "../../api/utils/fetchFiscalYearStart/fetchFiscalYearStart";
import { fetchFiscalYearBudget } from "../../api/utils/fetchFiscalYearBudget/fetchFiscalYearBudget";
import { fetchDailySummaryText } from "../../api/utils/fetchDailySummaryText/fetchDailySummaryText";
import { processFiscalYearBudget } from "./utils/processFiscalYearBudget";
import { DashboardWrapper } from "./styles";
import { StateContext } from "../../StateContext";

import IncomeYearCompare from "../../components/IncomeYearCompare/IncomeYearCompare";
const amountFinancedOptions = {
  colors: ["#00a2a5", "#ffa600", "#ff7c43"]
};
export function Dashboard() {
  const startDate = dayjs().subtract(1, "day"); //  : dayjs(new Date(2023,8,20));
  const [apiData, setApiData] = React.useState("");
  const [voiceSummary, setVoiceSummary] = React.useState("");

  const [hideAgentName, setHideAgentName] = useState(false); // need to pass to the AgentRanking component

  const [selectedDate, setSelectedDate] = useState(startDate);
  const [yearAmountFinanced, setYearAmountFinanced] = useState(0);
  const [monthAmountFinanced, setMonthAmountFinanced] = useState(0);
  const [dayAmountFinanced, setDayAmountFinanced] = useState(0);
  const [yearPremium, setYearPremium] = useState(0);
  const [monthPremium, setMonthPremium] = useState(0);
  const [dayPremium, setDayPremium] = useState(0);
  const [dayLoanCount, setDayLoanCount] = useState(0);
  const [monthLoanCount, setMonthLoanCount] = useState(0);
  const [yearLoanCount, setYearLoanCount] = useState(0);
  const [yearEarnedInterest, setYearEarnedInterest] = useState(0);
  const [monthEarnedInterest, setMonthEarnedInterest] = useState(0);
  const [dayEarnedInterest, setDayEarnedInterest] = useState(0);

  const [totalWorkingDaysThisMonth, setTotalWorkingDaysThisMonth] = useState(0);
  const [totalWorkingDaysUntilToday, setTotalWorkingDaysUntilToday] =
    useState(0);
  const [percentMonthCompleted, setPercentMonthCompleted] = useState(0);
  const [agentRankType, setAgentRankType] = useState("amount_financed");

  const [selectedAgent, setSelectedAgent] = useState("All");
  const [selectedSalesExec, setSelectedSalesExec] = useState("All");
  const [selectedTerritory, setSelectedTerritory] = useState("All");
  const [selectedState, setSelectedState] = useState("All");

  const [fiscalStart, setFiscalStart] = useState(
    dayjs().subtract(1, "day").startOf("year"),
  ); // start of fiscal year defaulted to January of current year
  const [fiscalYear, setFiscalYear] = useState(null);
  const [planData, setPlanData] = useState(null);
  const [monthPlanDataIndex, setMonthPlanDataIndex] = useState(0); // index into the planData.months array for the selected month
  const [monthPremiumPlan, setMonthPremiumPlan] = useState(0);
  const [monthAmountFinancedPlan, setMonthAmountFinancedPlan] = useState(0);
  const [monthNumberContractsPlan, setMonthNumberContractsPlan] = useState(0);
  const [yearPremiumPlan, setYearPremiumPlan] = useState(0);
  const [yearAmountFinancedPlan, setYearAmountFinancedPlan] = useState(0);
  const [yearNumberContractsPlan, setYearNumberContractsPlan] = useState(0);
  const [key, setKey] = React.useState(Math.random());

  const [username, setUsername] = useState("");

  
  const { clientId } = useContext(StateContext);

  // if clientId is '0001', then set agentRankType to 'premium'
  useEffect(() => {
    if (clientId === '0001') {
      setAgentRankType('activatedpremium');
    } else {
      setAgentRankType('amount_financed');
    }
  }, [clientId]);

  // call an api to get the voice summary after the planData and processIncomeData are set and update it every time the selectedDate, selectedAgent, selectedState, or selectedSalesExec changes
  useEffect(() => {
    async function getUsername() {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser) {
          setUsername(currentUser.attributes.name);
        } else {
          setUsername(null);
        }
      } catch (error) {
        setUsername('');
        console.log('Error getting username:', error);
      }
    }
    getUsername();
  }, [username]);

  useEffect(() => {
    if (selectedAgent !== "All") {
      setSelectedSalesExec("All");
      setSelectedTerritory("All");
    }
  }, [selectedAgent]);

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const handleSalesExecChange = (event) => {
    setSelectedSalesExec(event.target.value);
  };

  const handleTerritoryChange = (event) => {
    setSelectedTerritory(event.target.value);
  };

  // array of types of income to show in the chart and how to retrieve from the object from the API
  const incomeSelected = [
    { title: "Interest", selector: "interest" },
    { title: "Late Fee", selector: "late_fee" },
    { title: "NSF Fee", selector: "nsf_fee" },
    { title: "Setup Fee", selector: "setup_fee" },
    { title: "Cancel Fee", selector: "cancel_fee" },
  ];

  const amountFinancedSelected = [
    { title: "Amount Financed", selector: "amount_financed" },
  ];


  // Include vsc_fee if client_id is '0001'
  if (clientId === '0001') {
    incomeSelected.push({ title: "Vehicle Service Fee", selector: "vsc_fee" });
  }

  const { tokens } = useTheme();

  const { data, isLoading } = useQuery(
    [
      `dashboard-income-data`,
      selectedDate,
      selectedAgent,
      selectedState,
      selectedSalesExec,
      selectedTerritory
    ],
    () =>
      fetchIncomeData({
        selectedDate,
        selectedAgent,
        selectedState,
        selectedSalesExec,
        selectedTerritory
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: fiscalYearData, isLoading: fiscalYearLoading } = useQuery(
    [`dashboard-fiscal-year-data`, selectedDate],
    () =>
      fetchFiscalYearStart({
        selectedDate,
      }),
    {
      enabled: !!selectedDate,
      refetchOnWindowFocus: false,
    },
  );

  const { data: fiscalYearBudgetData, isLoading: fiscalYearBudgetLoading } =
    useQuery(
      [`dashboard-fiscal-year-budget`, fiscalYear],
      () =>
        fetchFiscalYearBudget({
          fiscalYear,
        }),
      {
        enabled: !!fiscalYear,
        refetchOnWindowFocus: false,
      },
    );

  useEffect(() => {
    if (!isLoading && data)
      processIncomeData(
        data,
        setApiData,
        setMonthAmountFinanced,
        setYearAmountFinanced,
        setDayAmountFinanced,
        setYearPremium,
        setYearLoanCount,
        setMonthPremium,
        setMonthLoanCount,
        setDayPremium,
        setDayLoanCount,
        setYearEarnedInterest,
        setMonthEarnedInterest,
        setDayEarnedInterest
      );
  }, [data, isLoading]);

  useEffect(() => {
    if (
      fiscalYearData &&
      !fiscalYearLoading &&
      fiscalYearData?.fiscal_year_start_date &&
      fiscalYearData?.fiscal_year
    ) {
      setFiscalYear(fiscalYearData.fiscal_year);
      setFiscalStart(fiscalYearData.fiscal_year_start_date);
    }
  }, [fiscalYearData, fiscalYearLoading]);

  useEffect(() => {
    if (fiscalYearBudgetData && !fiscalYearBudgetLoading) {
      processFiscalYearBudget(
        fiscalYearBudgetData,
        setPlanData,
        setMonthPlanDataIndex,
        setMonthPremiumPlan,
        setMonthAmountFinancedPlan,
        setMonthNumberContractsPlan,
        setYearPremiumPlan,
        setYearAmountFinancedPlan,
        setYearNumberContractsPlan,
        selectedDate,
        fiscalStart,
      );
    }
  }, [
    fiscalStart,
    fiscalYearBudgetData,
    fiscalYearBudgetLoading,
    selectedDate,
  ]);

  useEffect(() => {
    const totalWorkingDays = getTotalWorkingDaysInMonth(dayjs(selectedDate));
    const totalWorkingDaysUntilToday = getTotalMonthWorkingDaysUntilDate(
      dayjs(selectedDate),
    );

    setTotalWorkingDaysThisMonth(totalWorkingDays);
    setTotalWorkingDaysUntilToday(totalWorkingDaysUntilToday);
    setPercentMonthCompleted(
      Math.round((totalWorkingDaysUntilToday / totalWorkingDays) * 100),
    );
  }, [selectedDate, selectedAgent, selectedState, selectedSalesExec]);

  useEffect(() => {
    setKey(Math.random()); // change key when selectedSalesExec changes so that Income vs Prior year updates
  }, [selectedSalesExec, selectedAgent, selectedState, selectedTerritory]);

  const handleDateChange = (date) => setSelectedDate(date);

  const isMinistatsLoading =
    isLoading || fiscalYearLoading || fiscalYearBudgetLoading;

  // when isMinistatsLoading turns to false, I want to call the voice summary function

  const { data: voiceData, isLoading: voiceLoading } = useQuery(
    [
      `dashboard-speech-data`,
      selectedDate,
      selectedAgent,
      selectedState,
      selectedSalesExec,
      selectedTerritory,
      isMinistatsLoading,
      yearAmountFinanced
    ],
    () => {


      console.log(
        "fetching voice data - ",
        selectedDate.format("YYYYMMDD") + " isMinistatsLoading - ",
        isMinistatsLoading
      );
      console.log("username: ", username);
      return fetchDailySummaryText({
        selectedDate,
        selectedAgent,
        selectedState,
        selectedSalesExec,
        selectedTerritory,
        yearAmountFinanced,
        yearAmountFinancedPlan,
        monthAmountFinanced,
        monthAmountFinancedPlan,
        dayAmountFinanced,
        yearPremium,
        yearPremiumPlan,
        monthPremium,
        monthPremiumPlan,
        dayPremium,
        username
      });
    },
    {
      enabled: !!selectedDate && !isMinistatsLoading && monthAmountFinancedPlan != 0,
      refetchOnWindowFocus: false,
    }
  );
  


     // Use effect to set voice summary once data is available
  useEffect(() => {
    if (voiceData && !voiceLoading) {
      setVoiceSummary(voiceData);
      console.log("Voice Summary: ", voiceData);
    }
  }, [voiceData, voiceLoading]); // Depend on voiceData and voiceLoading

  {/* Define the New Loans MiniStatistics */}
  const loanMiniStats = (
    <>
      <View rowSpan={{ base: 1, large: 1 }} columnSpan={[3, 3, 1, 1]}>
        <MiniStatistics
          isLoading={isMinistatsLoading}
          title={`New Loans on ${selectedDate.format("MM/DD/YYYY")}`}
          amount={dayLoanCount}
          icon={<MdCalendarToday />}
        />
      </View>
      <View rowSpan={{ base: 1, large: 1 }} columnSpan={[3, 3, 1, 1]}>
        <MiniStatistics
          isLoading={isMinistatsLoading}
          title="New Loans MTD"
          amount={monthLoanCount}
          budget={selectedAgent === "All" ? monthNumberContractsPlan : undefined}
          icon={<MdCalendarMonth />}
        />
      </View>
      <View rowSpan={{ base: 1, large: 1 }} columnSpan={[3, 3, 1, 1]}>
        <MiniStatistics
          isLoading={isMinistatsLoading}
          title="New Loans YTD"
          amount={yearLoanCount}
          budget={selectedAgent === "All" ? yearNumberContractsPlan : undefined}
          icon={<MdWbSunny />}
        />
      </View>
    </>
  );

  if (!selectedDate || hideAgentName == null) return <div>Loading...</div>;
  return (
    <>
      <DashboardWrapper>
        <DashboardHeading
          totalWorkingDaysThisMonth={totalWorkingDaysThisMonth}
          totalWorkingDaysUntilToday={totalWorkingDaysUntilToday}
          percentMonthCompleted={percentMonthCompleted}
          speechText={voiceSummary}
        />
        <DashboardFields
          handleDateChange={handleDateChange}
          selectedDate={selectedDate}
          setSelectedAgent={setSelectedAgent}
          selectedSalesExec={selectedSalesExec}
          selectedTerritory={selectedTerritory}
          handleStateChange={handleStateChange}
          handleSalesExecChange={handleSalesExecChange}
          handleTerritoryChange={handleTerritoryChange}
          selectedState={selectedState}
        />
      </DashboardWrapper>
      <View
        borderRadius="6px"
        maxWidth="100%"
        padding="0rem"
        minHeight="100vh"
        backgroundColor={tokens.colors.background.secondary}
      >
        <Grid
          templateColumns={{ base: "1fr", large: "1fr 1fr 1fr" }}
          templateRows={{ base: "repeat(4, 10rem)", large: "repeat(3, 8rem)" }}
          gap={tokens.space.xl}
        >
  {/* Conditionally render Premium MiniStatistics for clientId === "0001" */}
    {clientId === "0001" ? (
      <>
        <View rowSpan={{ base: 1, large: 1 }} columnSpan={[3, 3, 1, 1]}>
         <MiniStatistics
          isLoading={isMinistatsLoading}
          title={`Premium on ${selectedDate.format("MM/DD/YYYY")}`}
          amount={dayPremium}
          format="currency"
          icon={<MdCalendarToday />}
         />
        </View>
        <View rowSpan={{ base: 1, large: 1 }} columnSpan={[3, 3, 1, 1]}>
         <MiniStatistics
          isLoading={isMinistatsLoading}
          title="Premium MTD"
          amount={monthPremium}
          budget={selectedAgent === "All" ? monthPremiumPlan : undefined}
          format="currency"
          icon={<MdCalendarMonth />}
         />
        </View>
        <View rowSpan={{ base: 1, large: 1 }} columnSpan={[3, 3, 1, 1]}>
         <MiniStatistics
          isLoading={isMinistatsLoading}
          title="Premium YTD"
          amount={yearPremium}
          budget={selectedAgent === "All" ? yearPremiumPlan : undefined}
          format="currency"
          icon={<MdOutlineAttachMoney />}
         />
        </View>
      </>
    ) : null}

         <View rowSpan={{ base: 1, large: 1 }} columnSpan={[3, 3, 1, 1]}>
          <MiniStatistics
            isLoading={isMinistatsLoading}
            title={`Amount Financed on ${selectedDate.format("MM/DD/YYYY")}`}
            amount={dayAmountFinanced}
            format="currency"
            icon={<MdCalendarToday />}
          />
         </View>
         <View rowSpan={{ base: 1, large: 1 }} columnSpan={[3, 3, 1, 1]}>
          <MiniStatistics
            isLoading={isMinistatsLoading}
            title="Amount Financed MTD"
            amount={monthAmountFinanced}
            budget={
             selectedAgent === "All" ? monthAmountFinancedPlan : undefined
            }
            format="currency"
            icon={<MdCalendarMonth />}
          />
         </View>
         <View rowSpan={{ base: 1, large: 1 }} columnSpan={[3, 3, 1, 1]}>
          <MiniStatistics
            isLoading={isMinistatsLoading}
            title="Amount Financed YTD"
            amount={yearAmountFinanced}
            budget={
             selectedAgent === "All" ? yearAmountFinancedPlan : undefined
            }
            format="currency"
            icon={<MdOutlineAttachMoney />}
          />
         </View>


            {/* Conditionally render Loan count MiniStatistics for clientId != "0001" */}
          {clientId != "0001" ? ( 
            <>
            {loanMiniStats}
            </>
          ) : null}

          {clientId != "0002" && planData &&
            selectedAgent === "All" &&
            selectedState === "All" &&
            selectedSalesExec === "All" && 
            selectedTerritory === "All" && (
              <View columnSpan={[3]} rowSpan={{ base: 3, large: 3 }}>
                <Card borderRadius="15px">
                  <div className="card-title">Income vs. Plan</div>
                  <div className="chart-wrap">
                    <div className="row">
                      <IncomeVPlanChart
                        title="Income v Plan"
                        startDate={selectedDate.toDate()}
                        incomeTypes={incomeSelected}
                        agentCode={selectedAgent}
                        fiscalStart={fiscalStart}
                        selectedState={selectedState}
                        selectedSalesExec={selectedSalesExec}
                        {...(planData &&
                          planData.months.length > 0 && { planData })}
                        monthPlanDataIndex={monthPlanDataIndex}
                        options={{
                          yaxis: {
                            forceNiceScale: true,
                            labels: {
                              padding: 4,
                              formatter: (value) => {
                                return DollarString(value);
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </Card>
              </View>
            )}

        {clientId == "0002" && (
            <View columnSpan={[3]} rowSpan={{ base: 3, large: 3 }}>
                <Card borderRadius="15px">
                  <div className="card-title">Amount Financed vs. Plan</div>
                  <div className="chart-wrap">
                    <div className="row">
                      <IncomeYearCompare
                        startDate={selectedDate.toDate()}
                        incomeTypes={amountFinancedSelected}
                        agentCode={selectedAgent}
                        state={selectedState}
                        territoryCode={selectedTerritory}
                        solicitorCode={selectedSalesExec}
                        options={amountFinancedOptions}
                      />
                    </div>
                  </div>
                </Card>
              </View>
        )}
         {/*  render Earned Interest MiniStatistics */}
         <View rowSpan={{ base: 1, large: 1 }} columnSpan={[3, 3, 1, 1]}>
          <MiniStatistics
            isLoading={isMinistatsLoading}
            title={`Earned Interest on ${selectedDate.format("MM/DD/YYYY")}`}
            amount={dayEarnedInterest}
            format="currency"
            icon={<MdCalendarToday />}
          />
         </View>
         <View rowSpan={{ base: 1, large: 1 }} columnSpan={[3, 3, 1, 1]}>
          <MiniStatistics
            isLoading={isMinistatsLoading}
            title="Earned Interest MTD"
            amount={monthEarnedInterest}
            format="currency"
            icon={<MdCalendarMonth />}
          />
         </View>
         <View rowSpan={{ base: 1, large: 1 }} columnSpan={[3, 3, 1, 1]}>
          <MiniStatistics
            isLoading={isMinistatsLoading}
            title="Earned Interest YTD"
            amount={yearEarnedInterest}
            format="currency"
            icon={<MdOutlineAttachMoney />}
          />
         </View>

                       {/* Conditionally render Loan count MiniStatistics for clientId != "0001" */}
          {clientId === "0001" ? ( 
            <>
            {loanMiniStats}
            </>
          ) : null}
          {selectedAgent === "All" &&
            selectedState === "All" &&
            selectedSalesExec === "All" && 
            selectedTerritory === "All" && (
              <View columnSpan={[3, 3, 3, 2]} rowSpan={{ base: 3, large: 3 }}>
                <Card height="100%" borderRadius="15px">
                  <div className="chart-wrap">
                    <ScrollView height="280px">
                      {selectedDate ? (
                        <AgentRanking
                          title={`Top 10 Agents on ${selectedDate.format("MM/DD/YYYY")} by ${clientId === "0001" ? "Activated Premium" : "Amount Financed"}`}
                          type={agentRankType}
                          startDate={selectedDate.toDate()}
                          topN={10}
                          timeframe="day"
                          bShowFakeAgents={hideAgentName}
                          selectedDate={selectedDate}
                          excelExport={false}
                        />
                      ) : (
                        <Flex direction="column" minHeight="285px">
                          <Placeholder size="small" />
                          <Placeholder size="small" />
                          <Placeholder size="small" />
                          <Placeholder size="small" />
                        </Flex>
                      )}
                    </ScrollView>
                  </div>
                </Card>
              </View>
            )}

          <View columnSpan={[3, 3, 3, 1]} rowSpan={{ base: 3, large: 3 }}>
            <Card borderRadius="15px">
              <div className="card-title">
                Quote Activity as of {selectedDate.format("dddd")},{" "}
                {selectedDate.format("MM/DD/YYYY")}
              </div>
              <div className="chart-wrap">
                <div className="row">
                  <QuoteTable
                    startDate={selectedDate.toDate()}
                    agentCode={selectedAgent}
                    solicitorCode={selectedSalesExec}
                    state={selectedState}
                    territoryCode={selectedTerritory}
                  />
                </div>
              </div>
            </Card>
          </View>



          <View columnSpan={[3, 3, 3, 3]} rowSpan={{ base: 3, large: 3 }}>
            <Card borderRadius="15px">
              <div className="card-title">
                New Business Production as of {selectedDate.format("dddd")},{" "}
                {selectedDate.format("MM/DD/YYYY")}
              </div>{" "}
              <span
                style={{
                  textAlign: "left",
                  color: "black",
                  fontStyle: "italic",
                  fontSize: "small",
                  verticalAlign: "baseline",
                }}
              >
                ({totalWorkingDaysThisMonth - totalWorkingDaysUntilToday}{" "}
                working days left in month - {percentMonthCompleted}% complete)
              </span>
              <div className="chart-wrap">
                <div className="row">
                  {planData && planData.months.length > 0 && (
                    <IncomeTable
                      data={apiData}
                      {...(planData &&
                        planData.months.length > 0 && { planData })}
                      monthPlanDataIndex={monthPlanDataIndex}
                      percentMonthComplete={percentMonthCompleted}
                      startDate={selectedDate.toDate()}
                      clientId={clientId}
                    />
                  )}
                </div>
              </div>
            </Card>
          </View>

          <View columnSpan={[3]} rowSpan={{ base: 3, large: 3 }}>
            <Card borderRadius="15px">
              <div className="card-title">Income vs. Prior Year</div>
              <div className="chart-wrap">
                <div className="row">
                  <IncomeYearCompareOld
                    key={key}
                    title="Income Summary"
                    startDate={selectedDate.toDate()}
                    incomeTypes={incomeSelected}
                    agentCode={selectedAgent}
                    state={selectedState}
                    solicitorCode={selectedSalesExec}
                    territoryCode={selectedTerritory}
                    options={{
                      yaxis: {
                        forceNiceScale: true,
                        labels: {
                          padding: 4,
                          formatter: (value) => {
                            return DollarString(value);
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </Card>
          </View>
        </Grid>
      </View>
    </>
  );
}
