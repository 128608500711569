import React from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

const ExportSortableTableDataIcon = ({ tableData, title, selectedDate, columnMapping }) => {

  const handleExport = async () => {
    if (!tableData || tableData.length === 0) {
      console.log("No data to export.");
      return;
    }
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');
  
    // Set the columns with keys but without headers
    // const columnKeys = Object.keys(tableData[0]);
    const columnKeys = Object.keys(columnMapping);
    worksheet.columns = columnKeys.map(key => ({ key, width: key.length + 5 }));
  
    // Add the headline row at the top
    const headlineRow = worksheet.addRow([]);
    
    if (selectedDate) {
      headlineRow.getCell(1).value = title + ' (as of ' + selectedDate.format('MM/DD/YYYY') + ')';
    } else {
      headlineRow.getCell(1).value = title;
    }
    
    headlineRow.getCell(1).alignment = { horizontal: 'center' };

    headlineRow.font = { bold: true, size: 14 };
    worksheet.mergeCells('A1', `${String.fromCharCode(64 + columnKeys.length)}1`);
  
    // Add a blank row after the headline
    worksheet.addRow([]);
  
    // Add header row with formatted titles
    const headerRow = worksheet.addRow(
      columnKeys.map(key => key.replace(/__d/g, ''))
    );
    headerRow.font = { bold: true };

    // Add data rows
    tableData.forEach(data => {
      const rowData = Object.values(columnMapping).map(dataColumnName => data[dataColumnName]);
      worksheet.addRow(rowData);
    });
  
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(blob, 'exported_data.xlsx');
  };  

  return (
    <IconButton onClick={handleExport}>
      <FileDownloadIcon />
    </IconButton>
  );
};

export default ExportSortableTableDataIcon;
