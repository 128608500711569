import React, { useState } from "react";
import { Formik, Form, Field, FieldProps } from "formik";
import {
  Button,
  TextField,
  Box,
  CircularProgress,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import { validationSchema } from "./validationSchema";
import { handleSubmit } from "./utils";
import { useQuery } from "react-query";
import { fetchCurrentUserInfo } from "../../../api/utils/fetchCurrentUserInfo/fetchCurrentUserInfo";

export interface FormValues {
  name: string;
  role?: string;
}

const ChangeUserDetailsForm: React.FC = () => {
  const {
    data: userInfo,
    isLoading,
    refetch,
  } = useQuery("userInfo", fetchCurrentUserInfo);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      <Formik
        initialValues={{ name: userInfo?.attributes?.name || "", role:  userInfo?.attributes?.['custom:role'] || "" }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) =>
          handleSubmit({ name: values.name }, setSubmitting).then(() => {
            refetch();
            setOpenSnackbar(true);
          })
        }
      >
        {({ isSubmitting }) => (
          <>
            {isLoading && (
              <Stack alignItems="center">
                <CircularProgress />
              </Stack>
            )}

            {!isLoading && (
              <Form>
                <Field name="name">
                  {({ field, meta }: FieldProps) => (
                    <TextField
                      {...field}
                      id="name"
                      label="Name"
                      fullWidth
                      margin="normal"
                      error={Boolean(meta.touched && meta.error)}
                      helperText={meta.touched && meta.error ? meta.error : ""}
                    />
                  )}
                </Field>

                <Field name="role">
                  {({ field, meta }: FieldProps) => (
                    <TextField
                      {...field}
                      id="role"
                      label="Role"
                      fullWidth
                      margin="normal"
                      disabled
                      error={Boolean(meta.touched && meta.error)}
                      helperText={meta.touched && meta.error ? meta.error : ""}
                    />
                  )}
                </Field>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  sx={{
                    marginTop: 2,
                    backgroundColor: "var(--amplify-components-link-color)",
                    '&:hover': {
                      backgroundColor: "var(--amplify-components-link-color)", 
                    }
                  }}>
                  Update
                </Button>
              </Form>
            )}
          </>
        )}
      </Formik>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Your changes have been saved successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ChangeUserDetailsForm;
