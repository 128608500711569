import { AgentCancellationsProps, AgentRankingProps, AgentWriteoffProps } from "./types";
import dayjs from "dayjs";

export const colors = {
  day: "#9de2f2",
  week: "#aadae5",
  month: "#b5d2d9",
  dayPremium: "#a3eadd",
  weekPremium: "#8ae2d2",
  monthPremium: "#6fd9c7",
};
interface WriteoffBlockConfig
  extends Omit<AgentWriteoffProps, "startDate" | "selectedDate"> {}
interface CancellationBlockConfig
  extends Omit<AgentCancellationsProps, "startDate" | "selectedDate"> {}
interface RankingBlockConfig
  extends Omit<AgentRankingProps, "startDate" | "selectedDate"> {}

export const cancellationBlocks: CancellationBlockConfig[] = [
  {
    showTop: true,
    headerBgColor: colors.dayPremium,
    title: "Agents with Highest Cancellation Rates",
  },
  {
    showTop: false,
    headerBgColor: colors.dayPremium,
    title: "Agents with Lowest Cancellation Rates",
  },
];

export const writeoffBlocks: WriteoffBlockConfig[] = [
  {
    showTop: true,
    headerBgColor: colors.dayPremium,
    title: "Agents with Highest Write-offs",
  }
];
export const generateRevenueBlocks = (
  selectedDate: dayjs.Dayjs,
): RankingBlockConfig[] => {
  return [
    {
      title: "Top 100 Agents this Year by Revenue",
      type: "revenue",
      topN: 100,
      timeframe: "year",
    },
  ];
};



export const generateActivatedPremiumBlocks = (
  selectedDate: dayjs.Dayjs,
): RankingBlockConfig[] => {
  return [
    {
      title: `Top 20 Agents on ${selectedDate.format("MM/DD/YYYY")} by Activated Premium`,
      type: "activatedpremium",
      topN: 20,
      timeframe: "day",
      backgroundColor: colors.dayPremium,
      headerBgColor: colors.dayPremium,
    },
    {
      title: "Top 20 Agents this Week by Activated Premium",
      type: "activatedpremium",
      topN: 20,
      timeframe: "week",
      backgroundColor: colors.weekPremium,
      headerBgColor: colors.weekPremium,
    },
    {
      title: "Top 20 Agents this Month by Activated Premium",
      type: "activatedpremium",
      topN: 20,
      timeframe: "month",
      backgroundColor: colors.monthPremium,
      headerBgColor: colors.monthPremium,
    },
    {
      title: "Top 100 Agents this Year by Activated Premium",
      type: "activatedpremium",
      topN: 100,
      timeframe: "year",
    },
  ];
};

export const generateQuoteBlocks = (
  selectedDate: dayjs.Dayjs,
): RankingBlockConfig[] => {
  return [
    {
      title: `Top 20 Agents on ${selectedDate.format("MM/DD/YYYY")} by Quote Count`,
      type: "quotecount",
      topN: 20,
      timeframe: "day",
      backgroundColor: colors.day,
      headerBgColor: colors.day,
    },
    {
      title: "Top 20 Agents this Week by Quote Count",
      type: "quotecount",
      topN: 20,
      timeframe: "week",
      backgroundColor: colors.week,
      headerBgColor: colors.weekPremium, // Assuming you want to use the premium color here
    },
    {
      title: "Top 20 Agents this Month by Quote Count",
      type: "quotecount",
      topN: 20,
      timeframe: "month",
      backgroundColor: colors.month,
      headerBgColor: colors.month,
    },
    {
      title: "Top 100 Agents this Year by Quote Count",
      type: "quotecount",
      topN: 100,
      timeframe: "year",
    },
  ];
};
