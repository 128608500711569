import React, { useState, useEffect } from 'react';
import SegmentRow from './SegmentRow';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const SegmentList = ({ segments, setSegments, fiscal_year, setHasInvalidSegments}) => {

  useEffect(() => {
    // Check if any segment is invalid
    const isInvalid = segments.some(segment => segment === '');
    setHasInvalidSegments(isInvalid);
  }, [segments]);

  const addSegment = () => {
    setSegments([...segments, '']);
  };

  const deleteSegment = (index) => {
    const newSegments = segments.filter((_, i) => i !== index);
    setSegments(newSegments);
  };

  const updateSegment = (index, newName) => {
    const newSegments = segments.map((name, i) => (i === index ? newName : name));
    setSegments(newSegments);
  };

  return (
    <div>
      {segments.map((name, index) => (
        <SegmentRow
          key={index + fiscal_year + name}
          name={name}
          onDelete={() => deleteSegment(index)}
          onUpdate={(newName) => updateSegment(index, newName)}
					fiscal_year={fiscal_year}
        />
      ))}
			<Button startIcon={<AddIcon />} onClick={addSegment}>
        Add Segment
      </Button>
    </div>
  );
}

export default SegmentList;
