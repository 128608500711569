import { useState, useEffect } from "react";
import { Amplify, Auth, API } from 'aws-amplify';
import { Heading, View } from '@aws-amplify/ui-react';
import { Link } from "react-router-dom";
import ViewPlanPage from "./ViewPlan";
import { useNavigate } from 'react-router-dom';

import { getApiRequestInfo } from "../api/utils";

export function PlanListPage() {
  const navigate = useNavigate();
  const [objects, setPlans] = useState([]);
  const [editingPlan, setEditingPlan] = useState(null);

  // load the exist plans from the backend
  async function fetchPlansData() {
    const data = "";

    const requestInfo = await getApiRequestInfo(null);
    console.log("---------- before API call to getPlan");

    API.get("idpro", "/annualplans", requestInfo)
    .then(response => {
        console.log("response: " + JSON.stringify(response));
        setPlans(response.Items);
    }).catch(error => {
        console.error(error);
        if( error.response && error.response.status === 404 ) {
            console.log("404 error");
            setPlans("not found");
        }
    });
    console.log("---------- AFTER API call in fetchPlansData");
}

    // load plans when page first loads
  useEffect(() => {

    fetchPlansData();
   
  }, []);

  const handleEdit = (object) => {
    setEditingPlan(object);
  };



  return (
    <View as="div" padding="1rem">
      <Heading level={4}>Monthly Plans by Fiscal Year</Heading>
      <ul>
        {objects.map((object) => (
          <li key={object.id}>
            <Link to={`/annualplan/0001/${object.fiscal_year}`} onClick={() => handleEdit(object)}>
              Fiscal Year: {object.fiscal_year}
            </Link>
          </li>
        ))}
      </ul>

    </View>
  );
}

