import React, { useState, useEffect, useContext } from "react";
import { Table, TableCell, TableBody, TableHead, TableRow, Button } from "@aws-amplify/ui-react";
import { Auth, API } from "aws-amplify";
import { CircularProgress } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import AdminEditUserPopUp from "./AdminEditUserPopUp";
import AdminCreateUserPopUp from "./AdminCreateUserPopUp";

import { StateContext } from "../../StateContext";

import { Chat } from "@mui/icons-material";

import { getApiRequestInfo } from "../../api/utils";
import { utcISOToEasternDatetime } from "../../utils";

const AdminUsersView = () => {
  const {isAdmin, isSuperAdmin, superAdminClientId, currentUser} = useContext(StateContext);
  console.log('AdminUsersView isAdmin: ', isAdmin);
  const [matchingUsers, setMatchingUsers] = useState([]); // To store matching users
  const [selectedUser, setSelectedUser] = useState(null); // Currently selected user to edit
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isFetchingUsers, setIsFetchingUsers] = useState(true);
  const [fetchingUsersMsg, setIsFetchingUsersMsg] = useState('Fetching Users...');

  const fetchUsers = async () => {
    setIsFetchingUsers(true);

    const requestInfo = await getApiRequestInfo(null);
    const url = '/users';
    API.get("idpro", url, requestInfo)
    .then(filteredUsers => {
      console.log("API filteredUsers: \n", filteredUsers);
      setMatchingUsers(filteredUsers);
      setIsFetchingUsers(false);
    }).catch(error => {
      console.log("error: " + JSON.stringify(error));
    });
  };

  useEffect(() => {
    // Dummy data fetch function
    const fetchUsersMock = async () => {
      setIsFetchingUsers(true);
      await delay(0);
      const filteredUsers = [
          {
              "username": "1",
              "attributes": {
                  "sub": "1",
                  "custom:clientid": "0001",
                  "name": "Luke Atmey",
                  "phone_number_verified": true,
                  "phone_number": "+123456789000",
                  "custom:role": "admin",
                  "email": "wawa1@example.org"
              },
              "preferredMFA": "SMS"
          },
          {
              "username": "2",
              "attributes": {
                  "sub": "2",
                  "custom:clientid": "0001",
                  "name": "Dick Gumshoe",
                  // "phone_number_verified": true,
                  // "phone_number": "+123456789000",
                  "custom:role": "agent",
                  "custom:company_code": "100",
                  "email": "wawa2@example.org"
              },
              "preferredMFA": "TOTP"
          },
          {
              "username": "3",
              "attributes": {
                  "sub": "3",
                  "custom:clientid": "0002",
                  "name": "Hercule Poirot",
                  "phone_number_verified": false,
                  "phone_number": "+123456789001",
                  "custom:role": "admin",
                  "email": "detective3@example.org"
              },
              "preferredMFA": "SMS"
          },
          {
              "username": "4",
              "attributes": {
                  "sub": "4",
                  "custom:clientid": "0003",
                  "name": "Miss Marple",
                  "phone_number_verified": true,
                  "phone_number": "+123456789002",
                  "custom:role": "user",
                  "email": "sleuth4@example.org"
              },
              "preferredMFA": "NOMFA"
          },
          {
              "username": "5",
              "attributes": {
                  "sub": "5",
                  "custom:clientid": "0001",
                  "name": "Sherlock Holmes",
                  "phone_number_verified": true,
                  "phone_number": "+123456789003",
                  "custom:role": "user",
                  "email": "detective5@example.org"
              },
              "preferredMFA": "TOTP"
          },
          {
              "username": "6",
              "attributes": {
                  "sub": "6",
                  "custom:clientid": "0002",
                  "name": "Nancy Drew",
                  "phone_number_verified": false,
                  "phone_number": "+123456789004",
                  "custom:role": "user",
                  "email": "mystery6@example.org"
              },
              "preferredMFA": "SMS"
          },
          {
              "username": "7",
              "attributes": {
                  "sub": "7",
                  "custom:clientid": "0003",
                  "name": "C. Auguste Dupin",
                  "phone_number_verified": false,
                  "phone_number": "+123456789005",
                  "custom:role": "user",
                  "email": "detective7@example.org"
              },
              "preferredMFA": "NOMFA"
          },
          {
              "username": "8",
              "attributes": {
                  "sub": "8",
                  "custom:clientid": "0001",
                  "name": "Miles Edgeworth",
                  "phone_number_verified": true,
                  "phone_number": "+123456789000",
                  "custom:role": "admin",
                  "email": "miles@example.org"
              },
              "preferredMFA": "TOTP"
          },
      ]
      setMatchingUsers(filteredUsers);
      setIsFetchingUsers(false);
    };

    currentUser && fetchUsers(); //TODO: change to fetchUsers
  }, [currentUser]);

  const handleDoubleClick = (user) => {
    console.log('User double-clicked:', user);
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const handleCreateUser = () => {
    // Logic to handle the creation of a new user
    console.log("Create User button clicked");
    setIsCreateModalOpen(true);
  };

  const updateUserList = (fetchingMsg) => {
    setIsFetchingUsersMsg(fetchingMsg);
    fetchUsers();
  }

  // for testing only
  async function delay(seconds) {
    return new Promise((resolve) => {
      setTimeout(resolve, seconds * 1000);
    });
  }

  return (
    <>
      {(!isAdmin && !isSuperAdmin) && (
        <div>
          {/* empty page for non admins, show Notice to user? */}
        </div>
      )}
      {
        (isAdmin || (isSuperAdmin && superAdminClientId)) && (
          <>
            <div>
              <h2>Users</h2>
            </div>
            {isFetchingUsers ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <CircularProgress size={20} color="inherit" />
                <label className="amplify-label">{fetchingUsersMsg}</label>
              </div>
            ) : (
              <>
                <Table highlightOnHover={true}>
                  <TableHead>
                    <TableRow>
                      <TableCell as="th">Name</TableCell>
                      <TableCell as="th">Role</TableCell>
                      <TableCell as="th">Email</TableCell>
                      <TableCell as="th">Phone</TableCell>
                      <TableCell as="th">Latest Login</TableCell>
                      <TableCell as="th">MFA</TableCell>
                      <TableCell as="th">Enabled</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {matchingUsers.map((user) => (
                      <TableRow 
                        key={user.username}
                        onDoubleClick={() => handleDoubleClick(user)}
                      >
                        <TableCell>{user.attributes.name}</TableCell>
                        <TableCell>{user.attributes['custom:role']}</TableCell>
                        <TableCell>{user.attributes.email}</TableCell>
                        <TableCell>{user.attributes.phone_number}</TableCell>
                        <TableCell>
                          {(user.attributes['custom:last_login_datetime'] ? utcISOToEasternDatetime(user.attributes['custom:last_login_datetime']) + '\n' : '')  + (user.attributes['custom:last_login_ip'] ? user.attributes['custom:last_login_ip'] : '')}
                        </TableCell>
                        <TableCell>{user.preferredMFA}</TableCell>
                        <TableCell>
                          {user.enabled ? (
                            <span style={{ color: 'green' }}>
                              <CheckCircleIcon />
                            </span>
                          ) : (
                            <span style={{ color: 'red' }}>
                              <CancelIcon />
                            </span>
                          )}
                        </TableCell>
                        {/* <TableCell>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            {user.enabled ? (
                              <span style={{ color: 'green' }}>
                                <CheckCircleIcon /> Enabled
                              </span>
                            ) : (
                              <span style={{ color: 'red' }}>
                                <CancelIcon /> Disabled
                              </span>
                            )}
                          </div>
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Button onClick={handleCreateUser}>Create User</Button>
                </div>
              </>
            )}

            {selectedUser && (
              <AdminEditUserPopUp
                isOpen={isEditModalOpen}
                setIsOpen={(boolVal) => setIsEditModalOpen(boolVal)}
                selectedUser={selectedUser}
                currentUser={currentUser}
                updateUserList={updateUserList}
              />
            )}

            {isCreateModalOpen && (
              <AdminCreateUserPopUp
                isOpen={isCreateModalOpen}
                setIsOpen={(boolVal) => setIsCreateModalOpen(boolVal)}
                currentUser={currentUser}
                updateUserList={updateUserList}
              />
            )}
          </>
        )
      }
    </>
  );
};

export default AdminUsersView;
