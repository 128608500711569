import React, { useEffect, useMemo, useState } from "react";
import {
  BudgetDataPayload,
  DataItem,
} from "../../pages/pfc_dashboard/actual_v_plan_segment/utils";
import { assessData, makeChartData } from "./utils";
import { useFormikContext } from "formik";
import Chart from "react-apexcharts";
import { defaultOptions } from "./constants";
import { DollarString } from "../../utils";
import { StyledHeading } from "./styles";

export interface AvPSegmentChartProps {
  monthlyData: DataItem[];
  budgetData: BudgetDataPayload;
  labels: string[];
}

export const AvPSegmentChart = ({
  budgetData,
  monthlyData,
  labels,
}: AvPSegmentChartProps) => {
  const { values } = useFormikContext<{
    isFinancial: boolean;
    selectedBudgetItem: string;
  }>();

  const [noData, setNoData] = useState(false);

  useEffect(() => {
    const assessment = !assessData({ budgetData, monthlyData, labels });

    if (assessment !== noData) setNoData(assessment);
  }, [budgetData, monthlyData, labels]);

  return (
    <>
      {noData && (
        <StyledHeading placeholder={undefined}>No data available</StyledHeading>
      )}

      {!noData &&
        // this ternary is not an ideal solution, but we need a way of forcing the re-render of the chart with new y axis formatting, dynamically propping the formatter does not seem to work so the y axis does not redraw
        (values?.isFinancial ? (
          <Chart
            // @ts-ignore
            options={Object.assign(
              {},
              defaultOptions,
              {
                yaxis: {
                  forceNiceScale: true,
                  labels: {
                    padding: 4,
                    formatter: (value: any) => {
                      return DollarString(value);
                    },
                  },
                },
              },
              {
                labels,
              },
            )}
            // @ts-ignore
            series={
              makeChartData(budgetData, values?.selectedBudgetItem, monthlyData)
                .barChatData as any
            }
            height={350}
          />
        ) : (
          <Chart
            // @ts-ignore
            options={Object.assign({}, defaultOptions, {
              labels,
            })}
            // @ts-ignore
            series={
              makeChartData(budgetData, values?.selectedBudgetItem, monthlyData)
                .barChatData as any
            }
            height={350}
          />
        ))}
    </>
  );
};
