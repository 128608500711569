import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Button,
} from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { CircularProgress, Select, MenuItem, TextField } from "@mui/material";
import { ScrollView } from "@aws-amplify/ui-react";
import dayjs from "dayjs";
import { StateContext } from "../../StateContext";
import { getApiRequestInfo } from "../../api/utils";

import BudgetItemCell from "./BudgetItemCell";

import SegmentList from "./SegmentList";
import _ from "lodash";

const BudgetPlanView = () => {
  const { isSuperAdmin, isAdmin, currentUser } = useContext(StateContext);
  const [budgetPlans, setBudgetPlans] = useState([]);
  const [selectedBudgetPlan, setSelectedBudgetPlan] = useState({ months: [] });

  const [segmentLists, setSegmentLists] = useState([]);
  const [selectedSegmentList, setSelectedSegmentList] = useState(null);

  const [fiscalYears, setFiscalYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");

  const [fiscalYearsSegments, setFiscalYearsSegments] = useState([]);
  const [selectedYearSegments, setSelectedYearSegments] = useState("");

  const [isFetching, setIsFetching] = useState(true);
  const [fetchingMsg, setFetchingMsg] = useState("Fetching Budget Plans...");

  const [isFetchingSegments, setIsFetchingSegments] = useState(true);
  const [fetchingSegmentsMsg, setFetchingSegmentsMsg] = useState(
    "Fetching Segments...",
  );

  const fiscal_year_start_month_day = useRef("0101");

  const [isEdited, setIsEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [isEditedSegments, setIsEditedSegments] = useState(false);
  const [isSavingSegments, setIsSavingSegments] = useState(false);

  const [isNewPlanCreating, setIsNewPlanCreating] = useState(false);
  const [newFiscalYear, setNewFiscalYear] = useState("");
  const [newDisplayFiscalYear, setNewDisplayFiscalYear] = useState("");

  const [isCreatingNewSegments, setIsCreatingNewSegments] = useState(false);
  const [newFiscalYearSegments, setNewFiscalYearSegments] = useState("");
  const [newDisplayFiscalYearSegments, setNewDisplayFiscalYearSegments] =
    useState("");

  const newBudgetPlan = useRef(null);
  const newSegmentList = useRef(null);

  const [newYearWarning, setNewYearWarning] = useState("");
  const [newYearWarningSegments, setNewYearWarningSegments] = useState("");

  const [hasInvalidSegments, setHasInvalidSegments] = useState(false);

  const fetchBudgetPlans = async () => {
    setIsFetching(true);
    const requestInfo = await getApiRequestInfo(null);
    var configurl = "/config/" + dayjs().format('YYYYMMDD');
    //var configurl = "/config/20231001";
    API.get("idpro", configurl, requestInfo)
      .then((response) => {
        console.log("config response: " + JSON.stringify(response));
        const start_fiscal_year_date = response.fiscal_year_start_date;
        fiscal_year_start_month_day.current =
          start_fiscal_year_date.substring(4);

        var budgeturl = "/allannualplans/";
        API.get("idpro", budgeturl, requestInfo)
          .then((response) => {
            const budgetPlans = response;
            setBudgetPlans(budgetPlans);
            const years = new Set(budgetPlans.map((plan) => plan.fiscal_year));
            setFiscalYears([...years].sort((a, b) => a - b));
          })
          .catch((error) => {
            console.log("error: " + JSON.stringify(error));
          });
        setIsFetching(false);
      })
      .catch((error) => {
        console.log("error: " + JSON.stringify(error));
        setIsFetching(false);
      });
  };

  const fetchSegmentLists = async () => {
    setIsFetchingSegments(true);
    const requestInfo = await getApiRequestInfo(null);
    var configurl = "/config/" + dayjs().format('YYYYMMDD');
    //var configurl = "/config/20231001";
    API.get("idpro", configurl, requestInfo)
      .then((response) => {
        console.log("config response: " + JSON.stringify(response));
        const start_fiscal_year_date = response.fiscal_year_start_date;
        fiscal_year_start_month_day.current =
          start_fiscal_year_date.substring(4);

        var budgeturl = "/allsegmentlists/";
        API.get("idpro", budgeturl, requestInfo)
          .then((response) => {
            const segmentLists = response;
            setSegmentLists(segmentLists);
            const years = new Set(segmentLists.map((list) => list.fiscal_year));
            setFiscalYearsSegments([...years].sort((a, b) => a - b));
          })
          .catch((error) => {
            console.log("error: " + JSON.stringify(error));
          });
        setIsFetchingSegments(false);
      })
      .catch((error) => {
        console.log("error: " + JSON.stringify(error));
        setIsFetchingSegments(false);
      });
  };

  const fetchSegmentsListsMock = async () => {
    const segmentsLists = [
      {
        clientid: "0001",
        fiscal_year: 2022,
        segments: [
          "segment1",
          // 'segment2',
          // 'segment3',
          // 'segment4',
          // 'segment5',
          // 'segment6',
          // 'segment7',
          // 'segment8',
        ],
      },
      {
        clientid: "0001",
        fiscal_year: 2023,
        segments: [
          "segment1",
          "segment2",
          // 'segment3',
          // 'segment4',
          // 'segment5',
          // 'segment6',
          // 'segment7',
          // 'segment8',
          // 'segment9',
          // 'segment10'
        ],
      },
    ];
    setSegmentLists(segmentsLists);
    const years = new Set(segmentsLists.map((plan) => plan.fiscal_year));
    setFiscalYearsSegments([...years].sort((a, b) => a - b));
  };

  useEffect(() => {
    if (budgetPlans.length === 0) {
      fetchBudgetPlans();
    }

    if (segmentLists.length === 0) {
      fetchSegmentLists();
    }
  }, []);

  useEffect(() => {
    if (budgetPlans.length > 0) {
      if (selectedBudgetPlan.fiscal_year) {
        const refreshedBudgetPlan = budgetPlans.find(
          (plan) => plan.fiscal_year === selectedBudgetPlan.fiscal_year,
        );
        refreshedBudgetPlan && setSelectedBudgetPlan(refreshedBudgetPlan);
      }
    }
  }, [budgetPlans]);

  useEffect(() => {
    if (segmentLists.length > 0) {
      setSelectedSegmentList(null);
    }
  }, [segmentLists]);

  useEffect(() => {
    if (selectedYear !== "") {
      const selectedPlan = budgetPlans.find(
        (plan) => plan.fiscal_year === selectedYear,
      );
      setSelectedBudgetPlan(selectedPlan);
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selectedYearSegments !== "") {
      //log the fiscal_year for every plan in budgetPlans
      const selectedList = segmentLists.find(
        (segmentList) => segmentList.fiscal_year === selectedYearSegments,
      );
      setSelectedSegmentList(selectedList);
    }
  }, [selectedYearSegments]);

  useEffect(() => {
    if (newFiscalYear !== "") {
      createNewBudgetPlan();
      newBudgetPlan.current.fiscal_year = parseInt(newFiscalYear);
      //check if budgetplan with this year already exists
      const existingPlan = budgetPlans.find(
        (plan) => plan.fiscal_year === newBudgetPlan.current.fiscal_year,
      );
      if (existingPlan) {
        //replace existing plan with new plan
        const updatedPlans = budgetPlans.filter(
          (plan) => plan.fiscal_year !== newBudgetPlan.current.fiscal_year,
        );
        updatedPlans.push(newBudgetPlan.current);
        setBudgetPlans(updatedPlans);
      }
    }
  }, [newFiscalYear]);

  useEffect(() => {
    if (newFiscalYearSegments !== "") {
      // newSegmentList.current.fiscal_year = parseInt(newFiscalYearSegments);
      createNewSegmentList();
      //check if budgetplan with this year already exists
    }
  }, [newFiscalYearSegments]);

  const updateSegments = (newSegments) => {
    setIsEditedSegments(true);
    setSelectedSegmentList({ ...selectedSegmentList, segments: newSegments });
  };

  const handleCellChange = (index, field, newBudgetItemDict) => {
    // Create a new copy of the selectedBudgetPlan
    let updatedPlan = { ...selectedBudgetPlan };

    // Check if the field exists in the monthly plan and update the value
    if (updatedPlan.months[index][field]) {
      if (!_.isEqual(updatedPlan.months[index][field], newBudgetItemDict)) {
        console.log("****budgetItem was edited!");
        setIsEdited(true);
      }
      updatedPlan.months[index][field] = newBudgetItemDict;
    }

    setSelectedBudgetPlan(updatedPlan);
  };

  const handleYearChangeSegments = (event) => {
    setSelectedYearSegments(event.target.value);
    setNewFiscalYearSegments("");
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setNewFiscalYear("");
  };

  const saveSegmentChanges = async () => {
    // Implement the logic to save changes
    setIsSavingSegments(true);

    await delay(2);
    const requestInfo = await getApiRequestInfo(selectedSegmentList);
    if (isCreatingNewSegments) {
      const url = "/segmentlist/" + newFiscalYearSegments;
      await API.post("idpro", url, requestInfo)
        .then((response) => {
          console.log("Created BudgetItem SegmentList: ", response);
        })
        .catch((error) => {
          console.error("Could not create SegmentList: ", error);
        });

      //add new year to fiscalYears
      const years = [...fiscalYearsSegments];
      years.push(parseInt(newFiscalYearSegments));
      setFiscalYearsSegments(years.sort((a, b) => a - b));
      setSelectedYearSegments(parseInt(newFiscalYearSegments));

      setIsCreatingNewSegments(false);
    } else {
      const url = "/segmentlist/" + selectedYearSegments;
      await API.put("idpro", url, requestInfo)
        .then((response) => {
          console.log("Updated SegmentList: ", response);
        })
        .catch((error) => {
          console.error("Could not update SegmentList: ", error);
        });
      fetchBudgetPlans();
    }

    setSelectedYearSegments("");
    setNewDisplayFiscalYearSegments("");
    setIsSavingSegments(false);
    setIsEditedSegments(false); // Reset isEdited after saving
    fetchSegmentLists();
    setSelectedSegmentList(null);
  };

  const saveChanges = async () => {
    // Implement the logic to save changes
    setIsSaving(true);
    console.log("Saving changes:", selectedBudgetPlan);
    const requestInfo = await getApiRequestInfo(selectedBudgetPlan);
    console.log("Created BudgetPlan requestInfo: \n", requestInfo);
    if (isNewPlanCreating) {
      const url = "/annualplan/" + newFiscalYear;
      await API.post("idpro", url, requestInfo)
        .then((response) => {
          console.log("Created BudgetPlan: ", response);
        })
        .catch((error) => {
          console.error("Could not create budgetPlan: ", error);
        });
      // await delay(2);

      //add new year to fiscalYears
      const years = [...fiscalYears];
      years.push(parseInt(newFiscalYear));
      setFiscalYears(years.sort((a, b) => a - b));
      setSelectedYear(parseInt(newFiscalYear));

      setIsNewPlanCreating(false);
    } else {
      const url = "/annualplan/" + selectedYear;
      await API.put("idpro", url, requestInfo)
        .then((response) => {
          console.log("Updated BudgetPlan: ", response);
        })
        .catch((error) => {
          console.error("Could not update budgetPlan: ", error);
        });
    }

    setIsSaving(false);
    setIsEdited(false); // Reset isEdited after saving
    setSelectedBudgetPlan({ months: [] });
    setSelectedYear("");
    setNewDisplayFiscalYear("");
    fetchBudgetPlans();
  };

  async function delay(seconds) {
    return new Promise((resolve) => {
      setTimeout(resolve, seconds * 1000);
    });
  }

  const createNewSegmentList = (newFiscalYear) => {
    newSegmentList.current = {
      clientid: "HardcodedClientID", //TODO Replace with actual client ID as needed
      fiscal_year: parseInt(newFiscalYearSegments),
      segments: [],
    };
    setSelectedSegmentList(newSegmentList.current);
    // setSegmentLists(prevLists => [...prevLists, newSegmentList.current]);
  };

  const handleNewSegmentsClick = () => {
    setIsCreatingNewSegments(true);
    // createNewSegmentList();
    setNewYearWarningSegments(" ");
  };

  const createNewBudgetPlan = () => {
    // get the segmentList for the current fiscal_year or the most recent fiscal_year
    const recentSegmentList = [...segmentLists]
      .sort((a, b) => parseInt(b.fiscal_year) - parseInt(a.fiscal_year))
      .find((list) => parseInt(list.fiscal_year) <= parseInt(newFiscalYear));

    const segments = recentSegmentList ? recentSegmentList.segments : [];
    const segmentsWithValues = segments.map((segmentName) => ({
      name: segmentName,
      value: 0.0,
    }));
    newBudgetPlan.current = {
      clientid: "HardcodedClientID", // TODOReplace with actual client ID as needed
      fiscal_year: parseInt(newFiscalYear),
      months: Array(12)
        .fill()
        .map(() => ({
          amount_financed: { segments: segmentsWithValues, total: 0.0 },
          cancel_fee: { segments: segmentsWithValues, total: 0.0 },
          interest: { segments: segmentsWithValues, total: 0.0 },
          late_fee: { segments: segmentsWithValues, total: 0.0 },
          nsf_fee: { segments: segmentsWithValues, total: 0.0 },
          number_contracts: { segments: segmentsWithValues, total: 0.0 },
          premium: { segments: segmentsWithValues, total: 0.0 },
          setup_fee: { segments: segmentsWithValues, total: 0.0 },
        })),
    };
    setSelectedBudgetPlan(newBudgetPlan.current);
    setBudgetPlans((prevPlans) => [...prevPlans, newBudgetPlan.current]);
  };

  const handleUndoSegmentListClick = () => {
    setSelectedSegmentList(null);
    isCreatingNewSegments && setIsCreatingNewSegments(false);
    isEditedSegments && setIsEditedSegments(false);
    !!newYearWarningSegments && setNewYearWarningSegments("");
    !!newDisplayFiscalYearSegments && setNewDisplayFiscalYearSegments("");
  };

  const handleUndoPlanClick = () => {
    setSelectedBudgetPlan({ months: [] });
    isNewPlanCreating && setIsNewPlanCreating(false);
    isEdited && setIsEdited(false);
    !!newYearWarning && setNewYearWarning("");
    !!newDisplayFiscalYear && setNewDisplayFiscalYear("");
  };

  const handleNewPlanClick = () => {
    setIsNewPlanCreating(true);
    setSelectedYear("");
    // createNewBudgetPlan();
    setNewYearWarning(" ");
  };

  const handleNewFiscalYearSegments = (event) => {
    const year = event.target.value;
    console.log();
    setNewDisplayFiscalYearSegments(year);
    if (
      year.length === 4 &&
      !isNaN(year) &&
      !fiscalYearsSegments.includes(parseInt(year))
    ) {
      setNewFiscalYearSegments(year);
      setNewYearWarningSegments("");
      setIsEditedSegments(true);
    } else {
      setNewYearWarningSegments("Invalid year");
    }
  };

  const handleNewFiscalYear = (event) => {
    const year = event.target.value;
    setNewDisplayFiscalYear(year);
    if (
      year.length === 4 &&
      !isNaN(year) &&
      !fiscalYears.includes(parseInt(year))
    ) {
      setNewFiscalYear(year);
      setNewYearWarning("");
      setIsEdited(true);
    } else {
      setNewYearWarning("Invalid year");
    }
  };

  const getMonthForRow = (startMonthDay, index, year, showDay = false) => {
    let yearInt = parseInt(year);
    if (newDisplayFiscalYear !== "") {
      yearInt = parseInt(newDisplayFiscalYear);
    }
    let [month, day] = [
      parseInt(startMonthDay.substring(0, 2)),
      parseInt(startMonthDay.substring(2, 4)),
    ];
    if( month != 1) {   // if fiscal year doesn't start in January, that means it starts the year before
      yearInt--;      
    }
    const date = new Date(yearInt, month - 1, day); // Using the selected year
    date.setMonth(date.getMonth() + index); // Add index months

    // Format to MM/DD/YYYY
    let formattedMonth = String(date.getMonth() + 1).padStart(2, "0");
    let formattedDay = String(date.getDate()).padStart(2, "0");
    let formattedYear = date.getFullYear();

    let formattedDate = "";

    if (showDay) {
      formattedDate = `${formattedMonth}/${formattedDay}/${formattedYear}`;
    } else {
      formattedDate = `${formattedMonth}/${formattedYear}`;
    }

    return formattedDate;
  };

  return (
    <>
      {!(isSuperAdmin || isAdmin) && (
        <div>{/* empty page for non admins */}</div>
      )}
      {(isSuperAdmin || isAdmin) && (
        <>
          <div>
            <h2>Budget Plans</h2>
          </div>

          {isSuperAdmin && (
            <div style={{ marginBottom: "10px" }}>
              {isFetchingSegments ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <CircularProgress size={20} color="inherit" />
                  <label className="amplify-label">{fetchingSegmentsMsg}</label>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {!isCreatingNewSegments && (
                      <Select
                        value={selectedYearSegments}
                        onChange={handleYearChangeSegments}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="" disabled>
                          Select Fiscal Year
                        </MenuItem>
                        {fiscalYearsSegments.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    )}

                    {isCreatingNewSegments && (
                      <>
                        <TextField
                          label="Enter Fiscal Year"
                          value={newDisplayFiscalYearSegments}
                          onChange={handleNewFiscalYearSegments}
                          name="new_fiscal_year_segments"
                          margin="normal"
                          color={
                            !!newYearWarningSegments ? "warning" : "primary"
                          }
                          error={!!newYearWarningSegments}
                          helperText={
                            newYearWarningSegments ? newYearWarningSegments : ""
                          }
                        />
                      </>
                    )}

                    {isSavingSegments ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <CircularProgress size={20} color="inherit" />
                        <label className="amplify-label">{"Saving..."}</label>
                      </div>
                    ) : (
                      <>
                        {isEditedSegments && (
                          <Button
                            onClick={saveSegmentChanges}
                            color="primary"
                            disabled={
                              !!newYearWarningSegments || hasInvalidSegments
                            }
                          >
                            Save Changes
                          </Button>
                        )}
                      </>
                    )}

                    {(isEditedSegments || isCreatingNewSegments) && (
                      <Button
                        onClick={handleUndoSegmentListClick}
                        color="primary"
                        disabled={isSavingSegments}
                      >
                        Undo
                      </Button>
                    )}

                    {!isEditedSegments && !isCreatingNewSegments && (
                      <Button
                        onClick={handleNewSegmentsClick}
                        color="primary"
                        disabled={!!newYearWarningSegments}
                      >
                        New Segments
                      </Button>
                    )}
                  </div>

                  {selectedSegmentList && (
                    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                      <SegmentList
                        segments={selectedSegmentList.segments}
                        setSegments={updateSegments}
                        fiscal_year={selectedSegmentList.fiscal_year}
                        setHasInvalidSegments={setHasInvalidSegments}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {isFetching ? (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <CircularProgress size={20} color="inherit" />
              <label className="amplify-label">{fetchingMsg}</label>
            </div>
          ) : (
            <>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                {!isNewPlanCreating && (
                  <Select
                    value={selectedYear}
                    onChange={handleYearChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="" disabled>
                      Select Fiscal Year
                    </MenuItem>
                    {fiscalYears.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                )}

                {isNewPlanCreating && (
                  <>
                    <TextField
                      label="Enter Fiscal Year"
                      value={newDisplayFiscalYear}
                      onChange={handleNewFiscalYear}
                      name="new_fiscal_year"
                      margin="normal"
                      color={!!newYearWarning ? "warning" : "primary"}
                      error={!!newYearWarning}
                      helperText={newYearWarning ? newYearWarning : ""}
                    />
                  </>
                )}

                {isSaving ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <CircularProgress size={20} color="inherit" />
                    <label className="amplify-label">{"Saving..."}</label>
                  </div>
                ) : (
                  <>
                    {isEdited && (
                      <Button
                        onClick={saveChanges}
                        color="primary"
                        disabled={!!newYearWarning}
                      >
                        Save Changes
                      </Button>
                    )}
                  </>
                )}

                {isEdited && (
                  <Button
                    onClick={handleUndoPlanClick}
                    color="primary"
                    disabled={isSaving}
                  >
                    Undo
                  </Button>
                )}

                {!isEdited && !isNewPlanCreating && (
                  <Button
                    onClick={handleNewPlanClick}
                    color="primary"
                    disabled={!!newYearWarning}
                  >
                    New Plan
                  </Button>
                )}
              </div>

              <ScrollView
                style={{ width: "100%", minHeight: "500px", overflow: "auto" }}
              >
                <div style={{ minWidth: "101%" }}>
                  <Table highlightOnHover={true}>
                    <TableHead>
                      <TableRow>
                        <TableCell as="th">Month</TableCell>
                        <TableCell as="th">Number Contracts</TableCell>
                        <TableCell as="th">Amount Financed</TableCell>
                        <TableCell as="th">Premium</TableCell>
                        <TableCell as="th">Interest</TableCell>
                        <TableCell as="th">Setup Fee</TableCell>
                        <TableCell as="th">Cancel Fee</TableCell>
                        <TableCell as="th">Late Fee</TableCell>
                        <TableCell as="th">NSF Fee</TableCell>
                        <TableCell as="th">VSC Fee</TableCell>
                      </TableRow>
                    </TableHead>
                    {(selectedYear || newFiscalYear !== "") && (
                      <TableBody>
                        {selectedBudgetPlan.months.map((monthlyPlan, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {getMonthForRow(
                                fiscal_year_start_month_day.current,
                                index,
                                selectedYear,
                                false,
                              )}
                            </TableCell>
                            <TableCell>
                              <BudgetItemCell
                                key={
                                  "number_contracts" +
                                  index +
                                  selectedBudgetPlan.fiscal_year
                                }
                                value={monthlyPlan.number_contracts.total}
                                segments={
                                  monthlyPlan.number_contracts.segments
                                    ? monthlyPlan.number_contracts.segments
                                    : []
                                }
                                budgetItemName={"Number Contracts"}
                                dateString={getMonthForRow(
                                  fiscal_year_start_month_day.current,
                                  index,
                                  selectedYear,
                                )}
                                // onChange={(newValue) => handleCellChange(index, 'number_contracts', newValue)}
                                onBlur={(newValue) =>
                                  handleCellChange(
                                    index,
                                    "number_contracts",
                                    newValue,
                                  )
                                }
                                isDollarValue={false}
                              />
                            </TableCell>
                            <TableCell>
                              <BudgetItemCell
                                key={
                                  "amount_financed" +
                                  index +
                                  selectedBudgetPlan.fiscal_year
                                }
                                value={monthlyPlan.amount_financed.total}
                                segments={
                                  monthlyPlan.amount_financed.segments
                                    ? monthlyPlan.amount_financed.segments
                                    : []
                                }
                                budgetItemName={"Amount Financed"}
                                dateString={getMonthForRow(
                                  fiscal_year_start_month_day.current,
                                  index,
                                  selectedYear,
                                )}
                                // onChange={(newValue) => handleCellChange(index, 'amount_financed', newValue)}
                                onBlur={(newValue) =>
                                  handleCellChange(
                                    index,
                                    "amount_financed",
                                    newValue,
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <BudgetItemCell
                                key={
                                  "premium" +
                                  index +
                                  selectedBudgetPlan.fiscal_year
                                }
                                value={monthlyPlan.premium.total}
                                segments={
                                  monthlyPlan.premium.segments
                                    ? monthlyPlan.premium.segments
                                    : []
                                }
                                budgetItemName={"Premium"}
                                dateString={getMonthForRow(
                                  fiscal_year_start_month_day.current,
                                  index,
                                  selectedYear,
                                )}
                                // onChange={(newValue) => handleCellChange(index, 'premium', newValue)}
                                onBlur={(newValue) =>
                                  handleCellChange(index, "premium", newValue)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <BudgetItemCell
                                key={
                                  "interest" +
                                  index +
                                  selectedBudgetPlan.fiscal_year
                                }
                                value={monthlyPlan.interest.total}
                                segments={
                                  monthlyPlan.interest.segments
                                    ? monthlyPlan.interest.segments
                                    : []
                                }
                                budgetItemName={"Interest"}
                                dateString={getMonthForRow(
                                  fiscal_year_start_month_day.current,
                                  index,
                                  selectedYear,
                                )}
                                // onChange={(newValue) => handleCellChange(index, 'interest', newValue)}
                                onBlur={(newValue) =>
                                  handleCellChange(index, "interest", newValue)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <BudgetItemCell
                                key={
                                  "setup_fee" +
                                  index +
                                  selectedBudgetPlan.fiscal_year
                                }
                                value={monthlyPlan.setup_fee.total}
                                segments={
                                  monthlyPlan.setup_fee.segments
                                    ? monthlyPlan.setup_fee.segments
                                    : []
                                }
                                budgetItemName={"Setup Fee"}
                                dateString={getMonthForRow(
                                  fiscal_year_start_month_day.current,
                                  index,
                                  selectedYear,
                                )}
                                // onChange={(newValue) => handleCellChange(index, 'setup_fee', newValue)}
                                onBlur={(newValue) =>
                                  handleCellChange(index, "setup_fee", newValue)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <BudgetItemCell
                                key={
                                  "cancel_fee" +
                                  index +
                                  selectedBudgetPlan.fiscal_year
                                }
                                value={monthlyPlan.cancel_fee.total}
                                segments={
                                  monthlyPlan.cancel_fee.segments
                                    ? monthlyPlan.cancel_fee.segments
                                    : []
                                }
                                budgetItemName={"Cancel Fee"}
                                dateString={getMonthForRow(
                                  fiscal_year_start_month_day.current,
                                  index,
                                  selectedYear,
                                )}
                                // onChange={(newValue) => handleCellChange(index, 'cancel_fee', newValue)}
                                onBlur={(newValue) =>
                                  handleCellChange(
                                    index,
                                    "cancel_fee",
                                    newValue,
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <BudgetItemCell
                                key={
                                  "late_fee" +
                                  index +
                                  selectedBudgetPlan.fiscal_year
                                }
                                value={monthlyPlan.late_fee.total}
                                segments={
                                  monthlyPlan.late_fee.segments
                                    ? monthlyPlan.late_fee.segments
                                    : []
                                }
                                budgetItemName={"Late Fee"}
                                dateString={getMonthForRow(
                                  fiscal_year_start_month_day.current,
                                  index,
                                  selectedYear,
                                )}
                                // onChange={(newValue) => handleCellChange(index, 'late_fee', newValue)}
                                onBlur={(newValue) =>
                                  handleCellChange(index, "late_fee", newValue)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <BudgetItemCell
                                key={
                                  "nsf_fee" +
                                  index +
                                  selectedBudgetPlan.fiscal_year
                                }
                                value={monthlyPlan.nsf_fee.total}
                                segments={
                                  monthlyPlan.nsf_fee.segments
                                    ? monthlyPlan.nsf_fee.segments
                                    : []
                                }
                                budgetItemName={"NSF Fee"}
                                dateString={getMonthForRow(
                                  fiscal_year_start_month_day.current,
                                  index,
                                  selectedYear,
                                )}
                                // onChange={(newValue) => handleCellChange(index, 'nsf_fee', newValue)}
                                onBlur={(newValue) =>
                                  handleCellChange(index, "nsf_fee", newValue)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <BudgetItemCell
                                key={
                                  "vsc_fee" +
                                  index +
                                  selectedBudgetPlan.fiscal_year
                                }
                                value={
                                  monthlyPlan.vsc_fee
                                    ? monthlyPlan.vsc_fee.total
                                    : 0.0
                                }
                                segments={
                                  monthlyPlan.vsc_fee
                                    ? monthlyPlan.vsc_fee.segments
                                      ? monthlyPlan.vsc_fee.segments
                                      : []
                                    : []
                                }
                                budgetItemName={"VSC Fee"}
                                dateString={getMonthForRow(
                                  fiscal_year_start_month_day.current,
                                  index,
                                  selectedYear,
                                )}
                                // onChange={(newValue) => handleCellChange(index, 'nsf_fee', newValue)}
                                onBlur={(newValue) =>
                                  handleCellChange(index, "vsc_fee", newValue)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </div>
              </ScrollView>
            </>
          )}
        </>
      )}
    </>
  );
};

export default BudgetPlanView;
