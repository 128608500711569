import { Dispatch, SetStateAction } from "react";
import {
  ColumnDisplayInfo,
  ColumnMapping,
  messageType,
  QueriedItem,
  AIInsightContentType
} from "./types";

export const addChatMessage = (
  role: string,
  chatResponse: string | React.ReactNode,
  setChatHistoryMessages: Dispatch<SetStateAction<any[]>>,
  query?: string,
  tableData?: string,
  type?: messageType,
) => {

  if (role !== 'system') {
    setChatHistoryMessages((prev) => [
      ...prev,
      { role, content: {chatResponse, query}, tableData: !!tableData ? tableData : {}, type },
    ]);
  }
};

const isLoadingType = (message: { type: string }) =>
  message?.type !== "loading";

export const removeLoadingChatMessage = (
  setChatHistoryMessages: Dispatch<SetStateAction<any[]>>
) => {
  setChatHistoryMessages((prev) => {
    console.log({prev})
    return prev.filter((message) => message?.type !== "loading");
  });
};

export const makeColumnMappings = (queriedItems: QueriedItem[]) => {
  const initialKeys = Object.keys(queriedItems[0]);
  const newColumnMapping: ColumnMapping = {};
  const newColumnDisplayInfo: ColumnDisplayInfo = {};

  initialKeys.forEach((key) => {
    newColumnMapping[key] = key;
    newColumnDisplayInfo[key] = {};
  });

  return { newColumnMapping, newColumnDisplayInfo };
};

export const isAIInsightContentType = (content: any): content is AIInsightContentType => {
  return (content as AIInsightContentType).chatResponse !== undefined;
}
