import ChatClientLogs from "../../components/ChatClient/ChatClientLogs";

const AiInsightLogs = () => {
  return (
    <>
      <ChatClientLogs />
    </>
  );
};

export default AiInsightLogs;