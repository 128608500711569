import React from "react";
import { StyledGrid, StyledFieldWrapper } from "./styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import SearchBar from "../SearchBar/SearchBar";
import { MenuItem, Select } from "@mui/material";
import USStatesDropdown from "../USStatesDropdown";
import SalesExecList from "../SalesExecDropdown/SalesExecDropdown";
import TerritoryList from "../TerritoryDropdown/TerritoryDropdown";
import { Grid } from "@mui/material";

interface DashboardFieldsProps {
  selectedDate: Dayjs;
  selectedState: string;
  setSelectedAgent: string;
  selectedSalesExec: string;
  selectedTerritory: string;
  handleDateChange: () => void;
  handleStateChange: (event: Event) => void;
  handleSalesExecChange: (event: Event) => void;
  handleTerritoryChange: (event: Event) => void;
}

export const DashboardFields = ({
  selectedDate,
  selectedState,
  setSelectedAgent,
  selectedSalesExec,
  selectedTerritory,
  handleDateChange,
  handleStateChange,
  handleSalesExecChange,
  handleTerritoryChange,

}: DashboardFieldsProps) => {
  return (
    <StyledGrid container spacing={3}>
      <Grid item xs={6} sm={3} md={1.5}>
          <DatePicker
            value={selectedDate}
            label="Date"
            onChange={handleDateChange}
            maxDate={dayjs().subtract(1, "day")}
          />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
          <SearchBar
            setSelectedItem={setSelectedAgent}
            selectedItemDefaultVal={"All"}
            resetTrigger={false}
          />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
          <SalesExecList
            onChange={handleSalesExecChange}
            defaultValue={selectedSalesExec}
          />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
          <TerritoryList
            onChange={handleTerritoryChange}
            defaultValue={selectedTerritory}
          />
      </Grid>

      <Grid item xs={6} sm={3} md={1.5}>
          <USStatesDropdown
            onChange={handleStateChange}
            defaultValue={selectedState}
          />
      </Grid>
    </StyledGrid>
  );
};

export default DashboardFields;
