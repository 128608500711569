import { Auth } from "aws-amplify";
import { FormValues } from "./ChangePasswordForm";

export const handleChangePassword = async (
  values: FormValues,
  setSubmitting: (isSubmitting: boolean) => void,
) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, values.oldPassword, values.newPassword);
    setSubmitting(false);
  } catch (error: any) {
    console.log(error);
    setSubmitting(false);
  }
};
