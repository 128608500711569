import React, { useMemo } from "react";
import {
  ContentWrapper,
  StyledCard,
  CardTitle,
  IconWrapper,
  CardStatisticsAmount,
  WrapperRow,
  CardStatisticsBudget,
} from "./styles";
import { DollarString } from "../../utils";
import { Placeholder, Flex } from "@aws-amplify/ui-react";

interface MinistatisticProps {
  title: string;
  isLoading: boolean;
  amount?: number;
  format?: "currency";
  icon: React.ReactNode;
  budget?: number;
}

const MiniStatistic = ({
  title,
  amount,
  format,
  icon,
  budget,
  isLoading,
}: MinistatisticProps) => {
  const formattedAmount = useMemo(() => {
    if (format === "currency") {
      return DollarString(amount);
    }
    return amount;
  }, [amount, format]);

  const formattedBudget = useMemo(() => {
    if (format === "currency" && budget) {
      return DollarString(budget);
    }
    return budget;
  }, [budget, format]);

  const percentage = useMemo(() => {
    if (budget && amount) {
      return Math.round((amount / budget) * 100);
    }
  }, [amount, budget]);

  return (
    <StyledCard placeholder={null} columnSpan={1}>
      <ContentWrapper>
        {isLoading ? (
          <Flex direction="column" minHeight="100px" placeholder={undefined}>
            <Flex direction={'row'} width={"100%"} placeholder={undefined} justifyContent={'space-between'}>
              <Flex direction="column" width={"100%"} placeholder={undefined}>
                <Placeholder size="small" placeholder={undefined} />
                <Placeholder size="small" placeholder={undefined} />
              </Flex>
              <IconWrapper>{icon}</IconWrapper>
            </Flex>
            <Placeholder size="small" placeholder={undefined} />
            <Placeholder size="small" placeholder={undefined} />
          </Flex>
        ) : (
          <WrapperRow>
            <div>
              <CardTitle>{title}</CardTitle>
              <CardStatisticsAmount>
                <span>{formattedAmount}</span>{" "}
              </CardStatisticsAmount>
              {!!formattedBudget && (
                <CardStatisticsBudget>
                  {" "}
                  {formattedBudget}
                  {"  "}({percentage}%){" "}
                </CardStatisticsBudget>
              )}
            </div>
            <IconWrapper>{icon}</IconWrapper>
          </WrapperRow>
        )}
      </ContentWrapper>
    </StyledCard>
  );
};

export default MiniStatistic;
