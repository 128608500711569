import { API } from "aws-amplify";
import { getApiRequestInfo } from "../../utils";

interface fetchFiscalYearBudgetArgs {
  fiscalYear: string;
  solicitorCode?: string;
  agentCode?: string;
  state?: string;
}

export async function fetchFiscalYearBudget({
  fiscalYear,
  solicitorCode,
  agentCode,
  state,
}: fetchFiscalYearBudgetArgs): Promise<any> {
  const requestInfo = await getApiRequestInfo(null);

  // now get the budget for the fiscal year
  let url = "/annualplan/" + fiscalYear;

  try {
    let budgetData = await API.get(
      "idpro",
      url,
      requestInfo,
    );
    // now add GrossPremium to the budget data by summing up interest, cancel_fee, late_fee, nsf_fee, setup_fee and vsc_fee
    var updatedData = addGrossRevenue(budgetData);
    return(updatedData);
  } catch (error) {
    console.error("error: " + JSON.stringify(error));
    throw new Error("Failed to fetch fiscal year budget data");
  }

  return null;
}

interface Segment {
  name: string;
  value: number;
}

interface FinancialMetric {
  total: number;
  segments: Segment[];
}

interface MonthData {
  interest: FinancialMetric;
  late_fee: FinancialMetric;
  cancel_fee: FinancialMetric;
  nsf_fee: FinancialMetric;
  setup_fee: FinancialMetric;
  vsc_fee: FinancialMetric;
  gross_revenue?: FinancialMetric;
}

interface Data {
  months: MonthData[];
}

function addGrossRevenue(data: Data): Data {
  data.months.forEach(month => {
      // Initialize gross revenue object
      month.gross_revenue = {
          total: 0,
          segments: []
      };

      // Names of elements to sum up for gross revenue
      const elementsToSum: (keyof MonthData)[] = ['interest', 'late_fee', 'cancel_fee', 'nsf_fee', 'setup_fee', 'vsc_fee'];

      // Prepare segments map to accumulate values
      const segmentSums: Record<string, number> = {};

      // Calculate sums for each required element
      elementsToSum.forEach(element => {
        if(month[element]) {
          month[element]!.segments.forEach(segment => {
              if (!segmentSums[segment.name]) {
                  segmentSums[segment.name] = 0;
              }
              segmentSums[segment.name] += segment.value;
          });
          month.gross_revenue!.total += month[element]!.total;
        }
      });

      // Convert the sums map to the segments array
      for (const [name, value] of Object.entries(segmentSums)) {
          month.gross_revenue.segments.push({ name, value });
      }
  });

  //console.log("data: ", data);
  return data;
}
