import React, { useState, useEffect } from 'react';
import { TextField, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const SegmentRow = ({ name, onDelete, onUpdate, fiscal_year }) => {
  const [isEditing, setIsEditing] = useState(name === '' ? true : false);
  const [value, setValue] = useState(name);
	const [textFieldWarning, setTextFieldWarning] = useState('');

	useEffect(() => {
		if (value === '') {
			setTextFieldWarning('must not be empty!');
		} else {
			setTextFieldWarning('');
		}
	}, [value])

  const handleDelete = () => {
    onDelete();
  };

  const toggleEdit = () => {
    if (isEditing) {
      onUpdate(value);
    }
    setIsEditing(!isEditing);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '5px'}}>
      <IconButton onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
      <TextField
        variant="outlined"
				// label={'Define Name'}
        value={value}
        onChange={handleChange}
        disabled={!isEditing}
        onKeyPress={(event) => event.key === 'Enter' && toggleEdit()}
				color={!!textFieldWarning ? 'warning' : 'primary'}
				helperText={textFieldWarning ? textFieldWarning : ""}
      />
      {!isEditing && (
        <IconButton onClick={toggleEdit}>
          <EditIcon />
        </IconButton>
      )}
    </div>
  );
}

export default SegmentRow;
