import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import { DashboardWrapper, StyledHeading, ChartsWrapper } from "../styles";
import ReportFields from "../../../components/ReportFields/ReportFields";
import dayjs from "dayjs";
import { Card, useTheme, View } from "@aws-amplify/ui-react";

import WriteoffReport from "../../../components/WriteoffReport/WriteoffReport";
 

export function Writeoffs() {
  const endDate = dayjs().subtract(1, "day"); //  : dayjs(new Date(2023,8,20));
  // set start date to the first date of this month
  const startDate = dayjs().startOf("month");
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);
  const [selectedAgent, setSelectedAgent] = useState("All");
  const [selectedSalesExec, setSelectedSalesExec] = useState("All");
  const [selectedTerritory, setSelectedTerritory] = useState("All");
  const [selectedState, setSelectedState] = useState("All");


  const handleStartDateChange = (date: dayjs.Dayjs) => {
    setSelectedStartDate(date);
    if (date) {
        // if date.endOf("month") is greater than yesterday, then use yesterday
      if (date.endOf("month").isAfter(dayjs().subtract(1, "day"))) {
        setSelectedEndDate(dayjs().subtract(1, "day"));
      } else {
        setSelectedEndDate(date.endOf("month"));
      }
    } else {
      setSelectedEndDate(date);
    }
  };
  

  const handleEndDateChange = (date: React.SetStateAction<dayjs.Dayjs>) =>
    setSelectedEndDate(date);

  const selectedAgentChanged = (agent: string) => {
    setSelectedAgent(agent);
  };

  const handleSalesExecChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedSalesExec(event.target.value);
  };

  const handleTerritoryChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedTerritory(event.target.value);
  };


  const handleStateChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedState(event.target.value);
  };

  return (
    <>
      <DashboardWrapper>
        <StyledHeading level={4} placeholder={undefined}>
          {"Writeoff Report"}
        </StyledHeading>

        <ReportFields
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          handleStartDateChange={handleStartDateChange as any}
          handleEndDateChange={handleEndDateChange as any}
          selectedAgent={selectedAgent}
          // @ts-ignore
          setSelectedAgent={selectedAgentChanged}
          selectedSalesExec={selectedSalesExec}
          selectedTerritory={selectedTerritory}
          // @ts-ignore
          handleSalesExecChange={handleSalesExecChange}
          handleTerritoryChange={handleTerritoryChange}
          selectedState={selectedState}
          handleStateChange={handleStateChange}
        />
      </DashboardWrapper>

      <View borderRadius="6px" maxWidth="100%" padding="0rem" minHeight="100vh" height="100%">

              <View
                columnSpan={[3, 3, 3, 3]}
                rowSpan={{ base: 5, large: 5 }}
                height="100%"
              >
                      <WriteoffReport
                        startDate={selectedStartDate.toDate()}
                        endDate={selectedEndDate.toDate()}
                        agentCode={selectedAgent}
                        solicitorCode={selectedSalesExec}
                        territoryCode={selectedTerritory}
                        state={selectedState}
                      />
              </View>
        
      </View>
    </>
  );
}
