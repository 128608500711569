import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { StyledGrid, StyledFieldWrapper } from "./styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import SearchBar from "../SearchBar/SearchBar";
import { Grid } from "@mui/material";
import SalesExecList from "../SalesExecDropdown/SalesExecDropdown";
import TerritoryList from "../TerritoryDropdown/TerritoryDropdown";
import USStatesDropdown from "../USStatesDropdown";

interface IncomeDashboardFieldsProps {
  selectedDate: Dayjs;
  handleDateChange: () => void;
  setSelectedAgent: Dispatch<SetStateAction<string>>;
  selectedSalesExec: string;
  handleSalesExecChange: (event: { target: { value: string } }) => void;
  selectedTerritory: string;
  handleTerritoryChange: (event: { target: { value: string } }) => void;
  selectedAgent: string;
  selectedState: string;
  handleStateChange: (event: { target: { value: string } }) => void;
}

export const IncomeDashboardFields = ({
  selectedDate,
  handleDateChange,
  setSelectedAgent,
  handleSalesExecChange,
  handleTerritoryChange,
  selectedSalesExec,
  selectedTerritory,
  selectedAgent,
  handleStateChange,
  selectedState,
}: IncomeDashboardFieldsProps) => {
  const [resetTrigger, setResetTrigger] = useState(false);

  const resetSearchBar = () => {
    setResetTrigger((prevState) => !prevState);
  };

  useEffect(() => {
    if (
      ((selectedSalesExec !== "All" &&
      selectedSalesExec !== "") || (selectedTerritory !== "All" && selectedTerritory !== "") ) &&
      selectedAgent !== "All" &&
      selectedAgent !== ""
    ) {
      selectedAgentChanged("All");
      resetSearchBar();
    }
  }, [selectedSalesExec, selectedTerritory]);

  useEffect(() => {
    if (
      selectedAgent !== "All" &&
      selectedAgent !== "" &&
      selectedSalesExec !== "All" &&
      selectedSalesExec !== ""
    )
      handleSalesExecChange({ target: { value: "All" } });
      handleTerritoryChange({ target: { value: "All" } });
  }, [selectedAgent]);

  const selectedAgentChanged = (agent: string) => {
    setSelectedAgent(agent);
  };

  return (
    <StyledGrid container spacing={3}>
      <Grid item xs={6} sm={3} md={1.5}>
          <DatePicker
            label="Date"
            minDate={dayjs("2021").startOf("year")}
            value={selectedDate}
            onChange={handleDateChange}
            maxDate={dayjs().subtract(1, "day")}
            views={["year"]}
          />
      </Grid>

      {/* removing this field for now as agentCodes are not being returned from the backend */}
      <Grid item xs={12} sm={6} md={3}>
        <SearchBar
          setSelectedItem={selectedAgentChanged}
          selectedItemDefaultVal={"All"}
          resetTrigger={resetTrigger}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <SalesExecList
          onChange={handleSalesExecChange}
          defaultValue={selectedSalesExec}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TerritoryList
          onChange={handleTerritoryChange}
          defaultValue={selectedTerritory}
        />
      </Grid>
      <Grid item xs={6} sm={3} md={1.5}>
        <USStatesDropdown
          onChange={handleStateChange}
          defaultValue={selectedState}
        />
      </Grid>
    </StyledGrid>
  );
};

export default IncomeDashboardFields;
