import React from "react";
import {
  StyledCard,
  StyledHeading,
  StyledRowHeader,
  StyledBudgetSpan,
  StyledMoneyCell,
} from "./styles";
import {
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@aws-amplify/ui-react";
import { DollarValue } from "../../utils";
import { capitalize } from "@mui/material";
import { valuesFormat } from "./utils";
import { useFormikContext } from "formik";

interface SegmentTableProps {
  budgetItem: string;
  segment: string;
  monthlyValues: {
    differenceValue: number;
    month: number;
    totalBudget: number;
    budgetSegmentName: string;
    year: number;
    actualValue: number | string;
    budgetSegmentValue: number;
  }[];
}

const SegmentTable = ({ segment, monthlyValues }: SegmentTableProps) => {
  const { values } = useFormikContext<{
    isFinancial: boolean;
  }>();

  const sumRowValues = (key: keyof typeof monthlyValues[0]) => {
    return monthlyValues.reduce((acc, monthValue) => {
      const value = parseFloat(monthValue[key] as string);
      return acc + (isNaN(value) ? 0 : value);
    }, 0);
  };

  const actualSum = sumRowValues("actualValue");
  const budgetSum = sumRowValues("budgetSegmentValue");
  const differenceSum = sumRowValues("differenceValue");

  return (
    <StyledCard placeholder={null} columnSpan={1}>
      <StyledHeading placeholder={undefined}>
        {capitalize(segment)}
      </StyledHeading>

      <Table
        caption=""
        highlightOnHover={true}
        placeholder={undefined}
        size={"small"}
        marginTop={"2rem"}
      >
        <TableHead placeholder={undefined}>
          <TableRow placeholder={undefined}>
            <TableCell placeholder={undefined} as="th"></TableCell>
            {monthlyValues.map((monthValue) => {
              return (
                <StyledRowHeader placeholder={undefined} as="th" key={monthValue.month + monthValue.year}>
                  {`${monthValue.month} / ${monthValue.year}`}
                </StyledRowHeader>
              );
            })}
            <StyledRowHeader placeholder={undefined} as="th">
              Total
            </StyledRowHeader>
          </TableRow>
        </TableHead>
        <TableBody placeholder={undefined}>
          <TableRow placeholder={undefined}>
            <TableCell placeholder={undefined}>Actual</TableCell>
            {monthlyValues.map((monthValue) => {
              return (
                <StyledMoneyCell placeholder={undefined} key={monthValue.month + monthValue.year}>
                  {monthValue.actualValue
                    ? `${valuesFormat(monthValue.actualValue, values.isFinancial)}`
                    : "-"}
                </StyledMoneyCell>
              );
            })}
            <StyledMoneyCell placeholder={undefined}>
              {valuesFormat(actualSum, values.isFinancial)}
            </StyledMoneyCell>
          </TableRow>

          <TableRow placeholder={undefined}>
            <TableCell placeholder={undefined}>Budget</TableCell>
            {monthlyValues.map((monthValue) => {
              return (
                <StyledMoneyCell placeholder={undefined} key={monthValue.month + monthValue.year}>
                  {monthValue.budgetSegmentValue
                    ? `${valuesFormat(monthValue.budgetSegmentValue, values.isFinancial)}`
                    : "-"}
                </StyledMoneyCell>
              );
            })}
            <StyledMoneyCell placeholder={undefined}>
              {valuesFormat(budgetSum, values.isFinancial)}
            </StyledMoneyCell>
          </TableRow>

          <TableRow placeholder={undefined}>
            <TableCell placeholder={undefined}>Difference</TableCell>
            {monthlyValues.map((monthValue) => {
              return (
                <StyledMoneyCell placeholder={undefined} key={monthValue.month + monthValue.year}>
                  {monthValue.actualValue && monthValue.budgetSegmentValue ? (
                    values.isFinancial ? (
                      <StyledBudgetSpan>
                        <DollarValue
                          value={monthValue.differenceValue}
                          color={
                            monthValue.differenceValue < 0 ? "red" : "black"
                          }
                        />
                      </StyledBudgetSpan>
                    ) : (
                      <StyledBudgetSpan
                        color={monthValue.differenceValue < 0 ? "red" : "black"}
                      >
                        {valuesFormat(
                          monthValue.differenceValue,
                          values.isFinancial,
                        )}
                      </StyledBudgetSpan>
                    )
                  ) : (
                    "-"
                  )}
                </StyledMoneyCell>
              );
            })}
            <StyledMoneyCell placeholder={undefined} color={differenceSum < 0 ? "red" : "black"}>
              {valuesFormat(differenceSum, values.isFinancial)}
            </StyledMoneyCell>
          </TableRow>
        </TableBody>
      </Table>
    </StyledCard>
  );
};

export default SegmentTable;
