  import React, { useEffect, useState } from 'react';
  import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
  import { API } from "aws-amplify";
  import { getApiRequestInfo } from '../../api/utils';

  const SalesExecDropdown = ({ onChange, defaultValue }) => {
    const [solicitors, setSolicitors] = useState([]);
    const [selectValue, setSelectValue] = useState(defaultValue);

    useEffect(() => {
      selectValue && setSelectValue(defaultValue);
    }, [defaultValue])


    useEffect(() => {
      const fetchData = async () => {
        try {
          const requestInfo = await getApiRequestInfo(null);
          const response = await API.get('idpro', '/solicitorlist', requestInfo);
          setSolicitors(response);
        } catch (error) {
          console.error("Error getting sales execs: " + error);
        }
      };

      fetchData();
    }, []);

    return (
      <FormControl fullWidth>
        <InputLabel shrink>Sales Exec</InputLabel>
          <Select onChange={onChange} value={selectValue} label="Sales Exec" className="us-states-dropdown">
            <MenuItem value="All">All Sales Execs</MenuItem>
            {solicitors.map((solicitor, index) => (
              <MenuItem key={index} value={solicitor.id}>
                {solicitor.name}
              </MenuItem>
            ))}
          </Select>
      </FormControl>
    );
  }

  export default SalesExecDropdown;
