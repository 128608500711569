import React, { useState, useEffect, useRef } from "react"; // Import useEffect and useRef
import { Select, MenuItem, CircularProgress, SelectChangeEvent } from "@mui/material";
import {ChatHistoryMessageType, ChatHistoryType } from "./types";
import { fetchGptChatResponseNew } from "../../api/utils/fetchGptChatResponseNew/fetchGptChatResponseNew";
import { fetchGptChatHistories } from "../../api/utils/fetchGptChatHistories/fetchGptChatHistories";
import { putGptChatHistory } from "../../api/utils/putGptChatHistory/putGptChatHistory";
import { addChatMessage, removeLoadingChatMessage } from "./utils";
import ChatInput from "./ChatInput/ChatInput";
import {
  ChatClientContainer,
  StyledTopBar,
  StyledHeading,
  ChatScrollContainer,
  StyledLinearProgress,
} from "./styles";
import { ChatHistoryMessage } from "./ChatMessage/ChatHistoryMessage";
import { WelcomeMessage } from "./WelcomeMessage/WelcomeMessage";

type SelectValueType = "" | { value: unknown } | undefined;

const ChatClientLogs: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chatHistoryUIMessages, setChatHistoryUIMessages] = useState<ChatHistoryMessageType[]>([]);

  const [chatHistoryLogs, setChatHistoryLogs] = useState<ChatHistoryType[]>([]);
  const [selectedChatHistoryTitle, setSelectedChatHistoryTitle] = useState<SelectValueType>("");
  const [selectedChatHistoryId, setSelectedChatHistoryId] = useState<SelectValueType>("");
  const [isChatHistoryEdited, setIsChatHistoryEdited] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const conversationTitle = useRef<string>('Title');

  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollDown = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const triggerChatError = (errorMessage?: string) => {
    addChatMessage(
      "assistant",
      errorMessage || "Error: please try again or edit your query",
      setChatHistoryUIMessages,
      undefined,
      undefined,
      "gptResponseError",
    );
  };

  const handleSubmit = async (userMessage: string) => {
    setIsLoading(true);

    addChatMessage(
      "user",
      userMessage,
      setChatHistoryUIMessages,
      undefined,
      undefined,
      "userMessage",
    );

    try {
      let requestIsRunning = true;
      let isConsecutiveRequest = false;
      let loopCounter = 0;
      while (requestIsRunning && loopCounter < 2) {
        const response = await fetchGptChatResponseNew({ userMessage, isConsecutiveRequest, logHistory: true, chatHistoryId: selectedChatHistoryId as string});
        const { chatHistoryId, chatHistoryTitle, query, chatResponse, newBackendMessages, needConsecutiveRequest, hadFailure } = response;
        setSelectedChatHistoryId(selectedChatHistoryId);

        conversationTitle.current = chatHistoryTitle;
        
        addChatMessage(
          "assistant",
          chatResponse,
          setChatHistoryUIMessages,
          query,
          response?.queryResults,
          "gptResponse",
        );

        if (needConsecutiveRequest) {
          let inBetweenResponse = hadFailure ? "Something went wrong! Let me try again" : "Let me fetch the Database Results...";
          addChatMessage(
            "assistant",
            inBetweenResponse,
            setChatHistoryUIMessages,
            "",
            undefined,
            "gptResponse",
          );
        } else {
          requestIsRunning = false;
        }

        isConsecutiveRequest = needConsecutiveRequest as boolean;
        loopCounter++;
      }
      
      
    } catch (error) {
      // Handle error
      triggerChatError();
    } finally {
      setIsLoading(false);
    }
  };

  const handleChatHistoryChange = (event: SelectChangeEvent<{ value: unknown }>) => {
    const title = event.target.value as SelectValueType;
    setSelectedChatHistoryTitle(title);

    const selectedChatHistory = chatHistoryLogs.find(log => log.title === title);
    if (selectedChatHistory) {
      console.log('selectedChatHistory: \n', selectedChatHistory);
      setChatHistoryUIMessages(selectedChatHistory.messages);
      setSelectedChatHistoryId(selectedChatHistory.historyId as SelectValueType);
    }
  };

  const handleChatItemUpdate = (index: number, updatedItem: ChatHistoryMessageType) => {
    console.log('updatedItem', updatedItem);
    // Clone the current chatHistory array
    const updatedChatHistory = [...chatHistoryUIMessages];
  
    // Update the item at the specific index
    updatedChatHistory[index] = updatedItem;
  
    // Update state with the modified array
    setChatHistoryUIMessages(updatedChatHistory);
    setIsChatHistoryEdited(true);
  };

  const handleSaveChatHistory = async () => {
    setIsSaving(true);
  
    //TODO:
    const extendedBackendChatHistory = {
      title: selectedChatHistoryTitle as string,
      historyId: selectedChatHistoryId as string,
      messages: chatHistoryUIMessages,
    };
  
    try {
      await putGptChatHistory({ extendedBackendChatHistory });
      setIsChatHistoryEdited(false);
    } catch (error) {
      // TODO: Handle error response
      console.error("Error saving chat history:", error);
    } finally {
      setIsSaving(false);
    }
  };  
  
  useEffect(() => {
    const fetchChatHistoryMock = () => {
      setIsLoading(true);
      const response = [
        {
          title: "Chat History1",
          historyId: "12434-24234-5235-32525",
          messages: [
            {
              role: "user",
              content: {
                chatResponse: "Hello"
              },
              comment: "",
            },
            {
              role: "assistant",
              content: {
                chatResponse: "Hello back",
                query: "SELECT * FROM agents",
              },
              comment: "Hello",
              approved: true,
            },
          ],
        }
      ];
      setChatHistoryLogs(response);
      setIsLoading(false);
    };

    const fetchChatHistories = async () => {
      setIsLoading(true);
      try {
        const response = await fetchGptChatHistories();
        console.log('fetchGptChatHistories response:\n', response);
        setChatHistoryLogs(response.chatHistories);
      } catch (error) {
        // Handle error
        triggerChatError();
      } finally {
        setIsLoading(false);
      }
    };

    if (chatHistoryLogs.length === 0) {
      fetchChatHistories();
      // fetchChatHistoryMock();
    }
    scrollDown();
  }, []);

  useEffect(() => {
    if (isLoading) {
      addChatMessage(
        "assistant",
        "loading",
        setChatHistoryUIMessages,
        "",
        undefined,
        "loading",
      );
    }
    if (!isLoading) {
      removeLoadingChatMessage(setChatHistoryUIMessages);
    }
    scrollDown();
  }, [isLoading]);

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Select
            value={selectedChatHistoryTitle}
            onChange={handleChatHistoryChange}
            displayEmpty
          >
            {chatHistoryLogs.map((chatHistoryLog, index) => (
              <MenuItem key={index} value={chatHistoryLog.title}>
                {chatHistoryLog.title}
              </MenuItem>
            ))}
          </Select>
          {isChatHistoryEdited && (
            <button onClick={handleSaveChatHistory} disabled={isSaving}>
              {isSaving ? <CircularProgress size={24} /> : "Save"}
            </button>
          )}
        </>
      )}

      <StyledTopBar>
        <StyledHeading level={4} placeholder={null}>
        
        </StyledHeading>
      </StyledTopBar>

      <ChatClientContainer>
        <ChatScrollContainer placeholder={null} ref={scrollRef}>
          {chatHistoryUIMessages.length === 0 && <WelcomeMessage />}
          {chatHistoryUIMessages.map((chat, index) => (
            <ChatHistoryMessage
              key={index}
              conversationTitle={conversationTitle.current}
              chatItem={chat}
              onUpdate={(updatedItem) => handleChatItemUpdate(index, updatedItem)}
            />
          ))}

        </ChatScrollContainer>
        {isLoading && <StyledLinearProgress variant={"indeterminate"} />}
        <ChatInput
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </ChatClientContainer>
    </div>
  );
};

export default ChatClientLogs;
