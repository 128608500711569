import React, {useEffect} from 'react';

const MFASetupSuccessView = ({ handleClosingMFASetup }) => {

  return (
    <div className="amplify-flex" style={{ flexDirection: 'column', gap: '1rem' }}>
      <h3 className="amplify-heading amplify-heading--3">Thank You!</h3>
      <label className="amplify-label">
        Your chosen MFA method will be used for future logins
      </label>
      <button
        className="amplify-button amplify-button--primary"
        onClick={handleClosingMFASetup}
      >
        Continue to PFC Insight
      </button>
    </div>
  );
};

export default MFASetupSuccessView;
