import React from "react";
import { Icon } from "@aws-amplify/ui-react";

import {
  MdDashboard,
  MdModeEditOutline,
  MdAccountBox,
  MdOutlineTableChart,
  MdLightbulbOutline,
} from "react-icons/md";

export const baseConfig = {
  titleSuffix: "",
  search: false,
  header: true,
  headerText: "PFC Insight",
  footer: true,
  footerText: (
    <>
      <span>
        © 2iG Solutions {new Date().getFullYear()} | All Rights Reserved
      </span>
    </>
  ),

  logo: (
    <>
      <img
        src={process.env.PUBLIC_URL + "/2iG_small.png"}
        alt="logo"
        width="100%"
      />
    </>
  ),
};

// @@TODO convert this toa  function to make the segment dashboard dynamic based on api data arg ({ shouldShowSegmentDashboard: boolean; })
/// Navigation sidebar
export function getNavigation(clientType) {
  console.log("get navigation for client type: ", clientType);
  if (clientType.toLowerCase() === "mga") {
    return mgaAppNavs;
  } else if (clientType.toLowerCase() === "pfc") {
    return pfcAppNavs;
  } else {
    return null;
  }
}
export const pfcAppNavs = [
  {
    eventKey: "dashboard",
    icon: <Icon as={MdDashboard} />,
    title: "Dashboard",
    to: "/dashboard",
    children: [
      {
        eventKey: "income-dashboard",
        title: "Income",
        to: "/incomedashboard",
      },
      {
        eventKey: "segment-dashboard",
        title: "Actual v Plan By Segment",
        to: "/segmentdashboard",
      },
      {
        eventKey: "agent-dashboard",
        title: "Agents",
        to: "/agentdashboard",
      },
      /**
      {
        eventKey: "sales-dashboard",
        title: "Sales",
        to: "/salesdashboard",
      },
      ***/


      {
        eventKey: "agent-maps",
        title: "Agent Maps",
        to: "/agentmaps",
      },
    ],
  },
  {
    eventKey: "ai-insight",
    icon: <Icon as={MdLightbulbOutline} />,
    title: "AI Insight",
    to: "/aiinsight",
  },
  {
    eventKey: "tables",
    icon: <Icon as={MdOutlineTableChart} />,
    title: "Reports",
    to: "/",
    children: [
      /****  not needed since Actual v Plan by Segment should fulfill all requirements 
      {
        eventKey: "actual-plan",
        title: "Actual v. Plan",
        to: "/reports/actualvplan",
      },
      ********/
      {
        eventKey: "equity",
        title: "Equity",
        to: "/reports/equity",
      },
      /***
      {
        eventKey: "production",
        title: "Production",
        to: "/reports/production",
      },
      ****/
      {
        eventKey: "concentration",
        title: "Concentration",
        to: "/reports/concentration",
      },

      {
        eventKey: "revenuedetails",
        title: "Revenue Details",
        to: "/reports/revenuedetails",
      },
      {
        eventKey: "premiumdetails",
        title: "Premium Details",
        to: "/reports/premiumdetails",
      },
      {
        eventKey: "writeoffs",
        title: "Write-offs",
        to: "/reports/writeoffs",
      },
      {
        eventKey: "paidfees",
        title: "Paid Fees",
        to: "/reports/paidfees",
      }
    ],
  },
  {
    eventKey: "profile",
    icon: <Icon as={MdAccountBox} />,
    title: "Profile",
    to: "/profile",
  },
];


export const mgaAppNavs = [
  {
    eventKey: "dashboard",
    icon: <Icon as={MdDashboard} />,
    title: "Program Dashboard",
    to: "/mgadashboard",
    children: [
      {
        eventKey: "income-dashboard",
        title: "Income",
        to: "/incomedashboard",
      },

      {
        eventKey: "agent-dashboard",
        title: "Agents",
        to: "/agentdashboard",
      },

      {
        eventKey: "agent-maps",
        title: "Agent Maps",
        to: "/agentmaps",
      },
    ],
  },
  {
    eventKey: "ai-insight",
    icon: <Icon as={MdLightbulbOutline} />,
    title: "AI Insight",
    to: "/aiinsight",
  },
  {
    eventKey: "tables",
    icon: <Icon as={MdOutlineTableChart} />,
    title: "Reports",
    to: "/",
    children: [
      {
        eventKey: "production",
        title: "Production",
        to: "/reports/production",
      },
      {
        eventKey: "concentration",
        title: "Concentration",
        to: "/reports/concentration",
      },

      {
        eventKey: "revenuedetails",
        title: "Revenue Details",
        to: "/reports/revenuedetails",
      },
      {
        eventKey: "premiumdetails",
        title: "Premium Details",
        to: "/reports/premiumdetails",
      },
    ],
  },
  {
    eventKey: "profile",
    icon: <Icon as={MdAccountBox} />,
    title: "Profile",
    to: "/profile",
  },
];
