import React from "react";

import { WelcomeMessageWrapper } from "./styles";
import Logo from "../../Logo";

export const WelcomeMessage = () => {
  return (
    <WelcomeMessageWrapper>
      <Logo />
      <h1>How can I help you?</h1>
    </WelcomeMessageWrapper>
  );
};
