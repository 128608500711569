import React from "react";
import ChatClient from "../../components/ChatClient/ChatClient";

const AiInsight = () => {
  return (
    <>
      <ChatClient />
    </>
  );
};

export default AiInsight;
