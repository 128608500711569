import { capitalize } from "@mui/material";

export interface DataItem {
  segment: string;
  months: Array<{
    year: number;
    month: number;
    values: {
      [key: string]: number | string;
    };
  }>;
}

export function getUniqueSegmentTypes(data: DataItem[]): string[] {
  const segmentSet = new Set<string>();
  data.forEach((item) => segmentSet.add(item.segment));
  return Array.from(segmentSet);
}

export const getUniqueValueKeys = (data: DataItem[]): string[] => {
  const uniqueKeys = new Set<string>();

  data.forEach((item) => {
    item.months.forEach((month) => {
      Object.keys(month.values).forEach((key) => {
        uniqueKeys.add(key);
      });
    });
  });

  return Array.from(uniqueKeys);
};

type FinancialMetric = {
  total: number;
  segments?: Array<{
    name: string;
    value: number;
  }>;
};

type MonthData = {
  [key: string]: FinancialMetric;
};

export type BudgetDataPayload = {
  fiscal_year: number;
  months: MonthData[];
  clientid: string;
};

export interface ProcessedTableData {
  budgetItem: string;
  segment: string;
  monthlyValues: {
    differenceValue: number;
    month: number;
    totalBudget: number;
    budgetSegmentName: string;
    year: number;
    actualValue: number | string;
    budgetSegmentValue: number;
  }[];
}

interface MonthStructure {
  year: number;
  month: number;
  values: {
    amount_financed: number;
    cancel_fee: number;
    interest: number;
    late_fee: number;
    nsf_fee: number;
    loan_count: string;
    premium: number;
    setup_fee?: number;
  };
}

function fillInMissingMonths(data: MonthStructure[]): MonthStructure[] {
  // The total number of months we need to fill in to make the array have 12 items.
  const totalMonthsNeeded = 12;

  // Check if the data already has 12 or more items
  if (data.length >= totalMonthsNeeded) {
    return data; // If it does, we don't need to add any more months.
  }

  // Find the last month in the data to know where to start.
  const lastMonthData = data[data.length - 1];
  let currentYear = lastMonthData.year;
  let currentMonth = lastMonthData.month;

  // Calculate how many months are missing.
  const monthsToAdd = totalMonthsNeeded - data.length;

  // Template for the values of the new months, assuming we want to add blank values.
  const blankValues = {
    amount_financed: 0,
    cancel_fee: 0,
    interest: 0,
    late_fee: 0,
    nsf_fee: 0,
    loan_count: "0",
    premium: 0,
  };

  for (let i = 1; i <= monthsToAdd; i++) {
    // Increment the month, wrapping around to January and incrementing the year if necessary.
    // @ts-ignore
    currentMonth++;
    // @ts-ignore
    if (currentMonth > 12) {
      // @ts-ignore
      currentMonth = 1; // Reset to January
      // @ts-ignore
      currentYear++; // Move to the next year
    }

    // Push the new month data into the array.
    data.push({
      year: currentYear,
      month: currentMonth,
      // @ts-ignore
      values: { ...blankValues }, // Use a spread to clone the blank values
    });
  }

  return data;
}

const getBudgetDataForSegment = (
  segmentName: string,
  budgetData: BudgetDataPayload,
  budgetItem: string,
) => {
  return budgetData.months.map((month) => {
    return {
      totalBudget: month[budgetItem]?.total,
      budgetSegmentName: segmentName,
      // @ts-ignore
      budgetSegmentValue: month[budgetItem]?.segments?.find(
        (item) => item.name.toUpperCase() === segmentName.toUpperCase(),
      )?.value,
    };
  });
};
export function extractMonthlyValuesAndMakeTableData(
  data: DataItem[],
  budgetItem: string,
  budgetData: BudgetDataPayload,
  segments: string[],
): ProcessedTableData[] {
  if (!budgetData) return [];

  const budgetDataProcessed = segments.map((segment) => {
    return {
      segment,
      data: getBudgetDataForSegment(segment, budgetData, budgetItem),
    };
  });

 // console.log("in extractMonthlyValuesAndMakeTableData:", data);

  // @ts-ignore
  return data.map((segmentData, i) => {
    // This is inconvenient but we have to fill in the extra months missing from the API payload to map against the budget, so we can show the difference.
    // @ts-ignore
    segmentData.months = fillInMissingMonths(
      segmentData.months as unknown as MonthStructure[],
    );

    const monthlyValues = segmentData.months.map((month, x) => {
      const foundBudgetKey = budgetDataProcessed.find(
        (item) => item.segment === segmentData.segment,
      );

      return {
        year: month.year,
        month: month.month,
        actualValue: month.values[budgetItem],
        ...foundBudgetKey?.data[x],
        differenceValue:
          // @ts-ignore
          month.values[budgetItem] -
          // @ts-ignore
          foundBudgetKey?.data[x]?.budgetSegmentValue,
        // budgetSegmentValue: budgetDataProcessed[x]?.budgetSegmentValue,
      };
    });

    return {
      budgetItem,
      segment: segmentData.segment,
      monthlyValues,
    };
  });
}

export function getFiscalYearMonths(fiscalStartDateStr: string): string[] {
  const months: string[] = [];

  // Parse the input string into a Date object
  const year = parseInt(fiscalStartDateStr.substring(0, 4), 10);
  const month = parseInt(fiscalStartDateStr.substring(4, 6), 10) - 1; // Months are 0-indexed in JavaScript Date
  const day = parseInt(fiscalStartDateStr.substring(6, 8), 10);
  const fiscalStartDate = new Date(year, month, day);

  if (isNaN(fiscalStartDate.getTime())) {
    throw new Error("Invalid fiscal start date");
  }

  for (let i = 0; i < 12; i++) {
    // Clone the startDate and adjust for each month
    const workingDate = new Date(
      fiscalStartDate.getFullYear(),
      fiscalStartDate.getMonth() + i,
      1,
    );
    const workingYear = workingDate.getFullYear();
    const workingMonth = (workingDate.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const strMonthYear = `${workingYear}-${workingMonth}`;
    months.push(strMonthYear);
  }

  return months;
}
