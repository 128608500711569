import React, { useState } from "react";
import { selectDataArray } from "./utils";

import { AgentRankingProps } from "./types";
import TableLoadingSkeleton from "./table/TableLoadingSkeleton";
import TableDisplay from "./table/TableDisplay";
import { useQuery } from "react-query";
import { fetchRankingData } from "../../api/utils/fetchRankingData/fetchRankingData";
import TableCard from "../DashboardCard/TableCard";

const AgentRanking: React.FC<AgentRankingProps> = ({
  selectedDate,
  startDate,
  topN = 10,
  timeframe = "day",
  type,
  title,
  headerBgColor = "#FFF",
  bHideAgentName = false,
  excelExport = true,
}) => {
  const [hideAgentName, setHideAgentName] = useState<boolean>(bHideAgentName);
  const {
    data: apiData,
    isLoading,
    isRefetching,
    isFetching,
  } = useQuery(
    [`${type}-${timeframe}`, startDate],
    () => fetchRankingData({ startDate }),
    {
      refetchOnWindowFocus: false,
    },
  );


  const dataArray = selectDataArray(type, timeframe, apiData);


  const showLastYear =
    type === "revenue" || (type !== "quotecount" && timeframe === "year");

  return (
    <TableCard
      selectedDate={selectedDate}
      title={title || ""}
      key={title}
      tableDataProp={dataArray}
      backgroundColor={undefined}
      noData={dataArray.length === 0}
      isLoading={isLoading}
      excelExport={excelExport}
    >
      {isFetching || isLoading || isRefetching ? (
        <TableLoadingSkeleton />
      ) : (
        <TableDisplay
          dataArray={dataArray}
          topN={topN}
          hideAgentName={hideAgentName}
          showLastYear={showLastYear}
          type={type}
          headerBgColor={headerBgColor}
        />
      )}
    </TableCard>
  );
};

export default AgentRanking;
