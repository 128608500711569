import React, { Dispatch, SetStateAction } from "react";
import { StyledGrid, StyledFieldWrapper } from "./styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid, Box, TextField, MenuItem } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import SearchBar from "../SearchBar/SearchBar";
import SalesExecList from "../SalesExecDropdown/SalesExecDropdown";
import TerritoryList from "../TerritoryDropdown/TerritoryDropdown";
import USStatesDropdown from "../USStatesDropdown";

// Custom Date Range Picker Component
interface DateRangePickerProps {
  selectedStartDate: Dayjs | null;
  selectedEndDate: Dayjs | null;
  handleStartDateChange: (date: Dayjs | null) => void;
  handleEndDateChange: (date: Dayjs | null) => void;
}

const DateRangePickerCustom = ({
  selectedStartDate,
  selectedEndDate,
  handleStartDateChange,
  handleEndDateChange,
}: DateRangePickerProps) => {
  return (
    <Box display="flex" alignItems="center" gap="1rem">
      <DatePicker
        label="Start Date"
        value={selectedStartDate}
        onChange={handleStartDateChange}
        minDate={dayjs("2022-01-01")}
        maxDate={dayjs().subtract(1, "day")}
        disableFuture
      />
      <DatePicker
        label="End Date"
        value={selectedEndDate}
        onChange={handleEndDateChange}
        minDate={dayjs("2022-01-01")}
        maxDate={dayjs().subtract(1, "day")}
        disableFuture
      />
    </Box>
  );
};

// Main ReportFields Component
interface ReportFieldsProps {
  selectedStartDate: Dayjs | null;
  selectedEndDate: Dayjs | null;
  handleStartDateChange: (date: Dayjs | null) => void;
  handleEndDateChange: (date: Dayjs | null) => void;
  setSelectedAgent: Dispatch<SetStateAction<string>>;
  selectedSalesExec: string;
  handleSalesExecChange: (event: { target: { value: string } }) => void;
  selectedTerritory: string;
  handleTerritoryChange: (event: { target: { value: string } }) => void;
  selectedAgent: string;
  selectedState: string;
  handleStateChange: (event: { target: { value: string } }) => void;
}

export const ReportFields = ({
  selectedStartDate,
  selectedEndDate,
  handleStartDateChange,
  handleEndDateChange,
  setSelectedAgent,
  handleSalesExecChange,
  handleTerritoryChange,
  selectedSalesExec,
  selectedTerritory,
  selectedAgent,
  handleStateChange,
  selectedState,
}: ReportFieldsProps) => {
  const [resetTrigger, setResetTrigger] = React.useState(false);

  const resetSearchBar = () => {
    setResetTrigger((prevState) => !prevState);
  };

  React.useEffect(() => {
    if (
      ((selectedSalesExec !== "All" && selectedSalesExec !== "") ||
        (selectedTerritory !== "All" && selectedTerritory !== "")) &&
      selectedAgent !== "All" &&
      selectedAgent !== ""
    ) {
      setSelectedAgent("All");
      resetSearchBar();
    }
  }, [selectedSalesExec, selectedTerritory]);

  React.useEffect(() => {
    if (
      selectedAgent !== "All" &&
      selectedAgent !== "" &&
      selectedSalesExec !== "All" &&
      selectedSalesExec !== ""
    ) {
      handleSalesExecChange({ target: { value: "All" } });
      handleTerritoryChange({ target: { value: "All" } });
    }
  }, [selectedAgent]);

  return (
    <StyledGrid container spacing={2}> {/* Reduced spacing for a tighter layout */}
      <Grid item xs={12} sm={6} md={4}> {/* Adjusted grid for compactness */}
        <StyledFieldWrapper>
          <DateRangePickerCustom
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
          />
        </StyledFieldWrapper>
      </Grid>

      <Grid item xs={12} sm={6} md={2}>
        <StyledFieldWrapper>
          <SearchBar
            setSelectedItem={setSelectedAgent}
            selectedItemDefaultVal={"All"}
            resetTrigger={resetTrigger}
          />
        </StyledFieldWrapper>
      </Grid>

      <Grid item xs={12} sm={6} md={2}>
        <StyledFieldWrapper>
          <SalesExecList
            onChange={handleSalesExecChange}
            defaultValue={selectedSalesExec}
          />
        </StyledFieldWrapper>
      </Grid>

      <Grid item xs={12} sm={6} md={2}>
        <StyledFieldWrapper>
          <TerritoryList
            onChange={handleTerritoryChange}
            defaultValue={selectedTerritory}
          />
        </StyledFieldWrapper>
      </Grid>

      <Grid item xs={12} sm={6} md={2}> {/* Adjusted to fit state dropdown */}
        <StyledFieldWrapper>
          <USStatesDropdown
            onChange={handleStateChange}
            defaultValue={selectedState}
          />
        </StyledFieldWrapper>
      </Grid>
    </StyledGrid>
  );
};

export default ReportFields;
