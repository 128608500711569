import { API } from "aws-amplify";
import { getApiRequestInfo } from "../../utils";
import { buildDashboardUrl } from "../buildDashboardUrl/buildDashboardUrl";


interface fetchPremiumDetailsData {
  startDate: Date;
  endDate: Date;
  agentCode: string;
  selectedStartDate: Date;
  selectedEndDate: Date;
  state: string;
  solicitorCode: string;
  territoryCode: string;
}

export async function fetchPremiumDetailsData({
  startDate,
  endDate,
  agentCode,
  state,
  solicitorCode,
  territoryCode
}: fetchPremiumDetailsData): Promise<any> {
  const requestInfo = await getApiRequestInfo(null);

  let startYear = startDate.getFullYear();
  let startMonth = (startDate.getMonth() + 1).toString().padStart(2, "0");
  let startDay = startDate.getDate().toString().padStart(2, "0");
  let startYearMonthDay = `${startYear}${startMonth}${startDay}`;
  let endYear = endDate.getFullYear();
  let endMonth = (endDate.getMonth() + 1).toString().padStart(2, "0");
  let endDay = endDate.getDate().toString().padStart(2, "0");
  let endYearMonthDay = `${endYear}${endMonth}${endDay}`;

  let url =
    "/detailedpremiumdata/" +
    startYearMonthDay + "/" + endYearMonthDay

  try {
    return await API.get(
      "idpro",
      buildDashboardUrl(url, agentCode, state, solicitorCode, territoryCode),
      requestInfo
    );
  } catch (error) {
    console.error("error: " + JSON.stringify(error));
    throw new Error("Failed to fetch detailed premium data");
  }

  return null;
}
