import React from "react";
import { TableHead, TableRow } from "@aws-amplify/ui-react";
import { DataType } from "../../types";
import { RevenueTableHead } from "./RevenueTableHead";
import { StandardTableHead } from "./StandardTableHead";
import { tableHeaderColor } from "../../../../constants";

interface TableHeadersProps {
  type: DataType;
  showLastYear: boolean;
}

const TableHeaders = ({
  type,
  showLastYear,
}: TableHeadersProps) => {
  let dollarLabel = "Premium";
  if (type === "amount_financed") {
    dollarLabel = "Amount Financed";
  }

  return (
    <TableHead
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        backgroundColor: tableHeaderColor,
      }}
      placeholder={undefined}
    >
      <TableRow placeholder={undefined}>
        {type === "revenue" ? (
          <RevenueTableHead showLastYear={showLastYear} />
        ) : (
          <StandardTableHead showLastYear={showLastYear} dollarLabel={dollarLabel} />
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeaders;
