import styled from "styled-components";

export const customModalStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(140,138,138,0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    background: "white",
    width: "80%",
    maxWidth: "calc(100vw - 2rem)",
    maxHeight: "calc(100vh - 2rem)",
    overflowY: "auto",
    position: "static",
    border: "1px solid #ccc",
    borderRadius: "0.3rem",
  },
};

export const StyledButton = styled.button`
  background-color: white;
  color: #047d95;
  width: fit-content;
  border-radius: 5px;
  padding: 12px 16px;
  border: 1px solid #047d95;
  cursor: pointer;
  min-width: 200px;
  display: flex;
  flex-direction: column;

  -webkit-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.06);

  & > p {
    margin: 0;
  }
  &:hover {
    filter: brightness(150%);
  }
`;
