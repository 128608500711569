import styled from "styled-components";
import { Heading } from "@aws-amplify/ui-react";

export const StyledHeading = styled(Heading)`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  color: var(--amplify-components-link-color);
`;

export const StyledSpan = styled.span`
  font-style: italic;
  font-size: small;
  vertical-align: baseline;
  font-weight: normal;
`;
