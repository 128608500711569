// RequireAuth.js
import { useContext } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { StateContext } from './StateContext';

export function RequireAuth({ children }) {
  const {isMFASetupRequired} = useContext(StateContext);
  const location = useLocation();
  const { user, route } = useAuthenticator((context) => [context.route]);

  if (route !== 'authenticated' || isMFASetupRequired) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}