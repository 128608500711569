import { API } from "aws-amplify";
import { getApiRequestInfo } from "../../utils";

interface fetchActiveAgents {
  limit?: number;
}

export async function fetchActiveAgents({ limit }: fetchActiveAgents): Promise<any> {

  const requestInfo = await getApiRequestInfo(null);
  const searchParams = limit ? `?limit=${limit}` : "";
  const url = "/activeagents" + searchParams;

  try {
    return await API.get("idpro", url, requestInfo);
  } catch (error) {
    console.error("Error fetching agents: ", error);
    return [];
  }
}
