import React, { useEffect, useState, useContext, useRef } from 'react';
import { Auth, API } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';

import MFASetupSuccessView from './MFASetupSuccessView';
import MFAMethodChoiceView from './MFAMethodChoiceView';
import MFASMSView from './MFASMSView';
import MFATOTPView from './MFATOTPView';

import { StateContext } from '../../StateContext';
import { getApiRequestInfo } from '../../api/utils';

const MFASetupManager = ({ setIsMfaPassed }) => {

  const [checkedIfMfaRequired, setCheckedIfMfaRequired] = useState(false);

  const {isMFASetupRequired, setIsMFASetupRequired} = useContext(StateContext);

  const isMFASetupRequiredRef = useRef(isMFASetupRequired);

  const [currentUser, setCurrentUser] = useState(null);

  const [mfaMethod, setMfaMethod] = useState(''); // 'SMS' or 'TOTP'

  const [totpSecret, setTotpSecret] = useState('');

  const [isSetupSuccessful, setIsSetupSuccessful] = useState(false);

  const setPhoneToVerified = useRef(false);
  const setEmailToVerified = useRef(false);

  useEffect(() => {
    const checkRequirements = async () => {
      await checkIfMfaRequired();
      setCheckedIfMfaRequired(true);
    };

    checkRequirements();
  }, []);

  //TODO: refactor: isMFASetupRequired is also determined in RequireAuth.jsx.
  const checkIfMfaRequired = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const mfaType = user.preferredMFA;
      const isRequired = mfaType === 'NOMFA';
      if (!isRequired) {
        handleClosingMFASetup();
      } else {
        console.log('MFA Setup Required!: ', mfaType);
      }
      setIsMFASetupRequired(isRequired);
      isMFASetupRequiredRef.current = isRequired;
      setCurrentUser(user);
    } catch (error) {
      console.error('Error checking user MFA status', error);
    }
  };

  const fetchUser = async () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        setCurrentUser(user);
      })
      .catch(error => console.error(error));
  };

  const setPreferredMFA = async (mfaMethod) => {
    try {
      await fetchUser();
      const result = await Auth.setPreferredMFA(currentUser, mfaMethod);
      await fetchUser();
    } catch (error) {
      console.error(`Error setting preferred MFA to ${mfaMethod}:`, error);
    }
  };

  const setupTOTP = async () => {
    try {
      const code = await Auth.setupTOTP(currentUser);
      setTotpSecret(code);
    } catch (error) {
      console.error('Error setting up TOTP:', error);
    }
  };

  // only for testing
  async function delay(seconds) {
    return new Promise((resolve) => {
      setTimeout(resolve, seconds * 1000);
    });
  }

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      console.log('Signed out successfully');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleMfaMethodChange = (method) => {
    method === 'TOTP' && setupTOTP();
    setMfaMethod(method);
  };

  const handleGoBack = () => {
    setMfaMethod('');
  };

  const setContactAttributesToVerified = async (emailToVerified, phoneToVerified) => {
    const body = {
      username: currentUser.username,
      attributes: {}
    }

    if (emailToVerified) {
      body.attributes.email_verified = "true";
    } else {
      body.attributes.email_verified = "false";
    }

    if (phoneToVerified) {
      body.attributes.phone_number_verified = "true";
    }

    const requestInfo = await getApiRequestInfo(body);
    const url = '/users';

    API.put("idpro", url, requestInfo)
    .then(response => {
      console.log("!!!!!!!!!!! Successfully set email and phone number to verified: ", response);
    })
    .catch(error => {
      console.error("Could not edit User: ", error);
      //TODO: display error message
    });
  };

  const handleClosingMFASetup = () => {
    if (checkedIfMfaRequired && isMFASetupRequiredRef.current) {
      setEmailToVerified.current = true;
      setContactAttributesToVerified(setEmailToVerified.current, setPhoneToVerified.current);
      setIsMFASetupRequired(false);
    }
    setIsMfaPassed(true);
  };

  return (
    <>
      {checkedIfMfaRequired && (
          <div className="amplify-flex" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, .7)', zIndex: 1000, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {isMFASetupRequiredRef.current && (
            <div className="amplify-card" style={{
              width: '470px', // Adjust the width as needed
              padding: '2rem',
              backgroundColor: 'white',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.08)',
            }}>
              {isSetupSuccessful && (
                <MFASetupSuccessView handleClosingMFASetup={handleClosingMFASetup} />
              )}
              {!isSetupSuccessful && !mfaMethod && (
                <MFAMethodChoiceView
                  handleMfaMethodChange={handleMfaMethodChange}
                  handleSignOut={handleSignOut}
                />
              )}
              {!isSetupSuccessful && mfaMethod === 'SMS' && (
                <MFASMSView
                  currentUser={currentUser}
                  handleGoBack={handleGoBack}
                  handleSignOut={handleSignOut}
                  setIsSetupSuccessful={setIsSetupSuccessful}
                  setPreferredMFA={setPreferredMFA}
                  setPhoneToVerified={setPhoneToVerified}
                />
              )}
              {!isSetupSuccessful && mfaMethod === 'TOTP' && (
                <MFATOTPView
                  currentUser={currentUser}
                  totpSecret={totpSecret}
                  handleGoBack={handleGoBack}
                  handleSignOut={handleSignOut}
                  setIsSetupSuccessful={setIsSetupSuccessful}
                  setPreferredMFA={setPreferredMFA}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>

  );
};

export default MFASetupManager;
