import styled from "styled-components";

export const StyledMessageWrapper = styled.div<{ isGPT: boolean; isError: boolean }>`
  display: flex;

  ${({ isGPT }) =>
    isGPT
      ? `flex-direction: column;
    align-items: end;
    margin-left: 100px;
    `
      : `margin-right: 100px;`}

  & > p {
    background-color: #047d95;
    color: white;
    width: fit-content;
    border-radius: 5px;
    padding: 8px 12px;
    min-width: 200px;

    ${({ isError }) => isError && `
    border: 1px solid #ed4337;
    background-color: white;
    color: #ed4337;
  `}
  }


`;
