import React from "react";
import { Form, useFormikContext } from "formik";
import { Grid, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { StyledGrid, StyledFieldWrapper } from "./styles";
import { formatOptionDisplay } from "./utils";
import ExportAvPTableDataIcon from "./ExportAvPTableDataIcon";

interface AvPFieldsProps {
  menuItems: any[];
  onDateChange: (date: any) => void;
  tablesData: object[];
  isAnythingFetching: boolean;
}

export const AvPFields = ({ menuItems, onDateChange, tablesData, isAnythingFetching }: AvPFieldsProps) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Form>
      <StyledGrid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
        <StyledFieldWrapper>
          <DatePicker
            label="Year"
            minDate={dayjs("2021").startOf("year")}
            // @ts-ignore
            value={values.selectedYear}
            onChange={(value) => {
              setFieldValue("selectedYear", value);
              onDateChange(value);
            }}
            maxDate={dayjs().subtract(1, "day")}
            views={["year"]}
          />
        </StyledFieldWrapper>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StyledFieldWrapper>
            <FormControl fullWidth>
            <InputLabel id="budget-item-label">Budget Item</InputLabel>

            <Select
              label="Budget Item"
              onChange={(value) => {
                setFieldValue("selectedBudgetItem", value.target.value);
                setFieldValue(
                  "isFinancial",
                  menuItems.find((item) => item.value === value.target.value)
                    .isFinancial,
                );
              }}
              // @ts-ignore
              value={values.selectedBudgetItem}
              className="us-states-dropdown"
            >
              {menuItems.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {formatOptionDisplay(item.title)}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
          </StyledFieldWrapper>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <StyledFieldWrapper>
            <div style={{ marginRight: "1rem" }}>Export Data</div>

            <ExportAvPTableDataIcon tablesData={tablesData} selectedDate={null} isDisabled={isAnythingFetching} />
          </StyledFieldWrapper>
        </Grid>

      </StyledGrid>
    </Form>
  );
};
