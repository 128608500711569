  import React, { useEffect, useState } from 'react';
  import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
  import { API } from "aws-amplify";
  import { getApiRequestInfo } from '../../api/utils';

  const TerritoryDropdown = ({ onChange, defaultValue }) => {
    const [territories, setTerritories] = useState([]);
    const [selectValue, setSelectValue] = useState(defaultValue);

    useEffect(() => {
      selectValue && setSelectValue(defaultValue);
    }, [defaultValue])


    useEffect(() => {
      const fetchData = async () => {
        try {
          const requestInfo = await getApiRequestInfo(null);
          const response = await API.get('idpro', '/territorylist', requestInfo);
          setTerritories(response);
        } catch (error) {
          console.error("Error getting territories: " + error);
        }
      };

      fetchData();
    }, []);

    return (
      <FormControl fullWidth>
        <InputLabel shrink>Territory</InputLabel>
        <Select onChange={onChange} value={selectValue} label="Territory" className="us-states-dropdown">
          <MenuItem value="All">All Territories</MenuItem>
          {territories.map((territory, index) => (
            <MenuItem key={index} value={territory.id}>
              {territory.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  export default TerritoryDropdown;
