import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import { DashboardWrapper, StyledHeading, ChartsWrapper } from "./styles";
import IncomeDashboardFields from "../../../components/IncomeDashboardFields/IncomeDashboardFields";
import dayjs from "dayjs";
import { Card, useTheme, View } from "@aws-amplify/ui-react";
import IncomeYearCompare from "../../../components/IncomeYearCompare/IncomeYearCompare";
import { getGraphs } from "./constants";

export function IncomeDashboard() {
  const startDate = dayjs().subtract(1, "day"); //  : dayjs(new Date(2023,8,20));
  const [selectedDate, setSelectedDate] = useState(startDate);
  const [selectedAgent, setSelectedAgent] = useState("All");
  const [selectedSalesExec, setSelectedSalesExec] = useState("All");
  const [selectedTerritory, setSelectedTerritory] = useState("All");
  const [containsOtherFees, setContainsOtherFees] = useState(false);
  const [containsVSCFees, setContainsVSCFees] = useState(false);

  const [selectedState, setSelectedState] = useState("All");
  const [key, setKey] = React.useState(Math.random());
  const graphs = useMemo(
    () => getGraphs(containsOtherFees, containsVSCFees),
    [containsOtherFees, containsVSCFees],
  );

  const handleDateChange = (date: React.SetStateAction<dayjs.Dayjs>) =>
    setSelectedDate(date);

  const selectedAgentChanged = (agent: string) => {
    setSelectedAgent(agent);
  };

  const handleSalesExecChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedSalesExec(event.target.value);
  };

  const handleTerritoryChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedTerritory(event.target.value);
  } 


  const handleStateChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedState(event.target.value);
  };

  useEffect(() => {
    setKey(Math.random()); // change key when selectedSalesExec changes so that Income vs Prior year updates
  }, [selectedSalesExec, selectedAgent, selectedState, selectedTerritory]);
  
  return (
    <>
      <DashboardWrapper>
        <StyledHeading level={5} placeholder={undefined}>
          {"Volume & Income by Month vs Prior Year"}
        </StyledHeading>

        <IncomeDashboardFields
          selectedDate={selectedDate}
          handleDateChange={handleDateChange as any}
          selectedAgent={selectedAgent}
          // @ts-ignore
          setSelectedAgent={selectedAgentChanged}
          selectedSalesExec={selectedSalesExec}
          selectedTerritory={selectedTerritory}
          // @ts-ignore
          handleSalesExecChange={handleSalesExecChange}
          handleTerritoryChange={handleTerritoryChange}
          selectedState={selectedState}
          handleStateChange={handleStateChange}
        />
      </DashboardWrapper>

      <View borderRadius="6px" maxWidth="100%" padding="0rem" minHeight="100vh">
        <ChartsWrapper>
          {graphs.map((graph, i) => {
            return (
              <View
                columnSpan={[3, 3, 3, 3]}
                rowSpan={{ base: 3, large: 3 }}
                key={i}
              >
                <Card borderRadius="15px" placeholder={undefined}>
                  <div className="card-title">{graph.selected[0].title}</div>
                  <div className="chart-wrap">
                    <div className="row">
                      <IncomeYearCompare
                        key={key}
                        startDate={selectedDate.toDate()}
                        incomeTypes={graph.selected}
                        options={graph.options}
                        agentCode={selectedAgent}
                        solicitorCode={selectedSalesExec}
                        territoryCode={selectedTerritory}
                        state={selectedState}
                        containsOtherFees={containsOtherFees}
                        containsVSCFees={containsVSCFees}
                        otherFeesCallback={setContainsOtherFees}
                        VSCFeesCallback={setContainsVSCFees}
                      />
                    </div>
                  </div>
                </Card>
              </View>
            );
          })}
        </ChartsWrapper>
      </View>
    </>
  );
}
