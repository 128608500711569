import { API, Auth } from "aws-amplify";
import { getApiRequestInfo } from "../../utils";
import dayjs from "dayjs";

interface fetchRankingDataArgs {
  startDate: Date;
}

export async function fetchRankingData({
  startDate,
}: fetchRankingDataArgs): Promise<any> {
  const requestInfo = await getApiRequestInfo(null);
  const url = `/agentranking/${dayjs(startDate).format("YYYYMMDD")}`;

  try {
    return await API.get("idpro", url, requestInfo);
  } catch (error) {
    console.error("error: " + JSON.stringify(error));
    throw new Error("Failed to fetch Rankings data");
  }

  return null;
}
