import React, { useEffect, useState } from "react";
import {
  APIProvider,
  Map,
  useAdvancedMarkerRef
} from "@vis.gl/react-google-maps";
import AgentMarkers from "./AgentMarkers";
import CustomAdvancedMarker from "./CustomAdvancedMarker";
import { fetchActiveAgents } from "../../api/utils/fetchActiveAgents/fetchActiveAgents";

import { useQuery } from "react-query";

export default function AgentMaps() {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [infowindowShown, setInfowindowShown] = useState(false);

  const toggleInfoWindow = () =>
    setInfowindowShown(previousState => !previousState);

  const closeInfoWindow = () => setInfowindowShown(false);

  const [currentPosition, setCurrentPosition] = useState({
    lat: 34.397,
    lng: -99.644
  });

  const success = (position: GeolocationPosition) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    };
    console.log('currentPosition:\n', currentPosition)
    setCurrentPosition(currentPosition);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success);
  }, []);


  const { data, isLoading } = useQuery(
    ['map-agent-data'],
    () => fetchActiveAgents({ limit: 1000 }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const activeAgents = data?.agents || [];

  if (isLoading) return <div>Loading...</div>;
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ""}>
        <Map
          center={currentPosition}
          zoom={4}
          mapId={process.env.REACT_APP_MAP_ID}
        >
          <>
            <CustomAdvancedMarker position={currentPosition} />
            {activeAgents && <AgentMarkers agents={activeAgents} />}
          </>
        </Map>
      </APIProvider>
    </div>
  );
}
