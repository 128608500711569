import React from "react";
import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Flex, Placeholder } from "@aws-amplify/ui-react";
import { DollarString } from "../../utils";
import { useQuery } from "react-query";
import { fetchMonthlySummaryData } from "../../api/utils/fetchMonthlySummaryData/fetchMonthlySummaryData";

const defaultOptions = {
  chart: {
    fontFamily: "inherit",
    parentHeightOffset: 0,
    toolbar: {
      show: false,
    },
    animations: {
      enabled: true,
      speed: 400,
    },
    stacked: true,
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  stroke: {
    curve: "straight",
    show: true,

    lineCap: "butt",
    width: 2,
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 1,
  },
  grid: {
    padding: {
      top: -20,
      right: 0,
      left: -4,
      bottom: -4,
    },
    strokeDashArray: 4,
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
  xaxis: {
    tooltip: {
      enabled: false,
    },
    axisBorder: {
      show: false,
    },
    type: "datetime",
  },
  yaxis: {
    forceNiceScale: true,
    min: 0,
    labels: {
      padding: 4,
      formatter: (value) => {
        return DollarString(value);
      },
    },
  },
  markers: {
    size: 3,
    hover: {
      sizeOffset: 6,
    },
  },
  // array of colors to use for the chart
  colors: [
    "#0b97ac",
    "#00a2a5",
    "#00ab92",
    "#2eb375",
    "#67b753",
    "#98b72e",
    "#cbb200",
    "#ffa600",
  ],
  legend: {
    show: true,
  },
};

//
// HCSNOTE:    fiscalStart is YYYYMMDD
//              startDate is a Date object containing the date we're doing this chart for (typically yesterday)
//   Chart starts at the Fiscal Start date and ends in 12 months
//        budget line will be full 12 months
//        bar chart will be fiscal start to the current month
function IncomeVPlanChart({
  startDate,
  incomeTypes,
  fiscalStart,
  planData,
  monthPlanDataIndex,
  options,
  agentCode,
  selectedState,
  selectedSalesExec,
}) {
  const [barChartData, setBarChartData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    //console.log({ labels });
  }, [labels]);

  const { isLoading } = useQuery(
    [
      `monthlySummaryData`,
      startDate,
      agentCode,
      selectedState,
      selectedSalesExec,
    ],
    () =>
      fetchMonthlySummaryData({
        fiscalStart,
        startDate,
        agentCode,
        selectedState,
        selectedSalesExec,
      }),
    {
      enabled: !!startDate,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          const barChartData = [];
          const fiscalStartDate = new Date(
            fiscalStart.slice(0, 4),
            fiscalStart.slice(4, 6) - 1,
            fiscalStart.slice(6, 8),
          );

          const lineChartDataObj = {};
          lineChartDataObj.name = "Total Plan";
          lineChartDataObj.type = "line";
          lineChartDataObj.data = [];

          // get the data for the budget numbers for this year from the passed in planData object
          //   need to start with the last month since we're pushing the data into the array
          for (let i = planData.months.length-1; i >= 0; i--) {
            var total = 0;
            for (let j = 0; j < incomeTypes.length; j++) {
              total += planData.months[i][incomeTypes[j].selector]?.total || 0;
            }
            lineChartDataObj.data.push(total.toFixed(0));
          }

          for (let j = 0; j < incomeTypes.length; j++) {
            const barChartDataObj = {
              name: incomeTypes[j].title,
              type: "bar",
              data: [],
            };

            // first pad the bar chart data with 0's for the months after this month in this fiscal year
            for (let i = 0; i < 12 - data.length; i++) {
              barChartDataObj.data.push(0);
            }
            //  get the income date starting from today down to the fiscal start for the bar chart and pad rest with 0
            for (let i = data.length - 1; i >= 0; i--) {
              var value = parseFloat(
                data[i].monthly_income.find(
                  (item) => item.receivabletype === incomeTypes[j].selector,
                )?.amount ?? 0,
              );
              barChartDataObj.data.push(value);
            }
            barChartData.push(barChartDataObj);
          }

          barChartData.push(lineChartDataObj);
          setBarChartData(barChartData);

          const months = [];

          var workingDate = new Date(fiscalStartDate);
          workingDate.setMonth(workingDate.getMonth() + 11);
          var workingYear = workingDate.getFullYear();
          var workingMonth = (workingDate.getMonth() + 1)
            .toString()
            .padStart(2, "0");
          var strMonthYear = `${workingYear}-${workingMonth}`;
          months.push(strMonthYear);

          for (let i = 1; i < 12; i++) {
            workingDate.setMonth(workingDate.getMonth() - 1);
            workingYear = workingDate.getFullYear();
            workingMonth = (workingDate.getMonth() + 1)
              .toString()
              .padStart(2, "0");
            strMonthYear = `${workingYear}-${workingMonth}`;
            months.push(strMonthYear);
          }
          setLabels(months);
        }
      },
    },
  );

  if (isLoading) {
    return (
      <Flex direction="column" minHeight="285px">
        <Placeholder size="small" />
        <Placeholder size="small" />
        <Placeholder size="small" />
        <Placeholder size="small" />
      </Flex>
    );
  }

  if (!barChartData || barChartData.length === 0) {
    return (
      <Flex direction="column" minHeight="285px">
        <Placeholder size="small" />
        <Placeholder size="small" />
        <Placeholder size="small" />
        <Placeholder size="small" />
      </Flex>
    );
  }

  return (
    <Chart
      series={barChartData}
      type="bar"
      height={284}
      width="100%"
      options={Object.assign({}, defaultOptions, options, { labels })}
    />
  );
}

export default IncomeVPlanChart;
