import React from "react";
import { Tab, Tabs, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  cancellationBlocks,
  writeoffBlocks,
  generateRevenueBlocks,
  generateActivatedPremiumBlocks,
  generateQuoteBlocks,
} from "./constants";
import AgentCancellations from "../../pages/pfc_dashboard/AgentCancellations";
import AgentWriteoffs from "../../pages/pfc_dashboard/AgentWriteoffs";
import { Dayjs } from "dayjs";
import AgentRanking from "../AgentRanking/AgentRanking";
import { StyledTabList, StyledTabColumn, StyledCard } from "./styles";
import { Grid, useTheme } from "@aws-amplify/ui-react";

interface AgentTabsProps {
  selectedDate: Dayjs;
}

export const AgentTabs = ({ selectedDate }: AgentTabsProps) => {
  const revenueBlocks = generateRevenueBlocks(selectedDate);
  const activatedPremiumBlocks = generateActivatedPremiumBlocks(selectedDate);
  const quoteBlocks = generateQuoteBlocks(selectedDate);
  const { tokens } = useTheme();

  return (
    <Tabs>
      <StyledTabList>
        <Tab>Activated Premium</Tab>

        <Tab>Revenue</Tab>
        <Tab>Quote</Tab>
        <Tab>Cancellations</Tab>
        <Tab>Write-Offs</Tab>
      </StyledTabList>

      <TabPanel forceRender={true}>
        <Grid
          templateColumns={{ base: "1fr", xxl: "1fr 1fr" }}
          templateRows={{ base: "repeat(4, 10rem)", large: "repeat(3, 8rem)" }}
          gap={tokens.space.medium}
          placeholder={undefined}
        >
          {activatedPremiumBlocks.map((block, index) => (
            <StyledCard placeholder={null} columnSpan={1} rowSpan={10}>
              <AgentRanking
                {...block}
                startDate={selectedDate.toDate()}
                selectedDate={selectedDate}
              />
            </StyledCard>
          ))}
        </Grid>
      </TabPanel>

      <TabPanel forceRender={true}>
        <Grid
          templateColumns={{ base: "1fr" }}
          templateRows={{ base: "repeat(4, 10rem)", large: "repeat(3, 8rem)" }}
          gap={tokens.space.medium}
          placeholder={undefined}
        >
          {revenueBlocks.map((block, index) => (
            <StyledCard placeholder={null} columnSpan={1} rowSpan={10}>
              <AgentRanking
                {...block}
                startDate={selectedDate.toDate()}
                selectedDate={selectedDate}
              />
            </StyledCard>
          ))}
        </Grid>
      </TabPanel>

      <TabPanel forceRender={true}>
        <Grid
          templateColumns={{ base: "1fr", xl: "1fr 1fr" }}
          templateRows={{ base: "repeat(4, 10rem)", large: "repeat(3, 8rem)" }}
          gap={tokens.space.medium}
          placeholder={undefined}
        >
          {quoteBlocks.map((block, index) => (
            <StyledCard placeholder={null} columnSpan={1} rowSpan={10}>
              <AgentRanking
                {...block}
                startDate={selectedDate.toDate()}
                selectedDate={selectedDate}
              />
            </StyledCard>
          ))}
        </Grid>
      </TabPanel>
      <TabPanel forceRender={true}>
        <Grid
          templateColumns={{ base: "1fr", xxl: "1fr 1fr" }}
          templateRows={{ base: "repeat(4, 10rem)", large: "repeat(3, 8rem)" }}
          gap={tokens.space.medium}
          placeholder={undefined}
        >
          {cancellationBlocks.map((block, index) => (
            <StyledCard placeholder={null} columnSpan={1} rowSpan={10}>
              <AgentCancellations
                {...block}
                startDate={selectedDate.toDate()}
                selectedDate={selectedDate}
              />
            </StyledCard>
          ))}
        </Grid>
      </TabPanel>
      <TabPanel>
        <Grid
            templateColumns={{ base: "1fr", xxl: "1fr 1fr" }}
            templateRows={{ base: "repeat(4, 10rem)", large: "repeat(3, 8rem)" }}
            gap={tokens.space.medium}
            placeholder={undefined}
          >
            {writeoffBlocks.map((block, index) => (
              <StyledCard placeholder={null} columnSpan={1} rowSpan={10}>
                <AgentWriteoffs
                  {...block}
                  startDate={selectedDate.toDate()}
                  selectedDate={selectedDate}
                />
              </StyledCard>
            ))}
          </Grid>
      </TabPanel>
    </Tabs>
  );
};

export default AgentTabs;
