import React, { useState } from "react";
import { ChatHistoryMessageType } from "../types";
import ChatMessageData from "../ChatMessageData/ChatMessageData";
import { StyledMessageWrapper } from "./styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ChatMessageProps {
  conversationTitle: string;
  chatItem: ChatHistoryMessageType;
  onUpdate: (updatedItem: ChatHistoryMessageType) => void;
}

export const ChatHistoryMessage = ({ conversationTitle, chatItem, onUpdate }: ChatMessageProps) => {
  const [isApproved, setIsApproved] = useState<boolean>(chatItem.approved ? chatItem.approved : false);
  const [comment, setComment] = useState<string>("");

  // When the approval checkbox changes
  const handleApprovalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedItem = {
      ...chatItem,
      approved: event.target.checked,
    };

    setIsApproved(event.target.checked);
    onUpdate(updatedItem);
  };

  // When the comment input changes
  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedItem = {
      ...chatItem,
      comment: event.target.value,
    };

    setComment(event.target.value);
    onUpdate(updatedItem);
  };

  if (chatItem.type === "loading") {
    return null;
  }

  // handles type difference of user content vs aiinsight content
  let content, query;
  if (typeof chatItem.content === 'string') {
    content = chatItem.content;
  } else {
    content = chatItem.content.chatResponse;
    query = chatItem.content.query;
  }

  return (
    <>
      <StyledMessageWrapper
        isGPT={chatItem.role === "assistant"}
        isError={chatItem.type === "gptResponseError"}
      >
        <p>
          <strong>{chatItem.role === "user" ? "You" : "ChatPFC"}:</strong>
          <br />
          {content}
        </p>

        {!!chatItem.tableData?.length && (
          <ChatMessageData conversationTitle={conversationTitle} data={chatItem.tableData} />
        )}



      </StyledMessageWrapper>
      {chatItem.role === "assistant" && (
        <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <FormControlLabel
            control={<Checkbox checked={isApproved} onChange={handleApprovalChange} />}
            label="Approved"
          />
          <TextField
            label="Comment"
            variant="outlined"
            size="small"
            fullWidth
            value={comment}
            onChange={handleCommentChange}
            margin="dense"
          />
        </div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel-header"
          >
            <Typography>Query</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {query && (
              <Typography variant="body2">Query: {query}</Typography>
            )}
          </AccordionDetails>
        </Accordion>
        </>
      )}
    </>
    
  );
};
