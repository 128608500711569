import React, { useEffect, useState } from "react";
import {
  AdvancedMarker,
  Pin,
  InfoWindow,
  useAdvancedMarkerRef,
  AdvancedMarkerRef
} from "@vis.gl/react-google-maps";
import { Position, Agent } from "./types";
import { DollarValueAgentMaps } from "../../utils";

interface Props {
  position: Position;
  agent?: Agent;
  setMarkerRef?: (marker: AdvancedMarkerRef | null, agentId: string) => void;
}

const CustomAdvancedMarker: React.FC<Props> = ({ position, agent, setMarkerRef }) => {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [infowindowShown, setInfowindowShown] = useState(false);

  // call setMarkerRef
  useEffect(() => {
    // Check if we have a marker and an agent
    if (marker && agent && setMarkerRef) {
      setMarkerRef(marker, agent.agent_id.toString());
    }

    // Cleanup function
    return () => {
      if (agent && setMarkerRef) {
        setMarkerRef(null, agent.agent_id.toString());
      }
    };
  }, [marker, agent, setMarkerRef]);

  const toggleInfoWindow = () => setInfowindowShown(prev => !prev);
  const closeInfoWindow = () => setInfowindowShown(false);

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        position={position}
        key={agent ? agent.agent_id.toString() : 'user'}
        onClick={toggleInfoWindow}
      >
        {agent ? (
          <Pin
            background={"black"}
            borderColor={"black"}
            glyphColor={"red"}
          />
        ) : (
          <Pin
            background={"orange"}
            borderColor={"orange"}
            glyphColor={"blue"}
          />
        )}
      </AdvancedMarker>
      {infowindowShown && (
        <InfoWindow anchor={marker} onCloseClick={closeInfoWindow}>
          {agent ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td colSpan={2}><b>{agent.agent_name}</b></td>
                  </tr>
                  <tr>
                    <td>Active Loans:</td>
                    <td align="right">{agent.number_of_loans}</td>
                  </tr>
                  <tr>
                    <td>Active Balance:</td>
                    <td align="right"><DollarValueAgentMaps value={agent.total_balance} /></td>
                  </tr>
                  <tr>
                    <td>Premium (12 months):</td>
                    <td align="right"><DollarValueAgentMaps value={agent.last_12_months_premium}/></td>
                  </tr>
                  <tr>
                    <td>Premium YTD:</td>
                    <td align="right"><DollarValueAgentMaps value={agent.ytd_premium}/></td>
                  </tr>
                  <tr>
                    <td>Premium PYTD:</td>
                    <td align="right"><DollarValueAgentMaps value={agent.pytd_premium}/></td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div>
              <p>Your current location</p>
            </div>
          )}
        </InfoWindow>
      )}
    </>
  );
};

export default CustomAdvancedMarker;
