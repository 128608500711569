import { useState, useEffect } from "react";
import { Auth } from "@aws-amplify/auth";
import { Hub, HubCallback } from "@aws-amplify/core";

interface AuthStatus {
  isAuthenticated: boolean;
  signOut: () => void;
}

export function useAuthStatus(): AuthStatus {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    const authListener: HubCallback = ({ payload: { event } }) => {
      switch (event) {
        case "signIn":
        case "tokenRefresh":
          setIsAuthenticated(true);
          break;
        case "signOut":
          setIsAuthenticated(false);
          break;
        default:
          break;
      }
    };

    const removeListener = Hub.listen("auth", authListener);

    checkAuthStatus();

    // Cleanup function to remove the listener using the remove function returned by Hub.listen
    return () => removeListener();
  }, []);

  const signOut = async () => {
    try {
      await Auth.signOut();
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return { isAuthenticated, signOut };
}
