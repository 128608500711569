import React, { useContext } from "react";
import "./Header.css";
import { baseConfig } from "../../config";
import { Flex } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";

import Logo from "../Logo";
// import HeaderNav from "./headerNav/HeaderNav";
import HeaderNav from "./headerNav/HeaderNav";
import HeaderSearchBar from "./HeaderSearchBar";
import HeaderCustomerSelector from "./HeaderCustomerSelector";

import { StateContext } from "../../StateContext";
import { HeaderLogo, HeaderLeft, HeaderRight, StyledHeader } from "./styles";

type HeaderProps = {
  username: string;
  customername: string;
};

const Header = ({ username, customername }: HeaderProps) => {
  const { isSuperAdmin, clientType } = useContext(StateContext);
  if (clientType === 'mga') {
    baseConfig.headerText = "MGA Insight";
  } else {
    baseConfig.headerText = "PFC Insight";
  } 
  return (
    <StyledHeader>
      <Flex
        direction="row"
        alignItems="center"
        wrap="nowrap"
        gap="1rem"
        justifyContent="space-between"
        placeholder={undefined}
      >
        <HeaderLeft>
          <HeaderLogo>
            <Link to="/">
              <Logo />
            </Link>
          </HeaderLogo>
          <span className="header-text">{baseConfig.headerText}</span>
          <span className="customer-name"> - {customername}</span>
        </HeaderLeft>

        {isSuperAdmin && (
          <HeaderLeft>
            <HeaderCustomerSelector />
          </HeaderLeft>
        )}

        <HeaderRight>
          {baseConfig.search && <HeaderSearchBar />}
          <HeaderNav username={username} />
        </HeaderRight>
      </Flex>
    </StyledHeader>
  );
};

export default Header;
