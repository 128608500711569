import React, { useMemo, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "../Dashboard.css";
import { Heading } from "@aws-amplify/ui-react";
import AgentTabs from "../../../components/AgentTabs/AgentTabs";
import { StyledView, StyledHeading } from "./styles";

export function AgentDashboard() {
  const startDate = dayjs().subtract(1, "day");
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(startDate);

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setSelectedDate(date);
    }
  };

  return (
    <>
      <div>
        <StyledHeading level={4} placeholder={undefined}>
          {"Agent Ranking"} 
        </StyledHeading>
        <DatePicker
          value={selectedDate}
          onChange={handleDateChange}
          maxDate={dayjs().subtract(1, "day")}
        />
      </div>
      <StyledView
        borderRadius="6px"
        maxWidth="100%"
        padding="0rem"
        minHeight="100vh"
      >
        <AgentTabs selectedDate={selectedDate}/>
      </StyledView>
    </>
  );
}
