import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useGoogleTagPageView = () => {
    const location = useLocation();

    useEffect(() => {
        if (window.gtag) {
            const isDev = location.hostname === 'localhost' || location.hostname === 'dev.pfcinsight.com';
            const tagId = isDev ? 'G-BE0TKTME5Q' : 'G-V14QVZGNMN';

            window.gtag('config', tagId, {
                page_path: location.pathname + location.search,
            });
        }
    }, [location]);
};

export default useGoogleTagPageView;
