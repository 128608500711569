import styled from "styled-components";
import {Heading} from "@aws-amplify/ui-react";

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 164px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledHeading = styled(Heading)`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  text-align: center;
`;

