import { TableCell } from "@aws-amplify/ui-react";
import React from "react";

export const RevenueTableHead = ({
  showLastYear,
}: {
  showLastYear: boolean;
}) => {
  return (
    <>
        <TableCell as="th" placeholder={undefined}>
            Rank
        </TableCell>
      <TableCell as="th" placeholder={undefined}>
        Agent
      </TableCell>
      <TableCell as="th" className="money" placeholder={undefined}>
        Total Revenue
      </TableCell>
      {showLastYear && (
        <TableCell as="th" className="money" placeholder={undefined}>
          Last Year
        </TableCell>
      )}
      <TableCell as="th" className="money" placeholder={undefined}>
        Interest
      </TableCell>
      <TableCell as="th" className="money" placeholder={undefined}>
        Late Fees
      </TableCell>
      <TableCell as="th" className="money" placeholder={undefined}>
        NSF Fees
      </TableCell>
      <TableCell as="th" className="money" placeholder={undefined}>
        Cancel Fees
      </TableCell>
      <TableCell as="th" className="money" placeholder={undefined}>
        Setup Fees
      </TableCell>
      <TableCell as="th" className="money" placeholder={undefined}>
        Number of Loans
      </TableCell>
    </>
  );
};
