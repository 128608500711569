import { DollarString } from "../../utils";

export const defaultOptions = {
  chart: {
    fontFamily: "inherit",
    redrawOnWindowResize: true,
    parentHeightOffset: 0,
    toolbar: {
      show: false,
    },
    animations: {
      enabled: true,
      speed: 400,
    },
    stacked: true,
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  stroke: {
    curve: "straight",
    show: true,

    lineCap: "butt",
    width: 2,
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 1,
  },
  grid: {
    padding: {
      top: -20,
      right: 0,
      left: -4,
      bottom: -4,
    },
    strokeDashArray: 4,
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
  xaxis: {
    tooltip: {
      enabled: false,
    },
    axisBorder: {
      show: false,
    },
    type: "datetime",
  },
  yaxis: {
    forceNiceScale: true,
    min: 0,
    labels: {
      padding: 4,
      formatter: (value: any) => {
        return value;
      },
    },
  },
  markers: {
    size: 3,
    hover: {
      sizeOffset: 6,
    },
  },
  // array of colors to use for the chart
  colors: [
    "#0b97ac",
    "#00a2a5",
    "#00ab92",
    "#2eb375",
    "#67b753",
    "#98b72e",
    "#cbb200",
    "#ffa600",
  ],
  legend: {
    show: true,
  },
};
