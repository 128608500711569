import styled from "styled-components";
import { Form, Field } from "formik";
import IconButton from "@mui/material/IconButton";

export const StyledIconButton = styled(IconButton)`
  path {
    fill: #047d95;
  }
  
  circle {
    stroke: #047d95;
  }
`;

export const StyledForm = styled(Form)`
  border: 1px solid grey;
  border-radius: 15px;
  padding: 6px;
  overflow-y: hidden;
  width: 100%;
  max-width: 43rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  margin-top: 1rem;
`;

export const StyledField = styled(Field)`
  background-color: transparent;
  border: none;
  width: 100%;

  &:focus {
    outline: none;
  }
`;
