import React, {useState} from 'react';
import { CircularProgress } from '@mui/material';
import MFAErrorMsg from './MFAErrorMsg';
import { Auth } from 'aws-amplify';

import QRCode from 'qrcode.react';

const MFATOTPView = ({
  currentUser,
  totpSecret,
  handleGoBack,
  handleSignOut,
  setIsSetupSuccessful,
  setPreferredMFA
}) => {
  const [isVerifyingTOTPCode, setIsVerifyingTOTPCode] = useState(false);
  const [showVerificationStatusMsg, setShowVerificationStatusMsg] = useState(true);
  const [cannotVerifyTOTPCode, setCannotVerifyTOTPCode] = useState(null);
  const [totpCode, setTotpCode] = useState('');

  const verifyTOTP = async () => {
    setCannotVerifyTOTPCode(false);
    console.log('in verifyTOTP');
    setIsVerifyingTOTPCode(true);
    try {
      const result = await Auth.verifyTotpToken(currentUser, totpCode);
      console.log('TOTP token verified?:', result);
      if (result === 'SUCCESS' || result.Status === 'SUCCESS') {
        setCannotVerifyTOTPCode(false);
        await setPreferredMFA('TOTP');
        setIsSetupSuccessful(true);
      } else {
        setCannotVerifyTOTPCode(true);
        setTotpCode('');
      } 
    } catch (error) {
      console.error('Error verifying TOTP token:', error);
      setCannotVerifyTOTPCode(true);
      setTotpCode('');
    }
    setIsVerifyingTOTPCode(false);
  };

  return (
    <div className="amplify-flex" style={{ flexDirection: 'column', gap: '1rem' }}>
      <h3 className="amplify-heading amplify-heading--3">App based MFA Setup</h3>
      <label className="amplify-label">
        Scan the QR-code below within the mobile App of your choosing such as Authenticator or Authy
      </label>
      {totpSecret ? (
        <QRCode
          value={`otpauth://totp/${currentUser.attributes.email ? currentUser.attributes.email : currentUser.username}?secret=${totpSecret}&issuer=PFCInsight`}
        />
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <CircularProgress size={20} color="inherit" />
          <label className="amplify-label">Generating code...</label>
        </div>
      )}
      <input
        className="amplify-input"
        type="text"
        value={totpCode}
        onChange={(e) => setTotpCode(e.target.value)}
        placeholder="Enter code"
      />
      <button className="amplify-button amplify-button--primary" onClick={() => {
        setShowVerificationStatusMsg(true);
        verifyTOTP();
      }}>
        {isVerifyingTOTPCode ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          'Verify Code'
        )}
      </button>

      {showVerificationStatusMsg && cannotVerifyTOTPCode && (
        <MFAErrorMsg message="Failed to verify the TOTP code. Please try again." setShowMsg={setShowVerificationStatusMsg}/>
      )}

      <div
        className="amplify-flex justify-center items-center"
        style={{ justifyContent: 'center', alignItems: 'center' }}
      >
        <button
          className="amplify-button amplify-field-group__control amplify-button--link amplify-button--small"
          onClick={handleGoBack}
        >
          Change MFA method
        </button>

        <button
          className="amplify-button amplify-field-group__control amplify-button--link amplify-button--small"
          onClick={handleSignOut}
        >
          Back to Sign In
        </button>
      </div>
    </div>
  );
};

export default MFATOTPView;
