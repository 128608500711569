import { ApiResponse } from "./types";

/**
 * Selects data array based on type and timeframe from the api response.
 *
 * @param type The type of data to display: activatedpremium, quotecount, or revenue
 * @param timeframe The time period to display: day, week, month, year
 * @param apiData The response data from the API
 * @returns The selected data array
 */
export const selectDataArray = (
  type: string,
  timeframe: string,
  apiData: ApiResponse,
): any[] => {
  let key: keyof ApiResponse;
  if (type === "activatedpremium") {
    key = `${timeframe}_premium` as keyof ApiResponse;
  } else if (type === "quotecount") {
    key = `${timeframe}_quote_count` as keyof ApiResponse;
  } else if (type === "revenue" && timeframe === "year") {
    key = "year_income";
  } else if (type === "amount_financed") {
    key = `${timeframe}_amount_financed` as keyof ApiResponse;
  } else {
    return [];
  }

  return apiData?.[key] || [];
};

export function DollarValue({
  value,
  color = "black",
}: {
  value: number;
  color?: string;
}) {
  const formattedValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);

  return <span style={{ color: `${color}` }}>{formattedValue}</span>;
}
