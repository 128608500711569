import React from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

const ExportAvPTableDataIcon = ({ tablesData, selectedDate, isDisabled}) => {

  const handleExport = async () => {
    if (!tablesData || tablesData.length === 0) {
      console.log("No data to export.");
      return;
    }

    const workbook = new ExcelJS.Workbook();

    tablesData.forEach((table) => {
      const { segment, monthlyValues } = table;
      const title = segment.toUpperCase();

      if (!monthlyValues || monthlyValues.length === 0) {
        console.log(`No data to export for ${title}.`);
        return;
      }

      const worksheet = workbook.addWorksheet(title);

      // Define columns
      worksheet.columns = [
        { header: '', key: 'header', width: 15 },
        ...monthlyValues.map((monthValue, index) => ({
          key: `col${index}`,
          width: 15
        }))
      ];

      // Add the headline row at the top
      const headlineRowIndex = 1;
      worksheet.getRow(headlineRowIndex).values = [''];
      
      if (selectedDate) {
        // worksheet.getCell(`A${headlineRowIndex}`).value = title + ' (as of ' + selectedDate.format('MM/DD/YYYY') + ')';
        worksheet.getCell(`A${headlineRowIndex}`).value = title;
      } else {
        worksheet.getCell(`A${headlineRowIndex}`).value = title;
      }
      
      worksheet.getCell(`A${headlineRowIndex}`).alignment = { horizontal: 'center' };
      worksheet.getRow(headlineRowIndex).font = { bold: true, size: 14 };
      worksheet.mergeCells(`A${headlineRowIndex}`, `${String.fromCharCode(65 + monthlyValues.length)}${headlineRowIndex}`);

      // Add a blank row after the headline
      const blankRowIndex = headlineRowIndex + 1;
      worksheet.getRow(blankRowIndex).values = [''];

      // Add header row with formatted titles
      const headerRowIndex = blankRowIndex + 1;
      worksheet.getRow(headerRowIndex).values = [
        '',
        ...monthlyValues.map(monthValue => `${monthValue.month} / ${monthValue.year}`)
      ];
      worksheet.getRow(headerRowIndex).font = { bold: true };

      // Add actual values row
      const actualRowIndex = headerRowIndex + 1;
      worksheet.getRow(actualRowIndex).values = ['Actual'];
      monthlyValues.forEach((monthValue, index) => {
        worksheet.getCell(actualRowIndex, index + 2).value = monthValue.actualValue || '-';
      });

      // Add budget values row
      const budgetRowIndex = actualRowIndex + 1;
      worksheet.getRow(budgetRowIndex).values = ['Budget'];
      monthlyValues.forEach((monthValue, index) => {
        worksheet.getCell(budgetRowIndex, index + 2).value = monthValue.budgetSegmentValue || '-';
      });

      // Add difference values row
      const differenceRowIndex = budgetRowIndex + 1;
      worksheet.getRow(differenceRowIndex).values = ['Difference'];
      monthlyValues.forEach((monthValue, index) => {
        const difference = monthValue.actualValue && monthValue.budgetSegmentValue
          ? monthValue.differenceValue
          : '-';
        worksheet.getCell(differenceRowIndex, index + 2).value = difference;
        worksheet.getCell(differenceRowIndex, index + 2).font = { color: { argb: monthValue.differenceValue < 0 ? 'FFFF0000' : 'FF000000' } };
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(blob, 'export_actual_v_plan_by_segment.xlsx');
  };

  return (
    <IconButton onClick={handleExport} disabled={isDisabled}>
      <FileDownloadIcon />
    </IconButton>
  );
};

export default ExportAvPTableDataIcon;
