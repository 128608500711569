import React, { useState, useEffect } from "react";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Flex,
  Placeholder,
} from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

import dayjs from "dayjs";
import { getApiRequestInfo } from "../../api/utils";
import TableCard from "../../components/DashboardCard/TableCard";

const AgentCancellations = ({
  startDate,
  showTop,
  headerBgColor,
  setTableData = null,
  selectedDate,
  title,
}) => {
  const [apiData, setApiData] = useState(null);
  const [nAgents, setNAgents] = useState(20); // State for the number of agents to display
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const getAgentCancellations = async () => {
      const requestInfo = await getApiRequestInfo(null);
      const dateQP = `selectedDate=${dayjs(startDate).format("YYYYMMDD")}`;
      const showTopQP = `showTop=${showTop}`;
      const nAgentsQP = `nAgents=${nAgents}`;
      const url = `/agentcancellations?${dateQP}&${showTopQP}&${nAgentsQP}`;

      try {
        const response = await API.get("idpro", url, requestInfo);
        setApiData(response.items);
        setTableData && setTableData(response.items);
      } catch (error) {
        console.log("error: " + JSON.stringify(error));
      }
    };
    setIsFetching(true);
    getAgentCancellations();
    setIsFetching(false);
  }, [startDate, nAgents]);

  const handleNAgentsChange = (event) => {
    setNAgents(event.target.value);
  };

  return (
    <div>
      <TableCard
        selectedDate={selectedDate}
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {title || ""}
            <Tooltip title="This is calculated by counting all accounts that have been activated for an agent up to 3 years ago and comparing it to the number of accounts that were canceled and closed in that same timeframe.  It includes counts that are currently open.  In parenthese is the cancellation percentage for closed accounts only.">
              <InfoIcon style={{ marginLeft: 8, fontSize: '1rem', cursor: 'pointer' }} />
            </Tooltip>
          </div>
        }
        key={title}
        tableDataProp={apiData}
        backgroundColor={"white"}
      >
        <FormControl
          variant="outlined"
          size="small"
          style={{ marginBottom: "1rem", width: "20%", marginTop: "0.5rem" }}
        >
          <InputLabel id="nAgents-select-label">Number of Agents</InputLabel>
          <Select
            labelId="nAgents-select-label"
            id="nAgents-select"
            value={nAgents}
            onChange={handleNAgentsChange}
            label="Number of Agents"
          >
            {[20, 40, 60, 80, 100].map((number) => (
              <MenuItem key={number} value={number}>
                {number}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {apiData && !isFetching ? (
          <Table caption="" highlightOnHover={true} size={"small"}>
            <TableHead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: headerBgColor,
              }}
            >
              <TableRow>
                <TableCell as="th">Rank</TableCell>
                <TableCell as="th">Agent Name</TableCell>
                <TableCell as="th">Number of Accounts</TableCell>
                <TableCell as="th">Number of Cancellations</TableCell>
                <TableCell as="th">% Accounts Canceled</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apiData.slice(0, nAgents).map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.rank}</TableCell>
                  <TableCell>{item.agent_name_code}</TableCell>
                  <TableCell>{item.number_of_accounts} ({item.number_of_closed_accounts})</TableCell>
                  <TableCell>{item.number_of_cancellations}</TableCell>
                  <TableCell>{item.percent_of_accounts_canceled} ({item.percent_of_closed_accounts_canceled})</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Flex direction="column" minHeight="285px">
            <Placeholder size="small" />
            <Placeholder size="small" />
            <Placeholder size="small" />
            <Placeholder size="small" />
          </Flex>
        )}
      </TableCard>
    </div>
  );
};

export default AgentCancellations;
