import { API, Auth } from "aws-amplify";
import { getApiRequestInfo } from "../../utils";
import { buildDashboardUrl } from "../buildDashboardUrl/buildDashboardUrl";
import { adjustYears } from "../../../components/IncomeYearCompare/utils";

interface fetchMonthlySummaryData {
  fiscalStart: string;
  startDate: Date;
  agentCode: string;
  selectedDate: Date;
  state: string;
  solicitorCode: string;
  territoryCode: string;
  oneYearFromFiscalStart?: boolean;
}

export async function fetchMonthlySummaryData({
  fiscalStart,
  startDate,
  agentCode,
  state,
  solicitorCode,
  territoryCode,
  oneYearFromFiscalStart,
}: fetchMonthlySummaryData): Promise<any> {
  const requestInfo = await getApiRequestInfo(null);

  const fiscalStartYearMonth = fiscalStart.slice(0, 6);
  const endYear = startDate.getFullYear();
  const endMonth = (startDate.getMonth() + 1).toString().padStart(2, "0");
  let endYearMonth = `${endYear}${endMonth}`;

  if (oneYearFromFiscalStart)
    endYearMonth = adjustYears(fiscalStart, 1).slice(0, -2);

  let url =
    "/monthlysummarydata?start_year_month=" +
    fiscalStartYearMonth +
    "&end_year_month=" +
    endYearMonth;

  try {
    return await API.get(
      "idpro",
      buildDashboardUrl(url, agentCode, state, solicitorCode,territoryCode),
      requestInfo,
    );
  } catch (error) {
    console.error("error: " + JSON.stringify(error));
    throw new Error("Failed to fetch Income data");
  }

  return null;
}
