import { BudgetDataPayload } from "../../pages/pfc_dashboard/actual_v_plan_segment/utils";
import { capitalize } from "@mui/material";
import { AvPSegmentChartProps } from "./AvPSegmentChart";

type SegmentData = {
  segment: string;
  months: Array<{
    month: number;
    values: {
      [key: string]: number | string;
    };
    year: number;
  }>;
};

type ProcessedData = {
  [segment: string]: number[];
};

export const makeSegmentBudgetValueLine = (
  data: BudgetDataPayload,
  segmentValue: string,
) => {
  const aggregatedData = processPayloadForGraph(data);

  return {
    type: "line",
    name: `Budget`,
    data: aggregatedData[segmentValue],
  };
};

type GraphData = {
  [key: string]: number[];
};

export function processPayloadForGraph(payload: BudgetDataPayload): GraphData {
  const graphData: GraphData = {};

  // Initialize the graph data structure with budget category keys and empty arrays
  payload?.months.forEach((month) => {
    Object.keys(month).forEach((category) => {
      // Exclude 'setup_fee' from category names if not needed
      if (!graphData[category]) {
        graphData[category] = [];
      }
    });
  });

  // Populate the arrays with monthly totals
  payload?.months.forEach((month) => {
    Object.entries(month).forEach(([category, { total }]) => {
      // Exclude 'setup_fee' from category names if not needed
      graphData[category].push(total);
    });
  });

  return graphData;
}

export function processSegmentData(
  data: SegmentData[],
  valueKey: string,
): ProcessedData {
  const processedData: ProcessedData = {};

  data?.forEach((item) => {
    const segment = item.segment;
    const valuesArray: number[] = [];

    item.months?.forEach((month) => {
      const value = month.values[valueKey];
      if (value) {
        // Ensures only numeric values are considered
        valuesArray.push(Number(value));
      }
    });

    processedData[segment] = valuesArray;
  });

  return processedData;
}

export const convertToBarChartData = (data: ProcessedData) => {
  return Object?.entries(data)?.map(([segment, values]) => {
    return {
      name: capitalize(segment),
      type: "bar",
      data: values,
    };
  });
};

export const makeChartData = (
  data: BudgetDataPayload,
  segmentValue: string,
  monthlyData: SegmentData[],
) => {
  return {
    barChatData: [
      makeSegmentBudgetValueLine(data, segmentValue),
      ...convertToBarChartData(processSegmentData(monthlyData, segmentValue)),
    ],
  };
};

export const assessData = (data: AvPSegmentChartProps) => {
  const { budgetData, monthlyData, labels } = data;
  if (!budgetData || !monthlyData || !labels) {
    return false;
  }

  if (budgetData?.months?.length === 0) {
    return false;
  }

  return true;
};
