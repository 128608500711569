import React from "react";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Flex,
  Card,
  Placeholder,
} from "@aws-amplify/ui-react";
import { useState, useEffect } from "react";
import { Auth, API } from 'aws-amplify';
import dayjs from 'dayjs';

import "./Dashboard.css";

import { DollarValue } from "../../utils";
import { getApiRequestInfo } from "../../api/utils";
import { buildDashboardUrl } from "../../api/utils/buildDashboardUrl/buildDashboardUrl";
function QuoteTable({ startDate, agentCode, state, solicitorCode, territoryCode}) {

  //  console.log("in QuoteTable, startDate: " + startDate + ", agentCode: " + agentCode);

    const [apiData, setApiData] = React.useState("");
    const [dayPremium, setDayPremium] = useState(0);
    const [weekPremium, setWeekPremium] = useState(0);
    const [monthPremium, setMonthPremium] = useState(0);
    const [yearPremium, setYearPremium] = useState(0);

    // api call to get quote numbers
    useEffect(() => {
      const getQuoteData = async () => {
        const requestInfo = await getApiRequestInfo(null);

        var url = "/quotesummarydata/" + dayjs(startDate).format('YYYYMMDD');
        url = buildDashboardUrl(url, agentCode, state, solicitorCode, territoryCode);
//console.log("READY TO CALL API: " + url);

        API.get("idpro", url, requestInfo)
        .then(response => {
          setDayPremium(response.day_quote.premium + response.day_quote.policy_fee + response.day_quote.tax_stamp_fee + response.day_quote.broker_fee  + response.day_quote.inspection_fee);
          setWeekPremium(response.week_quote.premium + response.week_quote.policy_fee + response.week_quote.tax_stamp_fee + response.week_quote.broker_fee + response.week_quote.inspection_fee);
          setMonthPremium(response.month_quote.premium + response.month_quote.policy_fee + response.month_quote.tax_stamp_fee + response.month_quote.broker_fee + response.month_quote.inspection_fee);
          setYearPremium(response.year_quote.premium + response.year_quote.policy_fee + response.year_quote.tax_stamp_fee + response.year_quote.broker_fee + response.year_quote.inspection_fee);
          setApiData(response);
        }).catch(error => {
          console.log("error: " + JSON.stringify(error));
          if( error.response && error.response.status === 404 ) {
            console.log("404 error");
          }
        });
  
      }
      getQuoteData();
    }, [startDate, agentCode, state, solicitorCode]);


  return (
    <>
    {apiData ? (
      <Table caption="" highlightOnHover={true}>
        <TableHead>
          <TableRow>
            <TableCell as="th">&nbsp;</TableCell>
            <TableCell as="th" className='money'>Count</TableCell>

            <TableCell as="th" className='money'>Premium</TableCell>

          </TableRow>
        </TableHead>

        <TableBody>
            <TableRow>
                <TableCell className='rowheader'>Day</TableCell>
                <TableCell className='money'>{apiData.day_quote.count}</TableCell>
                <TableCell className='money'><DollarValue value={dayPremium}/></TableCell>

            </TableRow>
            <TableRow>
                <TableCell className='rowheader'>Week</TableCell>
                <TableCell className='money'>{apiData.week_quote.count}</TableCell>
                <TableCell className='money'><DollarValue value={weekPremium}/></TableCell>

            </TableRow>
            <TableRow>
                <TableCell className='rowheader'>Month</TableCell>
                <TableCell className='money'>{apiData.month_quote.count}</TableCell>
                <TableCell className='money'><DollarValue value={monthPremium}/></TableCell>

            </TableRow>
            <TableRow>
                <TableCell className='rowheader'>Year</TableCell>
                <TableCell className='money'>{apiData.year_quote.count}</TableCell>
                <TableCell className='money'><DollarValue value={yearPremium}/></TableCell>
            </TableRow>
        </TableBody>
      </Table>
      ) : (
                  <Flex direction="column" minHeight="285px">
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                  </Flex>
                )}
    </>
  );
};
export default QuoteTable;
