import { styled } from "styled-components";

export const StyledHeader = styled.div`
  background-color: #fff;
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  min-height: 3.5rem;
  padding: 0 1.25rem;

  @media (max-width: 1024px) {
    padding: 0 1.25rem 0 4.375rem;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  gap: 0.625rem;
  font-size: 16px;
  font-weight: 400; 
  color: #777; 
  font-family: 'Roboto', sans-serif; 
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 0.688rem;
    font-size: 20px;
    font-weight: 500; /* Make the font weight slightly bold */
    color: #555; /* A modern grey color */
    font-family: 'Roboto', sans-serif; /* A modern sans-serif font */
  }
  span.customer-name {
    font-weight: 400; 
    font-size: 16px;
    color: #666; /* A lighter grey color for contrast */
    font-family: 'Roboto', sans-serif; /* Same font family for consistency */
  }
`;

export const HeaderLogo = styled.div`
  width: 1.875rem;
  height: 1.875rem;

  img {
    height: auto;
    width: 100%;
  }
`;
