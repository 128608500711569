export const formatOptionDisplay = (input: string): string => {
  // Replace all underscores with spaces
  let formattedString = input.replace(/_/g, " ");

  // Capitalize the first letter of the string
  formattedString =
    formattedString.charAt(0).toUpperCase() + formattedString.slice(1);

  return formattedString;
};
