import React from "react";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow
} from "@aws-amplify/ui-react";

import "./Dashboard.css";

import { DollarValue } from "../../utils";

function handlePlanHover(event, month_premium, monthPremiumPlan, percent_complete) {
  const projected = month_premium / (percent_complete/100);
  event.target.setAttribute('title', `Projected: $${projected.toLocaleString('en-US', {maximumFractionDigits: 2})}`);
  if (projected > monthPremiumPlan) {
    event.target.style.setProperty('color', 'green');
  } else {
    event.target.style.setProperty('color', 'red');
  }
}
function IncomeTable({ data, planData, monthPlanDataIndex, percentMonthComplete, startDate, clientId }) {

  var yPremiumPlan=0, yAmountFinancedPlan=0, yNumberContractsPlan=0, yInterestPlan=0, ySetupFeePlan=0, yLateFeePlan=0, yCancelFeePlan=0, yNSFFeePlan=0, yVSCFeePlan=0, yTotalIncomePlan=0;
  var monthPremiumPlan, monthAmountFinancedPlan, monthInterestPlan, monthSetupFeePlan, monthLateFeePlan, monthCancelFeePlan, monthNSFFeePlan, monthVSCFeePlan, monthTotalIncomePlan;


 if( planData.months) {

  var monthPremiumPlan = planData.months?.[monthPlanDataIndex].premium.total ?? 0;
  var monthAmountFinancedPlan = planData.months[monthPlanDataIndex].amount_financed.total ?? 0;
  var monthInterestPlan = planData.months[monthPlanDataIndex].interest.total ?? 0;
  var monthSetupFeePlan = planData.months[monthPlanDataIndex].setup_fee.total ?? 0;
  var monthLateFeePlan = planData.months[monthPlanDataIndex].late_fee.total ?? 0;
  var monthCancelFeePlan =  planData.months[monthPlanDataIndex].cancel_fee.total ?? 0;
  var monthNSFFeePlan = planData.months[monthPlanDataIndex].nsf_fee.total ?? 0;
  var monthVSCFeePlan = planData.months[monthPlanDataIndex].vsc_fee?.total ?? 0;
  var monthTotalIncomePlan = monthInterestPlan + monthSetupFeePlan + monthLateFeePlan + monthCancelFeePlan + monthNSFFeePlan + monthVSCFeePlan;


  for( var i=0; i <= monthPlanDataIndex; i++ ) {
    yPremiumPlan += (planData.months[i].premium.total ?? 0);
    yAmountFinancedPlan += (planData.months[i].amount_financed.total ?? 0);
    yInterestPlan += (planData.months[i].interest.total ?? 0);
    ySetupFeePlan += (planData.months[i].setup_fee.total ?? 0);
    yLateFeePlan += (planData.months[i].late_fee.total ?? 0);
    yCancelFeePlan += (planData.months[i].cancel_fee.total ?? 0);
    yNSFFeePlan += (planData.months[i].nsf_fee.total ?? 0);
    yVSCFeePlan += (planData.months[i].vsc_fee?.total ?? 0);
  }
  yTotalIncomePlan = yInterestPlan + ySetupFeePlan + yLateFeePlan + yCancelFeePlan + yNSFFeePlan + yVSCFeePlan;

 }

    const year_premium = (data.year_premium?.premium ?? 0) +
        (data.year_premium?.inspection_fee ?? 0) +
        (data.year_premium?.policy_fee ?? 0) +
        (data.year_premium?.tax_stamp_fee ?? 0) +
        (data.year_premium?.broker_fee ?? 0);
    const month_premium = (data.month_premium?.premium ?? 0) +
        (data.month_premium?.inspection_fee ?? 0) +
        (data.month_premium?.policy_fee ?? 0) +
        (data.month_premium?.tax_stamp_fee ?? 0) +
        (data.month_premium?.broker_fee ?? 0);
    const day_premium = (data.day_premium?.premium ?? 0) +
        (data.day_premium?.inspection_fee ?? 0) +
        (data.day_premium?.policy_fee ?? 0) +
        (data.day_premium?.tax_stamp_fee ?? 0) +
        (data.day_premium?.broker_fee ?? 0);
    const week_premium = (data.week_premium?.premium ?? 0) +
        (data.week_premium?.inspection_fee ?? 0) +
        (data.week_premium?.policy_fee ?? 0) +
        (data.week_premium?.tax_stamp_fee ?? 0) +
        (data.week_premium?.broker_fee ?? 0);

   const monthAmountFinanced = data.month_income?.find(item => item.receivable_type === 'amount_financed')?.amount ?? 0;
   const yearAmountFinanced = data.year_income?.find(item => item.receivable_type === 'amount_financed')?.amount ?? 0;
   const dayAmountFinanced = data.day_income?.find(item => item.receivable_type === 'amount_financed')?.amount ?? 0;
   const weekAmountFinanced = data.week_income?.find(item => item.receivable_type === 'amount_financed')?.amount ?? 0;

    const monthInterest = data.month_income?.find(item => item.receivable_type === 'interest')?.amount ?? 0;
    const yearInterest = data.year_income?.find(item => item.receivable_type === 'interest')?.amount ?? 0;
    const dayInterest = data.day_income?.find(item => item.receivable_type === 'interest')?.amount ?? 0;
    const weekInterest = data.week_income?.find(item => item.receivable_type === 'interest')?.amount ?? 0;

    const monthSetupFee = data.month_income?.find(item => item.receivable_type === 'setup_fee')?.amount ?? 0;
    const yearSetupFee = data.year_income?.find(item => item.receivable_type === 'setup_fee')?.amount ?? 0;
    const daySetupFee = data.day_income?.find(item => item.receivable_type === 'setup_fee')?.amount ?? 0;
    const weekSetupFee = data.week_income?.find(item => item.receivable_type === 'setup_fee')?.amount ?? 0;

    const monthLateFee = data.month_income?.find(item => item.receivable_type === 'late_fee')?.amount ?? 0;
    const yearLateFee = data.year_income?.find(item => item.receivable_type === 'late_fee')?.amount ?? 0;
    const dayLateFee = data.day_income?.find(item => item.receivable_type === 'late_fee')?.amount ?? 0;
    const weekLateFee = data.week_income?.find(item => item.receivable_type === 'late_fee')?.amount ?? 0;

    const monthCancelFee = data.month_income?.find(item => item.receivable_type === 'cancel_fee')?.amount ?? 0;
    const yearCancelFee = data.year_income?.find(item => item.receivable_type === 'cancel_fee')?.amount ?? 0;
    const dayCancelFee = data.day_income?.find(item => item.receivable_type === 'cancel_fee')?.amount ?? 0;
    const weekCancelFee = data.week_income?.find(item => item.receivable_type === 'cancel_fee')?.amount ?? 0;

    const monthNSFFee = data.month_income?.find(item => item.receivable_type === 'nsf_fee')?.amount ?? 0;
    const yearNSFFee = data.year_income?.find(item => item.receivable_type === 'nsf_fee')?.amount ?? 0;
    const dayNSFFee = data.day_income?.find(item => item.receivable_type === 'nsf_fee')?.amount ?? 0;
    const weekNSFFee = data.week_income?.find(item => item.receivable_type === 'nsf_fee')?.amount ?? 0;

      // HCSNOTE:  VSC Fee is SIU specific.  In the future, I think we should pass in an array of fee name strings to this function and then
      //    it can iterate over the names to dynamically create the table below.  That way, the caller can pass in any specific custom fee names rather than us doing IFs in here
    const monthVSCFee = data.month_income?.find(item => item.receivable_type === 'vsc_fee')?.amount ?? 0;
    const yearVSCFee = data.year_income?.find(item => item.receivable_type === 'vsc_fee')?.amount ?? 0;
    const dayVSCFee = data.day_income?.find(item => item.receivable_type === 'vsc_fee')?.amount ?? 0;
    const weekVSCFee = data.week_income?.find(item => item.receivable_type === 'vsc_fee')?.amount ?? 0;

    const monthTotalIncome =  monthInterest + monthSetupFee + monthLateFee + monthCancelFee + monthNSFFee + monthVSCFee;
    const yearTotalIncome =  yearInterest + yearSetupFee + yearLateFee + yearCancelFee + yearNSFFee + yearVSCFee;
    const dayTotalIncome =  dayInterest + daySetupFee + dayLateFee + dayCancelFee + dayNSFFee + dayVSCFee;
    const weekTotalIncome =  weekInterest + weekSetupFee + weekLateFee + weekCancelFee + weekNSFFee + weekVSCFee;


    const GoalValue = ({ value }) => {
      if (value && value > 0) {
        return (
          <>
            <br />
            <span className="goal-value" title="Goal">
              <DollarValue value={value} color='gray'/>
            </span>
          </>
        );
      } else {
        return (
          <>
            <br />
            <span className="goal-value-empty">&nbsp;</span> {/* Adds an invisible space for alignment */}
          </>
        );
      }
    };
    
    
  return (
    <>
 
      <Table caption="" highlightOnHover={true} size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell as="th">&nbsp;</TableCell>
            <TableCell as="th" className='money'>Day</TableCell>
            <TableCell as="th" className='money'>Week</TableCell>
            <TableCell as="th" className='money'>Month To Date</TableCell>
            <TableCell as="th" className='money'>Year To Date</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell className='rowheader'>Policy Premium</TableCell>
            <TableCell className='money'><DollarValue value={day_premium}/></TableCell>
            <TableCell className='money'><DollarValue value={week_premium}/></TableCell>
            <TableCell className='money'>
              <DollarValue value={month_premium}/>
              <GoalValue value={monthPremiumPlan} />
            </TableCell>
            <TableCell className='money'><DollarValue value={year_premium}/><GoalValue value={yPremiumPlan} /></TableCell>
          </TableRow>

          <TableRow>
            <TableCell className='rowheader'>Amount Financed</TableCell>
            <TableCell className='money'><DollarValue value={dayAmountFinanced}/></TableCell>
            <TableCell className='money'><DollarValue value={weekAmountFinanced}/></TableCell>
            <TableCell className='money'><DollarValue value={monthAmountFinanced}/>
              <GoalValue value={monthAmountFinancedPlan} />
            </TableCell>
            <TableCell className='money'><DollarValue value={yearAmountFinanced}/><GoalValue value={yAmountFinancedPlan} /></TableCell>
          </TableRow>

          <TableRow>
            <TableCell className='rowheader'>Booked Interest Income</TableCell>
            <TableCell className='money'><DollarValue value={dayInterest}/></TableCell>
            <TableCell className='money'><DollarValue value={weekInterest}/></TableCell>
            <TableCell className='money'><DollarValue value={monthInterest}/>
              <GoalValue value={monthInterestPlan} />
            </TableCell>
            <TableCell className='money'><DollarValue value={yearInterest}/><GoalValue value={yInterestPlan} /></TableCell>
          </TableRow>

          <TableRow>
            <TableCell className='rowheader'>Setup Fee Income</TableCell>
            <TableCell className='money'><DollarValue value={daySetupFee}/></TableCell>
            <TableCell className='money'><DollarValue value={weekSetupFee}/></TableCell>
            <TableCell className='money'><DollarValue value={monthSetupFee}/>
              <GoalValue value={monthSetupFeePlan} />
            </TableCell>
            <TableCell className='money'><DollarValue value={yearSetupFee}/><GoalValue value={ySetupFeePlan} /></TableCell>
          </TableRow>

          <TableRow>
            <TableCell className='rowheader'>Late Fee Income</TableCell>
            <TableCell className='money'><DollarValue value={dayLateFee}/></TableCell>
            <TableCell className='money'><DollarValue value={weekLateFee}/></TableCell>
            <TableCell className='money'><DollarValue value={monthLateFee}/>
              <GoalValue value={monthLateFeePlan} />
            </TableCell>
            <TableCell className='money'><DollarValue value={yearLateFee}/><GoalValue value={yLateFeePlan} /></TableCell>
          </TableRow>

          <TableRow>
            <TableCell className='rowheader'>Cancel Fee Income</TableCell>
            <TableCell className='money'><DollarValue value={dayCancelFee}/></TableCell>
            <TableCell className='money'><DollarValue value={weekCancelFee}/></TableCell>
            <TableCell className='money'><DollarValue value={monthCancelFee}/>
              <GoalValue value={monthCancelFeePlan} />
            </TableCell>
            <TableCell className='money'><DollarValue value={yearCancelFee}/><GoalValue value={yCancelFeePlan} /></TableCell>
          </TableRow>

          <TableRow>
            <TableCell className='rowheader'>NSF Fee Income</TableCell>
            <TableCell className='money'><DollarValue value={dayNSFFee}/></TableCell>
            <TableCell className='money'><DollarValue value={weekNSFFee}/></TableCell>
            <TableCell className='money'><DollarValue value={monthNSFFee}/>
              <GoalValue value={monthNSFFeePlan} />
            </TableCell>
            <TableCell className='money'><DollarValue value={yearNSFFee}/><GoalValue value={yNSFFeePlan} /></TableCell>
          </TableRow>

          {clientId === '0001' && (
            <TableRow>
              <TableCell className='rowheader'>VSC Fee Income</TableCell>
              <TableCell className='money'><DollarValue value={dayVSCFee}/></TableCell>
              <TableCell className='money'><DollarValue value={weekVSCFee}/></TableCell>
              <TableCell className='money'><DollarValue value={monthVSCFee}/>
                <GoalValue value={monthVSCFeePlan} />
              </TableCell>
              <TableCell className='money'><DollarValue value={yearVSCFee}/><GoalValue value={yVSCFeePlan} /></TableCell>
            </TableRow>
          )}

          <TableRow>
            <TableCell className='rowheader'>Total Income</TableCell>
            <TableCell className='money-total'><DollarValue value={dayTotalIncome}/></TableCell>
            <TableCell className='money-total'><DollarValue value={weekTotalIncome}/></TableCell>
            <TableCell className='money-total'><DollarValue value={monthTotalIncome}/>
              <GoalValue value={monthTotalIncomePlan} />
            </TableCell>
            <TableCell className='money-total'><DollarValue value={yearTotalIncome}/>
              <GoalValue value={yTotalIncomePlan} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

    </>
  );
};
export default IncomeTable;
