import { styled } from "styled-components";

export const HeaderAvatar = styled.div`
  img {
    width: 2.125rem;
    height: 2.125rem;
  }
`;

export const StyledUserIndicatorText = styled.p`
  white-space: nowrap;
  text-overflow: ellipsis;
`;
