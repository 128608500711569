import { useEffect } from 'react';

const useActivityMonitor = (onActivity) => {
    useEffect(() => {
        const handleActivity = () => {
            onActivity();
        };

        // window.addEventListener('mousemove', handleActivity);
        document.addEventListener('keydown', handleActivity);
        document.addEventListener('click', handleActivity);

        return () => {
            // window.removeEventListener('mousemove', handleActivity);
            document.removeEventListener('keydown', handleActivity);
            document.removeEventListener('click', handleActivity);
        };
    }, [onActivity]);
};

export default useActivityMonitor;
