import React, { createContext, useEffect, useState } from 'react';
import { useAuthStatus } from "./hooks/useAuthStatus/useAuthStatus";
import { postUserAuthLogging } from './api/utils/postUserAuthLogging/postUserAuthLogging';

export const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [isMFASetupRequired, setIsMFASetupRequired] = useState(true);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [superAdminClientId, setSuperAdminClientId] = useState(null);
  const [clientType, setClientType] = useState(""); // Add clientType state
  const [clientId, setClientId] = useState(null);

  const { isAuthenticated } = useAuthStatus();

  useEffect(() => {
    // Enforces MFA setup for all users
    if (!isAuthenticated) {
      setIsMFASetupRequired(true);
    } else {
      postUserAuthLogging(); // Logs datetime and IP of login event
    }
  }, [isAuthenticated]);

  const removeLocalStorage = () => {
    localStorage.removeItem('chatHistoryId');
  }

  const onLogout = () => {
    setIsMFASetupRequired(true);
    setIsSuperAdmin(false);
    setIsAdmin(false);
    setCurrentUser(null);
    setSuperAdminClientId(null);
    setClientType(""); // Reset clientType on logout
    setClientId("");  // Reset clientId on logout
    removeLocalStorage();
  }

  return (
    <StateContext.Provider
      value={{
        isMFASetupRequired,
        setIsMFASetupRequired,
        isSuperAdmin,
        setIsSuperAdmin,
        isAdmin,
        setIsAdmin,
        currentUser,
        setCurrentUser,
        superAdminClientId,
        setSuperAdminClientId,
        clientType, // Add clientType to context
        setClientType, // Function to update clientType
        clientId,
        setClientId,
        onLogout,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
