import React, {useState} from 'react';
import { CircularProgress, Box } from '@mui/material';
import MFAErrorMsg from './MFAErrorMsg';
import MFASuccessMsg from './MFASuccessMsg';
import { Auth } from 'aws-amplify';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const MFASMSView = ({
  currentUser,
  handleGoBack,
  handleSignOut,
  setIsSetupSuccessful,
  setPreferredMFA,
  setPhoneToVerified
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');

  const [cannotUpdatePhoneNumber, setCannotUpdatePhoneNumber] = useState(null);
  const [cannotVerifyPhoneNumber, setCannotVerifyPhoneNumber] = useState(null);

  const [showUpdatePhoneNumberMsg, setShowUpdatePhoneNumberMsg] = useState(true);
  const [showVerificationStatusMsg, setShowVerificationStatusMsg] = useState(true);

  const [isUpdatingPhoneNumber, setIsUpdatingPhoneNumber] = useState(false);
  const [isVerifyingPhoneNumber, setIsVerifyingPhoneNumber] = useState(false);

  const updatePhoneNumber = async (phoneNumber) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user.attributes.phone_number !== phoneNumber) {
        const result = await Auth.updateUserAttributes(user, {
          'phone_number': phoneNumber // Format: +12345678900
        });
        if (result === 'SUCCESS') {
          console.log('result is SUCCESS');
          setCannotUpdatePhoneNumber(false);
        } else {
          console.log('updatePhoneNumber failed: ', result);
          setCannotUpdatePhoneNumber(true);
        }
      }
    } catch (error) {
      console.error('Error updating phone number:', error);
      setCannotUpdatePhoneNumber(true); // Setting the state to true in case of an error
    }
  }

  const handlePhoneNumberSubmit = async () => {
    setShowUpdatePhoneNumberMsg(true);
    setCannotUpdatePhoneNumber(false);
    setIsUpdatingPhoneNumber(true); // Set loading state to true
    await updatePhoneNumber(phoneNumber);
    setIsUpdatingPhoneNumber(false); // Set loading state to false after the request is fulfilled
  };

  const verifyPhoneNumber = async (verificationCode) => {
    try {
      if (currentUser.attributes.phone_number_verified !== true) {
        const result = await Auth.verifyCurrentUserAttributeSubmit('phone_number', verificationCode);
        if (result === 'SUCCESS') {
          setCannotVerifyPhoneNumber(false); // Resetting the state in case of successful verification

          setPhoneToVerified.current = true;

          await setPreferredMFA('SMS');
          setIsSetupSuccessful(true);
        } else {
          setCannotVerifyPhoneNumber(true);
        }
      } else {
        console.log('Phone number already verified');
      }
    } catch (error) {
      console.error('Error verifying phone number:', error);
      setCannotVerifyPhoneNumber(true); // Setting the state to true in case of an error
    }
  };

  const handleVerificationCodeSubmit = async () => {
    console.log('handleVerificationCodeSubmit');
    setIsVerifyingPhoneNumber(true); // Set loading state to true
    await verifyPhoneNumber(verificationCode);
    setIsVerifyingPhoneNumber(false); // Set loading state to false after the request is fulfilled
  };

  const EmptyFlagComponent = () => null;

  return (
    <div className="amplify-flex" style={{ flexDirection: 'column', gap: '1rem' }}>
      <h3 className="amplify-heading amplify-heading--3">PFCInsight SMS Setup</h3>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>


      <p className="amplify-form__help-text">By opting in to receive SMS text messages from 2iG Solutions for the PFCInsight application, you agree to
      receive login verification codes via text message to the phone number you entered above each time you log into the application.  Message and data rates may apply.
      You may stop these messages at any time by changing your login settings to use another method via the settings menu in PFCInsight.</p>
      <a href="www.2igsolutions.com/terms" className="amplify-form__help-text" style={{ textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">Click here for our terms of service</a>
      <a href="www.2igsolutions.com/privacy" className="amplify-form__help-text" style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">Click here for our privacy notice</a>
      <p className="amplify-form__help-text">By clicking "Submit Phone Number", you agree to the terms of service, privacy notice, and potential SMS charges.</p>
      <PhoneInput
          className="number amplify-flex"
          country={"us"}
          value={phoneNumber}
          onChange={(value) => {
            value = '+' + value.replace(/\s+/g, '');
            setPhoneNumber(value);
          }}
          inputStyle={{
            width: '100%',
            height: '45px',
            fontSize: '1rem',
          }}
        />
      <button
        className="amplify-button amplify-button--primary"
        onClick={handlePhoneNumberSubmit}
      >
        {isUpdatingPhoneNumber ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          'Submit Phone Number'
        )}
      </button>


      {showUpdatePhoneNumberMsg && cannotUpdatePhoneNumber != null && (
        cannotUpdatePhoneNumber === true ? (
          <MFAErrorMsg
            message="Problem with phone number. Make sure it's in the correct format: +123456789000"
            setShowMsg={setShowUpdatePhoneNumberMsg}
          />
        ) : (
          <MFASuccessMsg
            message="You should receive a text message with a verification code to enter below."
            setShowMsg={setShowUpdatePhoneNumberMsg}
          />
        )
      )}

      <input
        className="amplify-input"
        type="text"
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
        placeholder="Enter the SMS verification code you received"
      />
      <button
        className="amplify-button amplify-button--primary"
        onClick={handleVerificationCodeSubmit}
      >
        {isVerifyingPhoneNumber ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          'Submit Verification Code'
        )}
      </button>

      {showVerificationStatusMsg && cannotVerifyPhoneNumber && (
        <MFAErrorMsg message="Failed to verify the SMS code. Please try again." setShowMsg={setShowVerificationStatusMsg}/>
      )}

      <div
        className="amplify-flex justify-center items-center"
        style={{ justifyContent: 'center', alignItems: 'center' }}
      >
        <button
          className="amplify-button amplify-field-group__control amplify-button--link amplify-button--small"
          onClick={handleGoBack}
        >
          Change MFA method
        </button>

        <button
          className="amplify-button amplify-field-group__control amplify-button--link amplify-button--small"
          onClick={handleSignOut}
        >
          Back to Sign In
        </button>
      </div>
      </Box>
    </div>
  );
};

export default MFASMSView;
