import styled from "styled-components";
import { Grid } from "@mui/material";

export const StyledGrid = styled(Grid)`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
`;

export const StyledFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;

  & > div:first-child {
    //border: solid 1px red;
    text-align: left;
  }

  & > div {
    width: 100%;
  }
`;
