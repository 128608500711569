import React, { useEffect, useState, useRef, MouseEvent } from "react";
import { AdvancedMarker, useMap, Pin } from "@vis.gl/react-google-maps";
import { Marker, MarkerClusterer } from "@googlemaps/markerclusterer";
import CustomAdvancedMarker from "./CustomAdvancedMarker";
import { Agent } from "./types";

type Props = { agents: Agent[] };

const AgentMarkers: React.FC<Props> = ({ agents }) => {
  const map = useMap();
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const clusterer = useRef<MarkerClusterer | null>(null);

  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map });
    }
  }, [map]);

  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker: Marker | null, agentId: string) => {
    if (marker && markers[agentId]) return;
    if (!marker && !markers[agentId]) return;

    setMarkers((prev) => {
      if (marker) {
        return { ...prev, [agentId]: marker };
      } else {
        const newMarkers = { ...prev };
        delete newMarkers[agentId];
        return newMarkers;
      }
    });
  };
  return (
    <>       
      {agents.map((agent) => (
        <CustomAdvancedMarker
          key={agent.agent_id}
          position={{ lat: agent.lat, lng: agent.lng }}
          agent={agent}
          setMarkerRef={setMarkerRef} // Pass setMarkerRef to CustomAdvancedMarker
        />
      ))}
    </>
  );
};

export default AgentMarkers;
