import { API } from "aws-amplify";
import { getApiRequestInfo } from "../../utils";
import dayjs from "dayjs";
import {buildDashboardUrl} from "../buildDashboardUrl/buildDashboardUrl";

interface fetchIncomeDataArgs {
  selectedDate: Date;
  selectedAgent: string;
  selectedState: string;
  selectedSalesExec: string;
  selectedTerritory: string;
}

export async function fetchIncomeData({
  selectedDate,
  selectedAgent,
  selectedState,
  selectedSalesExec,
  selectedTerritory,
}: fetchIncomeDataArgs): Promise<any> {
  const requestInfo = await getApiRequestInfo(null);
  const intermediateUrl = "/incomedata/" + dayjs(selectedDate).format("YYYYMMDD");
  const   url = buildDashboardUrl(intermediateUrl, selectedAgent, selectedState, selectedSalesExec, selectedTerritory);

  try {
    return await API.get("idpro", url, requestInfo);
  } catch (error) {
    console.error("error: " + JSON.stringify(error));
    throw new Error("Failed to fetch Income data");
  }

  return null;
}
