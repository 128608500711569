import React, { useEffect, useState, useRef } from 'react';
import { API } from 'aws-amplify';
import SortableTable from '../../../components/Table/SortableTable';
import { getApiRequestInfo } from '../../../api/utils';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CircularProgressWrapper, StyledCircularProgress, StyledHeading } from "./styles";

export const EquityReport = () => {
  const apiChunkSize = 5000;
  const isComplete = useRef(false);
  const [policyIncludingData, setPolicyIncludingData] = useState([]);
  const [policyExcludingData, setPolicyExcludingData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPolicies, setShowPolicies] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState("");
  
  useEffect(() => {
    const fetchEquityDataMock = async () => {
      // Simulate API call delay
      await new Promise(resolve => setTimeout(resolve, 1000));
    
      // Hardcoded data
      const originalData = [
          {
            "account_number": "0001-9858583",
            "policy_number": "NSD8757",
            "account_status": 5,
            "coverage_type": "ADDITIONAL COVERAGE (RAP)",
            "carrier_name": "0001-8812",
            "principal_balance": 225.0000,
            "interest_balance": 0.0000,
            "policy_based_fee_balance": 0.0000,
            "account_based_fee_balance": 0.0000,
            "policy_cancellation_date": "2024-03-01T00:00:00",
            "policy_calc_cancellation_date": "2024-03-01T00:00:00",
            "account_cancellation_date": null,
            "account_calc_cancellation_date": "2024-03-01T00:00:00",
            "calc_total_expected_return_premium": 2076.0000,
            "calc_total_equity": -1022.0000,
            "calc_total_equity_principal_interest": 2076.0000,
            "calc_total_equity_principal_only": 1791.0000,
            "policy_calc_equity_notes": "notes....",
            "account_calc_equity_notes": "account notes...."
          },
          {
            "account_number": "0001-9858584",
            "policy_number": "NSD8758",
            "account_status": "intent to cancel",
            "coverage_type": "ADDITIONAL COVERAGE (RAP2)",
            "carrier_name": "0001-8813",
            "principal_balance": 225.0000,
            "interest_balance": 0.0000,
            "policy_based_fee_balance": 0.0000,
            "account_based_fee_balance": 0.0000,
            "policy_cancellation_date": null,
            "policy_calc_cancellation_date": "2024-03-02T00:00:00",
            "account_cancellation_date": "2024-03-02T00:00:00",
            "account_calc_cancellation_date": "2024-03-02T00:00:00",
            "calc_total_expected_return_premium": 2076.0000,
            "calc_total_equity": -1.0000,
            "calc_total_equity_principal_interest": 2076.0000,
            "calc_total_equity_principal_only": 1791.0000,
            "policy_calc_equity_notes": "notes....",
            "account_calc_equity_notes": "account notes...."
          }
      ];

      // Define how many times you want to repeat the data
      const timesToRepeat = 10;

      // Extend the array by duplicating the elements
      const duplicatedData = Array.from({ length: timesToRepeat }, () => originalData).flat();

      // Use the extended data
      const mockData = {
        items: duplicatedData,
        isComplete: false
      };
    
      setPolicyIncludingData(mockData.items);
      transformAndSetPolicyExcludingData(mockData.items);
      setIsLoading(false);
    };

    const fetchEquityData = async (offset = 0, accumulatedData = []) => {
      const requestInfo = await getApiRequestInfo(null);
      const url = `/equityreport?nRows=${apiChunkSize}&offset=${offset}`;
    
      try {
        const fetchedData = await API.get("idpro", url, requestInfo);
        console.log("API fetchedData length: \n", fetchedData.items.length, fetchedData.items[0]);
    
        // Append new data to accumulated data
        const updatedAccumulatedData = [...accumulatedData, ...fetchedData.items];
    
        // Update state with the currently accumulated data
        setPolicyIncludingData(updatedAccumulatedData);
        if (!fetchedData.isComplete) {
          // Continue fetching next chunk with updated accumulated data
          fetchEquityData(offset + apiChunkSize, updatedAccumulatedData);
        } else {
          // All data has been fetched, transform and set policy excluding data
          transformAndSetPolicyExcludingData(updatedAccumulatedData);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching equity data:", error);
        setIsLoading(false);
      }
    };
  
    setIsLoading(true);
    fetchEquityData();
    // fetchEquityDataMock();
  }, []);

  const transformAndSetPolicyExcludingData = (data) => {
    const aggregatedData = data.reduce((acc, cur) => {
      // Check if the current item is the same as the last item in the accumulator
      const lastItem = acc[acc.length - 1];
      if (!lastItem || lastItem.account_number !== cur.account_number) {
        acc.push({ ...cur });
      } 
      return acc;
    }, []);
    setPolicyExcludingData(aggregatedData);
  };
  
  useEffect(() => {
    setDisplayData(showPolicies ? policyIncludingData : policyExcludingData);
  }, [showPolicies, policyIncludingData, policyExcludingData]);

  const updateSelectedColumn = (currentSelectedColumn) => {
    const selectableColumnsShowPoliciesTransitions = {
      "Coverage": "Coverage(s)",
      "Coverage(s)": "Coverage",
    }
    if (selectableColumnsShowPoliciesTransitions[currentSelectedColumn]) {
      setSelectedColumn(selectableColumnsShowPoliciesTransitions[currentSelectedColumn]);
    } else {
      setSelectedColumn(selectableColumns[0]);
    }
  }

  // Adjust columnMapping based on showPolicies
  const columnMapping = showPolicies ? {
    "Account": "account_number",
    "Policy": "policy_number",
    "Account Status": "account_status",
    "Coverage": "coverage_type",
    "Carrier": "carrier_name",
    "Cancellation Date": "policy_cancellation_date",
    "Calculated Cancellation Date": "policy_calc_cancellation_date",
    "Account Principal Balance__d": "account_principal_balance",
    "Account Interest Balance__d": "account_interest_balance",
    "Fee Balance__d": "policy_fee_balance",
    "Calculated Return Premium__d": "policy_calc_total_expected_return_premium",
    "Equity (w/fees)__d": "policy_calc_total_equity",
    "Equity (principal/interest)__d": "policy_calc_total_equity_principal_interest",
    "Equity (principal only)__d": "policy_calc_total_equity_principal_only",
    "Equity Notes": "policy_calc_equity_notes"
  } : {
    "Account": "account_number",
    "Status": "account_status",
    "Coverage(s)": "coverages",
    "Cancellation Date": "account_cancellation_date",
    "Calculated Cancellation Date": "account_calc_cancellation_date",
    "Principal Balance__d": "account_principal_balance",
    "Interest Balance__d": "account_interest_balance",
    "Fee Balance__d": "account_fee_balance",
    "Calculated Return Premium__d": "calc_total_expected_return_premium",
    "Equity (w/fees)__d": "calc_total_equity",
    "Equity (principal/interest)__d": "calc_total_equity_principal_interest",
    "Equity (principal only)__d": "calc_total_equity_principal_only",
    "Equity Notes": "account_calc_equity_notes"
  };

  const selectableColumns = showPolicies ? [
    "Account",
    "Policy",
    "Account Status",
    "Coverage",
    "Carrier",
    "Cancellation Date"
  ] : [
    "Account",
    "Status",
    "Coverage(s)",
    "Cancellation Date"
  ];

  const checkbox = (
    <FormControlLabel
      control={<Checkbox checked={showPolicies} onChange={(e) => {
        setShowPolicies(e.target.checked);
      }} />}
      label="Show Policies"
    />
  );

  const formatDate = (rowItem, fieldKey, fieldValue) => {
    if (isNaN(Date.parse(fieldValue))) {
      return '';
    }
  
    const datetimeObj = new Date(fieldValue);
    const formattedDate = datetimeObj.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  const formatDateAndReplaceEmptyValue = (rowItem, fieldKey, fieldValue) => {
    if (fieldKey === 'policy_cancellation_date' && !fieldValue) {
      return formatDate(rowItem, fieldKey, rowItem['account_cancellation_date']);
    }
    return formatDate(rowItem, fieldKey, fieldValue);
  };

  const columnDisplayInfo = {
    policy_cancellation_date: {
      customAppearance: formatDateAndReplaceEmptyValue
    },
    policy_calc_cancellation_date: {
      customAppearance: formatDate
    },
    account_cancellation_date: {
      customAppearance: formatDate
    },
    account_calc_cancellation_date: {
      customAppearance: formatDate
    },
    account_calc_equity_notes: {
      showHover: true
    },
    policy_calc_equity_notes: {
      showHover: true
    }
  }

  return (
    <div>
      <StyledHeading level={4}>Equity Report</StyledHeading>
      {isLoading && (
        <CircularProgressWrapper>
          <StyledCircularProgress />
        </CircularProgressWrapper>
      )}
      {!isLoading && (
        <SortableTable tableData={displayData} columnMapping={columnMapping} selectableColumns={selectableColumns} exportTitle={'Equity Report'} controlElements={[checkbox]} columnDisplayInfo={columnDisplayInfo} selectedColumnProp={selectedColumn} updateSelectedColumnProp={updateSelectedColumn}/>
      )}
    </div>
  );
};

export default EquityReport;
