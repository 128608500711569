import React, { useState, useEffect, useContext } from 'react';
import { MenuItem, FormControl, Select, CircularProgress } from '@mui/material';
import { Auth, API } from 'aws-amplify';

import { StateContext } from '../../StateContext';
import { getApiRequestInfo } from '../../api/utils';

const HeaderCustomerSelector = () => {
  const [customers, setCustomers] = useState([]);
  const [isFetchingCustomers, setIsFetchingCustomers] = useState(false);
  const [isUpdatingCustomer, setIsUpdatingCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState('');

  const { currentUser, setSuperAdminClientId } = useContext(StateContext);

  const defaultSelectionName = 'No Customer';

  useEffect(() => {
    const fetchCustomersMock = async () => {
			setIsFetchingCustomers(true);
			await delay(2);
			const filteredUsers = [
				{
					"id": "0001",
					"excluded_agent_ids": "15846,17062,8743,3759,8341",
					"fiscal_year_start_month_day": "1001",
					"name": "SIUPrem, Inc.",
				},
				{
					"id": "0003",
					"excluded_agent_ids": "",
					"fiscal_year_start_month_day": "0101",
					"name": "J&J Premium Finance",
				}
			]
			setCustomers(filteredUsers);
			setIsFetchingCustomers(false);
		};

    const fetchCustomers = async () => {
      setIsFetchingCustomers(true);
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const response = await API.get("idpro", '/customers', {
          headers: {
            'Authorization': token
          }
        });
        setCustomers(response);
      } catch (error) {
        console.error("Error fetching customers: ", error);
        // Handle error as required...
      } finally {
        setIsFetchingCustomers(false);
      }
    };

    // Fetch customers when the component mounts
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (currentUser) {
      console.log('HeaderCustomerSelector currentUser: ', currentUser);
      const saClientId = currentUser.attributes['custom:saclientid'];
      // get the customer name from the id
      const selectedCustomer = customers.find(customer => customer.id === saClientId);
      console.log('HeaderCustomerSelector selectedCustomer: ', selectedCustomer);
      setSelectedCustomer(selectedCustomer ? selectedCustomer.id : defaultSelectionName);
    }
  }, [customers]);

	useEffect(() => {

		const updateSuperAdminClientId = async () => {
			setIsUpdatingCustomer(true);
			const url = '/users';
	
			const body = {
				username: currentUser.username,
				attributes: {
					"custom:saclientid": selectedCustomer
				}
			};
			const requestInfo = await getApiRequestInfo(body);
	
			console.log('edit body: ', body);
			console.log('edit requestInfo: ', requestInfo);

			API.put("idpro", url, requestInfo)
			.then(response => {
				console.log("Edited SuperUser waiting for delay: ", response);
				setIsUpdatingCustomer(false);
				setSuperAdminClientId(selectedCustomer);
			})
			.catch(error => {
				console.error("Could not edit User: ", error);
				setIsUpdatingCustomer(false);
			});
		}

		if (currentUser && selectedCustomer && selectedCustomer !== '') {
      console.log('HeaderCustomerSelector is selectedCustomer!');
      if (selectedCustomer !== defaultSelectionName) {
			  updateSuperAdminClientId();
      } else {
        console.log('HeaderCustomerSelector selectedCustomer === defaultSelectionName!');
        setSuperAdminClientId(null);
      }
		}
	}, [selectedCustomer, currentUser]);

  const handleChange = (event) => {
    console.log('HeaderCustomerSelector handleChange: ', event.target.value);
    setSelectedCustomer(event.target.value);
  };

	// for testing only
  async function delay(seconds) {
    return new Promise((resolve) => {
      setTimeout(resolve, seconds * 1000);
    });
  }

	return (
    <FormControl fullWidth>
      <Select
        value={selectedCustomer}
        onChange={handleChange}
        displayEmpty
        renderValue={
          selectedCustomer !== '' ? undefined : () => <em>Select a customer</em>
        }
        disabled={isFetchingCustomers || isUpdatingCustomer} // Disable dropdown during loading
      >
        <MenuItem value={defaultSelectionName}>
          <em>{defaultSelectionName}</em>
        </MenuItem>
        {isFetchingCustomers ? (
          <MenuItem value="" disabled>
            <CircularProgress size={24} />
            <span style={{ marginLeft: '10px' }}>Loading...</span>
          </MenuItem>
        ) : (
          customers.map((customer) => (
            <MenuItem key={customer.id} value={customer.id}>
              {customer.name}
            </MenuItem>
          ))
        )}
      </Select>
      {isUpdatingCustomer && ( // Show loading indicator when updating
        <CircularProgress size={24} style={{ marginLeft: '10px' }} />
      )}
    </FormControl>
  );
};

export default HeaderCustomerSelector;
