export function formatTimestamp(timestamp: string): string {
  // Check if the timestamp is exactly 6 characters (YYYYMM)
  if (timestamp.length !== 6) {
    throw new Error("Timestamp must be in the format YYYYMM.");
  }

  // Extract the year (first 4 characters) and month (last 2 characters)
  const year = timestamp.substring(0, 4);
  const month = timestamp.substring(4);

  // Return the formatted timestamp
  return `${year}-${month}`;
}

export function adjustYears(dateStr: string, yearsToAdjust: number): string {
  // Check if the date string is exactly 8 characters (YYYYMMDD)
  if (dateStr.length !== 8) {
    throw new Error("Date must be in the format YYYYMMDD.");
  }

  // Extract the year, month, and day from the date string
  const year = parseInt(dateStr.substring(0, 4), 10);
  const month = parseInt(dateStr.substring(4, 6), 10) - 1; // Months are 0-indexed in JS Date
  const day = parseInt(dateStr.substring(6, 8), 10);

  // Create a new Date object with the extracted values and adjust the year
  const date = new Date(year, month, day);
  date.setFullYear(date.getFullYear() + yearsToAdjust);

  // Ensure the resulting date is formatted back into the "YYYYMMDD" format
  const formattedYear = date.getFullYear().toString();
  // Pad month and day with leading zeros if necessary
  const formattedMonth = (date.getMonth() + 1).toString().padStart(2, "0");
  const formattedDay = date.getDate().toString().padStart(2, "0");

  return formattedYear + formattedMonth + formattedDay;
}

export function nextYearSameMonth(dateStr: string): string {
  // Check if the date string is in the correct format (YYYY-MM)
  if (dateStr.length !== 7 || dateStr.indexOf("-") !== 4) {
    throw new Error("Date must be in the format YYYY-MM.");
  }

  // Extract the year and month from the date string
  const year = parseInt(dateStr.substring(0, 4), 10);
  const month = dateStr.substring(5, 7); // Month is already in the correct format

  // Increment the year by 1
  const nextYear = year + 1;

  // Return the date string for the same month next year
  return `${nextYear}-${month}`;
}

export function generateYearlyTimestamps(startDateStr: string): string[] {
  // Check if the start date string is exactly 8 characters (YYYYMMDD)
  if (startDateStr.length !== 8) {
    throw new Error("Start date must be in the format YYYYMMDD.");
  }

  // Extract the year and month from the start date string
  let year = parseInt(startDateStr.substring(0, 4), 10);
  let month = parseInt(startDateStr.substring(4, 6), 10); // 1-12

  const timestamps = [];

  for (let i = 0; i < 12; i++) {
    // Format the month and push the timestamp into the array
    const formattedMonth = month.toString().padStart(2, "0");
    timestamps.push(`${year}-${formattedMonth}`);

    // Increment month and handle year wrap
    month++;
    if (month > 12) {
      month = 1; // Reset month to January
      year++; // Increment year
    }
  }

  return timestamps;
}

type IncomeRecord = {
  year_month: string;
  monthly_income: {
    amount: number | null;
    receivabletype: string;
  }[];
};

export const containsVSCFee = (data: IncomeRecord[]): boolean => {
  // Iterate through each record
  for (const item of data) {
    // Check each income record for the 'vsc_fee' receivable type
    if (
      item.monthly_income.some(
        (income) => income.receivabletype === "vsc_fee",
      )
    ) {
      return true; // Return true at the first instance of 'vsc_fee'
    }
  }

  return false; // Return false if 'vsc_fee' is not found in any records
}
export const containsOtherFee = (data: IncomeRecord[]): boolean => {
  // Iterate through each record
  for (const item of data) {
    // Check each income record for the 'other_fee' receivable type
    if (
      item.monthly_income.some(
        (income) => income.receivabletype === "other_fee",
      )
    ) {
      return true; // Return true at the first instance of 'other_fee'
    }
  }

  return false; // Return false if 'other_fee' is not found in any records
};
