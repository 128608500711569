import React, { useState, useEffect } from 'react';
import { Auth, API } from "aws-amplify";
// import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';

import { getApiRequestInfo } from '../../api/utils';

const AdminCreateUserPopUp = ({ isOpen, setIsOpen, currentUser, updateUserList }) => {
  const [editedUser, setEditedUser] = useState({
    attributes: {
      name: '',
      email: '',
      'custom:clientid': currentUser.attributes['custom:clientid'],
      'custom:role': '',
    }
  });
  const [emailError, setEmailError] = useState('');
  const [requireCompanyCode, setRequireCompanyCode] = useState(false);
  const [companyCodeValue, setCompanyCodeValue] = useState('');
  const [isRequestingSave, setIsRequestingSave] = useState(false);

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const handleClickedSave = async () => {
    if (!isValidEmail(editedUser.attributes.email)) {
      setEmailError('Invalid email format');
      return;
    }
    const url = '/users';
    // I want to make a post request and sending a body in json format
    const body = editedUser
    console.log('create body: ', body);
    const requestInfo = await getApiRequestInfo(body);

    setIsRequestingSave(true);
    API.post("idpro", url, requestInfo)
    .then(response => {
      console.log("Created User: ", response);
      updateUserList('Fetching New User...');
      handleCloseDialog();
      setIsRequestingSave(false);
    })
    .catch(error => {
      console.error("Could not create User: ", error);
      //TODO: handle error
      setIsRequestingSave(false);
    });
  }
  
  const isAdminOrEmployee = (role) => {
    return role === 'admin' || role === 'employee';
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'email') {
        setEmailError('');
        console.log('email: ', value);
    }

    if (name === 'custom:role') {
      if (isAdminOrEmployee(value)) {
        setRequireCompanyCode(false);
      } else {
        setRequireCompanyCode(true);
      }
    }

    if (name === 'custom:company_code') {
        setCompanyCodeValue(value);
    }

    const user = {
        ...editedUser,
        attributes: {
            ...editedUser.attributes,
            [name]: value,
        },
    }
    // console.log('user: ', user);
    setEditedUser(user);
  };

  const handleCloseDialog = () => {
    setEmailError('');
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
      <DialogTitle>Create User</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          value={editedUser.attributes.name}
          onChange={handleInputChange}
          name="name"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          value={editedUser.attributes.email}
          onChange={handleInputChange}
          name="email"
          fullWidth
          margin="normal"
          type="email"
          error={!!emailError}
          helperText={emailError}
        />
        {/* <TextField
          label="Phone Number"
          value={editedUser.attributes.phone_number}
          onChange={handleInputChange}
          name="phone_number"
          fullWidth
          margin="normal"
          type="tel"
        /> */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="role-label">Role</InputLabel>
          <Select
            labelId="role-label"
            name="custom:role"
            value={editedUser.attributes['custom:role']}
            label="Role"
            onChange={handleInputChange}
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="employee">Employee</MenuItem>
            <MenuItem value="agent">Agent</MenuItem>
            <MenuItem value="sales">Sales</MenuItem>
          </Select>
        </FormControl>
        {requireCompanyCode && (
            <TextField
                label="Company Code"
                value={editedUser.attributes['custom:company_code']}
                onChange={handleInputChange}
                name="custom:company_code"
                fullWidth
                margin="normal"
                type="tel"
            />
        )}
        {/* Add more fields as needed */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Cancel</Button>
        {isRequestingSave ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <CircularProgress size={20} color="inherit" />
            <label className="amplify-label">Saving...</label>
          </div>
        ) : (
          <Button onClick={() => handleClickedSave(editedUser)} disabled={requireCompanyCode && companyCodeValue === ''}>Save</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AdminCreateUserPopUp;
