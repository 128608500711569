import React, { useState, useEffect, useRef } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import _ from 'lodash';

import CurrencyInput from "./CurrencyInput";

const BudgetItemCell = ({ value, segments, budgetItemName, dateString, onChange, onBlur, isDollarValue=true }) => {
    const [isEditing, setIsEditing] = useState(false);

    const [totalValue, setTotalValue] = useState(value);
    const [userInput, setUserInput] = useState('' + value);
    const [formattedDisplayValue, setFormattedDisplayValue] = useState('' + value);

    const [currentSegments, setCurrentSegments] = useState(segments.map(segment => ({ ...segment })));
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const initialSegmentsRef = useRef(segments.map(segment => ({ ...segment })));
    const initialTotalValueRef = useRef(value);


    useEffect(() => {
        console.log('user effect userInput: ', userInput);
        if (userInput !== '' && isValidNumber(userInput)) {
            const sanitizedInput = userInput.replace(/,/g, '');
            setTotalValue(parseFloat(sanitizedInput));
        } else {
            setTotalValue(0);
        }
    }, [userInput]);
    
    useEffect(() => {
        setFormattedDisplayValue(formatCurrency('' + totalValue));
    }, [totalValue]);

    useEffect(() => {
        const totalSum = currentSegments.reduce((acc, segment) => acc + segment.value, 0);
        if (currentSegments.length > 0 && totalSum !== 0) {
            calculateTotalValueFromSegments();
        }
    }, [currentSegments]);

    const isValidNumber = (input) => {
        // Remove commas for validation
        const sanitizedInput = input.replace(/,/g, '');
        // Check if it is a valid floating-point number after removing commas
        return !isNaN(sanitizedInput) && !isNaN(parseFloat(sanitizedInput));
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value);
    };    

    const calculateTotalValueFromSegments = () => {
        const total = currentSegments.reduce((acc, segment) => acc + Number(segment.value), 0);
        setTotalValue(parseFloat(total.toFixed(2)));
    };
    
    const handleDoubleClick = () => {
        if (segments.length > 0) {
            // calculateTotalValueFromSegments();
            setIsDialogOpen(true);
        } else {
            setIsEditing(true);
        }
    };

    const handleChange = (event) => {
        if (event.target.value === '' || isValidNumber(event.target.value)){
            setUserInput(event.target.value);
        }
    };

    const handleSegmentChange = (index, newValue) => {
        const updatedSegments = currentSegments.map((segment, i) =>
            i === index ? { ...segment, value: newValue } : segment
        );
        setCurrentSegments(updatedSegments);
    };
    
    const handleBlur = () => {
        initialSegmentsRef.current = currentSegments.map(segment => ({ ...segment }));
        initialTotalValueRef.current = totalValue;
        setUserInput(formattedDisplayValue.replace(/\$/g, ''));
        setIsEditing(false);
        onBlur({
            segments: currentSegments,
            total: totalValue
        });
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleBlur();
        }
    };

    const handleApplyDialog = () => {
        setIsDialogOpen(false);
        handleBlur(); // Pass the total value back to the parent component
    };

    const handleCancelDialog = () => {
        setCurrentSegments(initialSegmentsRef.current);
        setTotalValue(initialTotalValueRef.current);
        setIsDialogOpen(false);
    };
    

    const renderDialog = () => (
        <Dialog open={isDialogOpen} onClose={handleCancelDialog} maxWidth="sm" fullWidth>
            <DialogTitle>Edit <strong>{budgetItemName}</strong> Segments for <strong>{dateString}</strong></DialogTitle>
            <DialogContent>
                {currentSegments.map((segment, index) => (
                    <CurrencyInput
                        key={index}
                        label={segment.name}
                        value={segment.value}
                        onChange={(newValue) => handleSegmentChange(index, newValue)}
                        isDollarValue={isDollarValue}
                    />
                ))}
                <div>Total: {isDollarValue ? formattedDisplayValue : parseInt(totalValue)}</div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelDialog}>Cancel</Button>
                <Button onClick={handleApplyDialog}>Apply</Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            {isEditing ? (
                <input 
                    type="text" 
                    value={userInput} 
                    onChange={handleChange} 
                    onBlur={handleBlur} 
                    onKeyPress={handleKeyPress}
                    autoFocus
                />
            ) : (
                <div onDoubleClick={handleDoubleClick}>{isDollarValue ? formattedDisplayValue : parseInt(totalValue)}</div>
            )}
            {renderDialog()}
        </>
    );
};

export default BudgetItemCell;
