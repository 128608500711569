import { API } from "aws-amplify";
import { getApiRequestInfo } from "../../utils";
import { ChatHistoryType } from "../../../components/ChatClient/types";

interface fetchRankingDataArgs {
  extendedBackendChatHistory: ChatHistoryType;
}

export async function putGptChatHistory({
  extendedBackendChatHistory,
}: fetchRankingDataArgs): Promise<any> {
  // remove parts o the chat history that are not needed for chatGPR eg. tableData
  const sanitizedHistory = {
    title: extendedBackendChatHistory.title,
    historyId: extendedBackendChatHistory.historyId,
    messages: extendedBackendChatHistory.messages.map((historyItem) => {
      delete historyItem.tableData;
      delete historyItem.type;
      return historyItem;
    }),
  };

  console.log({ sanitizedHistory });
  const requestInfo = await getApiRequestInfo({
    chatHistory: sanitizedHistory
  });
  const url = "/chathistories";

  try {
    return await API.put("idpro", url, requestInfo);
  } catch (error) {
    console.error("Error querying based on GPT's response: ", error);
    throw new Error("Failed to fetch Rankings data");
  }
}

