import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import "./SideBar.css";
import { getNavigation } from "../../config";
import SideBarNav from "./SidebarNav";
import SidebarNavToggle from "./SidebarNavToggle";
import { StateContext } from "../../StateContext";
var appNavs = null;


const SideBar = () => {
  const { clientType } = useContext(StateContext);
  const [expand, setExpand] = useState(false);
  let location = useLocation();
  appNavs = getNavigation(clientType);

  console.log("appNavs: ", appNavs);

  useEffect(() => {
    setExpand(false);
    appNavs = getNavigation(clientType);
  }, [location, clientType]);

  return (
    <>

      <div className="btn-sidebar-nav">
        <SidebarNavToggle expand={expand} onChange={() => setExpand(!expand)} />
      </div>
      <div className={"sidebar " + (expand ? "visible" : "")}>
        <SideBarNav navs={appNavs} />
      </div>

    </>
  );
};

export default SideBar;
