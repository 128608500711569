import React, { useEffect, useState } from "react";
import { ColumnDisplayInfo, ColumnMapping } from "../types";
import { makeColumnMappings } from "../utils";
import SortableTable from "../../../components/Table/SortableTable";
import Modal from "react-modal";
import { customModalStyles, StyledButton } from "./styles";

interface ChatMessageDataProps {
  conversationTitle: string;
  data: any[];
}

export const ChatMessageData = ({ conversationTitle, data }: ChatMessageDataProps) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [columnMapping, setColumnMapping] = useState<ColumnMapping>({});
  const [columnDisplayInfo, setColumnDisplayInfo] = useState<ColumnDisplayInfo>(
    {},
  );

  useEffect(() => {
    if (data.length) {
      const { newColumnMapping, newColumnDisplayInfo } =
        makeColumnMappings(data);
      setColumnMapping(newColumnMapping);
      setColumnDisplayInfo(newColumnDisplayInfo);
    }
  }, [data]);

  const selectableColumns = Object.keys(columnMapping);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    data.length ? (
      <div>
        <StyledButton onClick={openModal}>
          <p>Your results:</p>
          <p>{data.length} records</p>
        </StyledButton>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          // @ts-ignore
          style={customModalStyles}
          contentLabel="Example Modal"
        >
          <SortableTable
            tableData={data}
            columnMapping={columnMapping}
            selectableColumns={selectableColumns}
            exportTitle={conversationTitle}
            columnDisplayInfo={columnDisplayInfo}
            fixedHeight={"80%"}
          />
        </Modal>
      </div>
    ) : (
      <StyledButton><p>No results</p></StyledButton>
    )
  );
};

export default ChatMessageData;
