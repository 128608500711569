import styled from "styled-components";
import {Heading} from "@aws-amplify/ui-react";

export const StyledHeading = styled(Heading)`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  color: var(--amplify-components-link-color);
`;

export const SectionWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  padding-top: 0rem;
  border-radius: 1rem;
  background-color: white;
  border: solid 1px #0b97ac;
  margin-bottom: 1rem;
`;
