interface Premium {
    premium: number;
    inspection_fee: number;
    policy_fee: number;
    tax_stamp_fee: number;
    broker_fee: number;
    loan_count: number;
}

interface IncomeItem {
    receivable_type: string;
    amount: number;
}

interface ResponseData {
    month_income: IncomeItem[];
    year_income: IncomeItem[];
    day_income: IncomeItem[];
    year_premium: Premium;
    month_premium: Premium;
    day_premium: Premium;
    year_earned_interest: number;
    month_earned_interest: number;
    day_earned_interest: number;
}

// Utility function to sum premium components
const calculateTotalPremium = (premium: Premium) => {
        // need to exclude loan_count from the sum
    const { loan_count, ...otherValues } = premium;
    return Object.values(otherValues)
      .reduce((acc, curr) => acc + (curr ?? 0), 0);
  };

// Utility function to find the amount financed
const findAmountFinanced = (income: IncomeItem[]) =>
    income.find(item => item.receivable_type === "amount_financed")?.amount ?? 0;

export const processIncomeData = (

    response: ResponseData,
    setApiData: (data: ResponseData) => void,
    setMonthAmountFinanced: (amount: number) => void,
    setYearAmountFinanced: (amount: number) => void,
    setDayAmountFinanced: (amount: number) => void,
    setYearPremium: (premium: number) => void,
    setYearLoanCount: (count: number) => void,
    setMonthPremium: (premium: number) => void,
    setMonthLoanCount: (count: number) => void,
    setDayPremium: (premium: number) => void,
    setDayLoanCount: (count: number) => void,
    setYearEarnedInterest: (interest: number) => void,
    setMonthEarnedInterest: (interest: number) => void,
    setDayEarnedInterest: (interest: number) => void
) => {

    setApiData(response);

    setMonthAmountFinanced(findAmountFinanced(response.month_income));
    setYearAmountFinanced(findAmountFinanced(response.year_income));
    setDayAmountFinanced(findAmountFinanced(response.day_income));

    setYearPremium(calculateTotalPremium(response.year_premium));
    setYearLoanCount(response.year_premium.loan_count);

    setMonthPremium(calculateTotalPremium(response.month_premium));
    setMonthLoanCount(response.month_premium.loan_count);

    setDayPremium(calculateTotalPremium(response.day_premium));
    setDayLoanCount(response.day_premium.loan_count);

    setYearEarnedInterest(response.year_earned_interest);
    setMonthEarnedInterest(response.month_earned_interest);
    setDayEarnedInterest(response.day_earned_interest);
};
