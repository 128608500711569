import React, { SetStateAction, useEffect } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";
import { Flex, Placeholder } from "@aws-amplify/ui-react";
import { defaultOptions } from "./constants";
import { useQuery } from "react-query";
import { fetchFiscalYearStart } from "../../api/utils/fetchFiscalYearStart/fetchFiscalYearStart";
import { fetchMonthlySummaryData } from "../../api/utils/fetchMonthlySummaryData/fetchMonthlySummaryData";
import {
  adjustYears,
  generateYearlyTimestamps,
  containsOtherFee,
  containsVSCFee
} from "./utils";
import { fetchFiscalYearBudget } from "../../api/utils/fetchFiscalYearBudget/fetchFiscalYearBudget";

interface IncomeYearCompareProps {
  startDate: Date;
  incomeTypes: any[];
  options: any;
  agentCode: string;
  state: string | undefined;
  solicitorCode: string | undefined;
  territoryCode: string | undefined;
  containsOtherFees: boolean;
  containsVSCFees: boolean;
  otherFeesCallback?: ((containsOtherFees: boolean) => void) | null;
  VSCFeesCallback?: ((containsVSCFees: boolean) => void) | null;
}

function IncomeYearCompare({
  startDate,
  incomeTypes,
  options,
  agentCode,
  state,
  solicitorCode,
  territoryCode,
  containsOtherFees= false,
  containsVSCFees = false,
  otherFeesCallback = null,
  VSCFeesCallback = null
}: IncomeYearCompareProps) {
  const [barChartData, setBarChartData] = useState({});
  const [lineChartData, setLineChartData] = useState({});
  //const [budgetData, setBudgetData] = useState({});
  const [budgetData, setBudgetData] = useState<{ data: any[]; name: string; type: string }>({ data: [], name: '', type: '' });

  const [labels, setLabels] = useState([]);
  const [fullChartData, setFullChartData] = useState([]);
  const [dataFetching, setDataFetching] = useState(true);

  const { data: fiscalYearData, isFetching: fiscalYearFetching } = useQuery(
    [`income-dashboard-fiscal-year-data`, startDate, solicitorCode, agentCode],
    () =>
      fetchFiscalYearStart({
        selectedDate: startDate,
      }),
    {
      enabled: !!startDate,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const labels = generateYearlyTimestamps(data?.fiscal_year_start_date);
        setLabels(labels as unknown as SetStateAction<never[]>);
      },
    },
  );

  // gets data for the current fiscal year
  const { isFetching } = useQuery(
    [`monthlySummaryData`, startDate, agentCode, state, solicitorCode],
    () =>
      fetchMonthlySummaryData({
        // @ts-ignore
        selectedDate: adjustYears(
          fiscalYearData?.fiscal_year_start_date,
          1,
        ).toString(),
        solicitorCode: solicitorCode || "",
        territoryCode: territoryCode || "",
        state: state || "",
        fiscalStart: fiscalYearData?.fiscal_year_start_date,
        startDate,
        agentCode,
        oneYearFromFiscalStart: true,
      }),
    {
      enabled: !!startDate && !!fiscalYearData?.fiscal_year_start_date,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const otherFeeCheck = containsOtherFee(data);
        const VSCFeeCheck = containsVSCFee(data);

        // activate/deactivate other fees depending on data
        if (otherFeeCheck !== containsOtherFees && otherFeesCallback) {
          otherFeesCallback(otherFeeCheck);
        }
        // activate/deactivate VSC fees depending on data
        if (VSCFeeCheck !== containsVSCFees && VSCFeesCallback) {
          VSCFeesCallback(VSCFeeCheck);
        }

        const barData = {
          data: data
            .map(
              (item: { monthly_income: any[] }) =>
                item.monthly_income.find(
                  (incomeItem) =>
                    incomeItem.receivabletype === incomeTypes[0].selector,
                )?.amount ?? 0,
            )
            .slice(0, 12),
          name: incomeTypes[0].title,
          type: "bar",
        };

        setBarChartData(barData);
      },
    },
  );

  // gets previous years data and maps against current year labels
  const { isFetching: isPreviousYearFetching } = useQuery(
    [
      `PreviousYearMonthlySummaryData`,
      startDate,
      agentCode,
      state,
      solicitorCode,
    ],
    () =>
      fetchMonthlySummaryData({
        selectedDate: startDate,
        solicitorCode: solicitorCode || "",
        territoryCode: territoryCode || "",
        state: state || "",
        fiscalStart: adjustYears(fiscalYearData?.fiscal_year_start_date, -1),
        startDate,
        agentCode,
        oneYearFromFiscalStart: true,
      }),
    {
      enabled: !!startDate && !!fiscalYearData?.fiscal_year_start_date,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const lineData = {
          data: data
            .map(
              (item: { monthly_income: any[] }) =>
                item.monthly_income.find(
                  (incomeItem) =>
                    incomeItem.receivabletype === incomeTypes[0].selector,
                )?.amount ?? 0,
            )
            .slice(0, 12),
          name: "Total (prior year)",
          type: "line",
        };

        setLineChartData(lineData);
      },
    },
  );

  // get budget data for each month of the fiscal year
  // only call if solicitor agent territory and state are set to all
  const { isFetching: fiscalYearBudgetFetching, isError: isBudgetError } = useQuery(
    [
      `dashboard-fiscal-year-budget`,
      fiscalYearData?.fiscal_year,
      solicitorCode,
      state,
    ],
    () =>
      fetchFiscalYearBudget({
        fiscalYear: fiscalYearData?.fiscal_year,
        solicitorCode: solicitorCode,
        agentCode: agentCode,
        state: state,
      }),
    {
      enabled:
        solicitorCode === "All" &&
        agentCode === "All" &&
        state === "All" &&
        territoryCode === "All" &&
        !!fiscalYearData?.fiscal_year &&
        !fiscalYearFetching,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const budgetData = labels?.map(
          (label, index) => data.months[index][incomeTypes[0]?.selector]?.total,
        );

        const budgetLineData = {
          data: budgetData.slice(0, 12),
          name: "Budget",
          type: "line",
        };

        // Check if all items in budgetLineData are 0
        const allZero = budgetLineData.data.every((item) => item === 0);

        if (!allZero) {
          setBudgetData(budgetLineData); // Pass the full object directly
        } else {
          setBudgetData({ data: [], name: '', type: '' }); // Reset to an empty object with correct structure
        }
        
      },
    },
  );

  const shouldShowBudgetLine = () => {

    // API errors when it cant find a budget, so we just remove the line from the chart
    if (isBudgetError) return false;

    if (budgetData.data.length === 0) return false;


    // removes the budget line unless solicitor agent and state are set to all in the form
    return solicitorCode === "All" && agentCode === "All" && state === "All";
  };

  // update the chart in one go when all loading has resolved to avoid mutations
  useEffect(() => {
    let newChartData = [];
    if (!dataFetching) {
      newChartData.push(barChartData);
      newChartData.push(lineChartData);

      // removes the budget line unless solicitor agent and state are set to all in the form
      if (shouldShowBudgetLine()) newChartData.push(budgetData);

      // @ts-ignore
      setFullChartData(newChartData);
    }
    // @ts-ignore
  }, [
    barChartData,
    lineChartData,
    budgetData,
    dataFetching,
    agentCode,
    solicitorCode,
  ]);

  useEffect(() => {
    if (
      isFetching ||
      fiscalYearFetching ||
      isPreviousYearFetching ||
      fiscalYearBudgetFetching
    ) {
      setDataFetching(true);
    } else {
      setDataFetching(false);
    }
  }, [
    isFetching,
    fiscalYearFetching,
    isPreviousYearFetching,
    fiscalYearBudgetFetching,
  ]);

  if (dataFetching) {
    return (
      <Flex direction="column" minHeight="285px" placeholder={undefined}>
        <Placeholder size="small" placeholder={undefined} />
        <Placeholder size="small" placeholder={undefined} />
        <Placeholder size="small" placeholder={undefined} />
        <Placeholder size="small" placeholder={undefined} />
      </Flex>
    );
  }

  return (
    <>
      {/*// @ts-ignore*/}
      <Chart
        series={fullChartData}
        type="bar"
        height={284}
        width="100%"
        options={Object.assign({}, defaultOptions, options, { labels })}
      />
    </>
  );
}

export default IncomeYearCompare;
