import React from 'react';

const MFAErrorMsg = ({ message, setShowMsg }) => {
  return (
    <div className="amplify-flex amplify-alert amplify-alert--error" role="alert">
      <span className="amplify-alert__icon">
        <span className="amplify-icon" aria-hidden="true" style={{ width: '1em', height: '1em' }}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z" fill="currentColor"></path>
          </svg>
        </span>
      </span>
      <div style={{ flex: '1 1 0%' }}>
        <div className="amplify-alert__body">{message}</div>
      </div>
      {/* Dismiss button (functionality not implemented here) */}
      <button aria-label="Dismiss alert" className="amplify-button amplify-field-group__control amplify-button--link amplify-alert__dismiss" type="button" onClick={() => {setShowMsg(false);}}>
        <span className="amplify-icon" aria-hidden="true" style={{ width: '1em', height: '1em' }}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="currentColor"></path>
          </svg>
        </span>
      </button>
    </div>
  );
};

export default MFAErrorMsg;
