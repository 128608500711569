import React, { useState } from "react";
import {
  DashboardWrapper,
  StyledHeading,
  ChartsWrapper,
  StyledCircularProgress,
} from "./styles";
import { AvPFields } from "../../../components/AvPSegmentFields/AvPFields";
import ExportAvPTableDataIcon from "../../../components/AvPSegmentFields/ExportAvPTableDataIcon";
import dayjs, { Dayjs } from "dayjs";
import { View } from "@aws-amplify/ui-react";
import { useQuery } from "react-query";
import { fetchFiscalYearStart } from "../../../api/utils/fetchFiscalYearStart/fetchFiscalYearStart";
import { fetchMonthlySegmentData } from "../../../api/utils/fetchMonthlySegmentValues/fetchMonthlySegmentData";
import {
  extractMonthlyValuesAndMakeTableData,
  getFiscalYearMonths,
  getUniqueSegmentTypes,
  getUniqueValueKeys,
} from "./utils";
import { fetchFiscalYearBudget } from "../../../api/utils/fetchFiscalYearBudget/fetchFiscalYearBudget";
import SegmentTable from "../../../components/SegmentTable/SegmentTable";
import { Formik } from "formik";
import { AvPSegmentChart } from "../../../components/AvPSegmentChart/AvPSegmentChart";
import { menuItems } from "./constants";


export function ActualVPlanSegment() {
  const startDate = dayjs().subtract(1, "day");     // default is yesterday

  const [selectedDate, setSelectedDate] = useState<Dayjs>(startDate);
  const [labels, setLabels] = useState<string[]>([]);

  const { data: fiscalYearData, isFetching: fiscalYearFetching } = useQuery(
    [`segments-dashboard-fiscal-year-data`, selectedDate],
    () =>
      fetchFiscalYearStart({
        selectedDate: selectedDate.toDate(),
      }),
    {
      enabled: !!selectedDate,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setLabels(getFiscalYearMonths(data.fiscal_year_start_date));
      },
    },
  );

  const { data: monthlyData, isFetching } = useQuery(
    [
      `segments-dashboard-monthly-segment-data`,
      selectedDate,
      fiscalYearData?.fiscal_year_start_date,
    ],
    () =>
      fetchMonthlySegmentData({
        selectedDate: selectedDate, /*** dayjs(fiscalYearData?.fiscal_year_start_date).add(
          1,
          "year",
        ), **/
        startYearMonth: dayjs(fiscalYearData?.fiscal_year_start_date),
      }),
    {
      enabled: !!fiscalYearData?.fiscal_year_start_date,
      refetchOnWindowFocus: false,
    },
  );

  const { data: budgetData, isFetching: fiscalYearBudgetFetching } = useQuery(
    [`dashboard-fiscal-year-budget`, fiscalYearData?.fiscal_year],
    () =>
      fetchFiscalYearBudget({
        fiscalYear: fiscalYearData?.fiscal_year,
      }),
    {
      enabled: !!fiscalYearData?.fiscal_year && !fiscalYearFetching,
      refetchOnWindowFocus: false,
    },
  );

  const isAnythingFetching =
    fiscalYearFetching || isFetching || fiscalYearBudgetFetching;

  interface FormValues {
    selectedYear: Dayjs;
    selectedBudgetItem?: string;
    isFinancial: boolean;
  }

  const initialValues: FormValues = {
    selectedYear: selectedDate,
    selectedBudgetItem: "gross_revenue",
    isFinancial: true,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        enableReinitialize={false}
      >
        {({ values }) => (
          <>
            <DashboardWrapper>
              <StyledHeading level={5} placeholder={undefined}>
                {"Actual v Plan By Segment"}
              </StyledHeading>

              <AvPFields 
                menuItems={menuItems} 
                onDateChange={setSelectedDate} 
                tablesData={extractMonthlyValuesAndMakeTableData(
                  monthlyData || [],
                  values.selectedBudgetItem || "gross_revenue",
                  budgetData,
                  getUniqueSegmentTypes(monthlyData || []),
                )}
                isAnythingFetching={isAnythingFetching}
              />

            </DashboardWrapper>

            <View
              borderRadius="6px"
              maxWidth="100%"
              padding="0rem"
              minHeight="100vh"
            >
              <ChartsWrapper>
                {isAnythingFetching && <StyledCircularProgress />}

                {!isAnythingFetching && (
                  <AvPSegmentChart
                    labels={labels}
                    monthlyData={monthlyData}
                    budgetData={budgetData}
                  />
                )}

                {!isAnythingFetching &&
                  extractMonthlyValuesAndMakeTableData(
                    monthlyData || [],
                    values.selectedBudgetItem || "gross_revenue",
                    budgetData,
                    getUniqueSegmentTypes(monthlyData || []),
                  ).map((segment, i) => {
                    return <SegmentTable {...segment} key={i} />;
                  })}
              </ChartsWrapper>
            </View>
          </>
        )}
      </Formik>
    </>
  );
}
