const roundToWholeNumber = (num: number | string): number => {
  return Math.round(Number(num));
};

export const valuesFormat = (
  value: number | string,
  isFinancial: boolean,
): number | string => {
  if (isFinancial) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(Number(value));
  }

  // in non-financial case just round the number to keep the table formatting nice
  return roundToWholeNumber(value);
};
