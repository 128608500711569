import React, { useContext } from "react";
import { StyledHeading, StyledSpan } from "./styles";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import CircularProgress from '@mui/material/CircularProgress';
import { StateContext } from "../../StateContext"; // Import the StateContext to get clientType

interface DashboardHeadingProps {
  totalWorkingDaysThisMonth: number;
  totalWorkingDaysUntilToday: number;
  percentMonthCompleted: number;
  speechText?: string;
}


const speakContent = async (content: string, onComplete: () => void, setLoading: (loading: boolean) => void, setSource: (source: AudioBufferSourceNode | null) => void) => {
  setLoading(true); // Set loading to true when the function starts
  try {
    const response = await fetch('https://api.openai.com/v1/audio/speech', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
      },
      body: JSON.stringify({
        model: "tts-1",
        voice: "shimmer",
        input: content
      })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const arrayBuffer = await response.arrayBuffer();
    const audioContext = new (window.AudioContext)();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);
    source.start(0);
    
    setSource(source); // Save the source to be able to stop it later

    source.onended = () => {
      setLoading(false); // Set loading to false when the audio finishes playing
      setSource(null); // Clear the source reference
      onComplete();
    };
  } catch (error) {
    console.error('Error synthesizing text:', error);
    setLoading(false); // Set loading to false if there is an error
    setSource(null); // Clear the source reference
    onComplete();
  }
};

export const DashboardHeading = ({
  totalWorkingDaysThisMonth,
  totalWorkingDaysUntilToday,
  percentMonthCompleted,
  speechText,
}: DashboardHeadingProps) => {
  const { clientType } = useContext(StateContext); // Get clientType from StateContext
  // Determine the dashboard title based on clientType
  const dashboardTitle = clientType === "mga" ? "MGA Management Dashboard" : "PFC Management Dashboard";

  const speak = speechText || `There are ${totalWorkingDaysThisMonth - totalWorkingDaysUntilToday} working days left in this month. The month is ${percentMonthCompleted}% complete.`;

  const [iconColor, setIconColor] = React.useState("black");
  const [speaking, setSpeaking] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [audioSource, setAudioSource] = React.useState<AudioBufferSourceNode | null>(null);

  const handleClick = () => {
    if (speaking) {
      if (audioSource) {
        audioSource.stop(); // Stop the audio if it's already speaking
        setAudioSource(null); // Clear the source reference
        setSpeaking(false);
        setIconColor("black"); // Reset the icon color when stopped
      }
      return;
    }
    setIconColor("red");
    setSpeaking(true);

    speakContent(speak, () => {
      setSpeaking(false);
      setIconColor("black"); // Reset the icon color when done
    }, setLoading, setAudioSource);
  };

  return (
    <StyledHeading level={5} placeholder={undefined}>
      {dashboardTitle}{" "}
      <StyledSpan>
        ({totalWorkingDaysThisMonth - totalWorkingDaysUntilToday} working days
        left in month - {percentMonthCompleted}% complete)
      </StyledSpan>
      {" "}
      <RecordVoiceOverIcon
        style={{ verticalAlign: "bottom", cursor: "pointer", color: iconColor }}
        onClick={handleClick}
      />
      {loading ? <CircularProgress size={24} style={{ marginLeft: 8 }} /> : null}
    </StyledHeading>
  );
};

export default DashboardHeading;