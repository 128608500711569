import { useNavigate, useLocation } from 'react-router';
import { useState, useEffect } from 'react';
import { useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import MFASetupManager from "./Authenticator/MFASetupManager";
import CustomAuthenticator from './Authenticator/CustomAuthenticator';

export function Login() {

    const [isMfaPassed, setIsMfaPassed] = useState(false); //TODO use isMFASetupRequired context var instead?
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    let from = location.state?.from?.pathname || '/';

    useEffect(() => {
        if (route === 'authenticated' && isMfaPassed) {
            navigate(from, { replace: true });
        }
    }, [route, navigate, from, isMfaPassed]);
    
    return (
        <View className="auth-wrapper">
            {route === 'authenticated' ? 
                <MFASetupManager setIsMfaPassed={setIsMfaPassed}/> 
                : 
                <CustomAuthenticator hideSignUp={true} variation="modal"></CustomAuthenticator>
            }
        </View>
    );
}

export default Login;