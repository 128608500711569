import React from "react";
import { Menu, MenuButton, Icon } from "@aws-amplify/ui-react";
import { MdOutlineSettings } from "react-icons/md";
import { MenuItems } from "./MenuItems";
import { HeaderAvatar, StyledUserIndicatorText } from "./styles";

const HeaderNav = ({ username }: { username: string }) => {
  return (
    <>
      {username ? (
        <StyledUserIndicatorText>Welcome, {username}! </StyledUserIndicatorText>
      ) : (
        <StyledUserIndicatorText>User logged out... </StyledUserIndicatorText>
      )}

      <Menu
        menuAlign="end"
        placeholder="Settings"
        trigger={
          <MenuButton variation="menu">
            <HeaderAvatar>
              <Icon as={MdOutlineSettings} width={24} height={24} />
            </HeaderAvatar>
          </MenuButton>
        }
      >
        <MenuItems />
      </Menu>
    </>
  );
};

export default HeaderNav;
