import { styled } from "styled-components";
import { Card } from "@aws-amplify/ui-react";

export const StyledCard = styled(Card)`
  min-height: 131px;
  border-radius: 15px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const CardTitle = styled.p`
  font-size: 18px;
  color: #0b97ac;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
`;

export const CardStatisticsAmount = styled.p`
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 0;
`;

export const CardStatisticsBudget = styled.span`
  font-size: 14px;
  color: #0b97ac;
  font-weight: bold;
`;

export const IconWrapper = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  border-radius: 12px;
  color: #fff;
  background-color: #0b97ac;
  font-size: 22px;
  flex-shrink: 0;
  margin-left: 5px;
`;
