import { styled } from "styled-components";
import { Card, Heading, TableCell } from "@aws-amplify/ui-react";

export const StyledCard = styled(Card)`
  min-height: 131px;
  border-radius: 15px;
  overflow: scroll;
`;

export const StyledHeading = styled(Heading)`
  margin-top: 0rem;
  margin-bottom: 0.75rem;
  color: #0b97ac;
  position: absolute;
`;

export const StyledRowHeader = styled(TableCell)`
  font-weight: bold;
  font-size: 14px;
  text-align: right;
`;
export const StyledMoneyCell = styled(TableCell)`
  text-align: right;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
`;

export const StyledBudgetSpan = styled.span<{ color?: string }>`
  ${({ color }) => (color ? `color: ${color};` : `color: #0b97ac;`)}
`;
