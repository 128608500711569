import React, { useState, useEffect } from "react";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Flex,
  Placeholder,
} from "@aws-amplify/ui-react";
import { DollarValue } from "../../utils";
import { API } from "aws-amplify";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

import dayjs from "dayjs";
import { getApiRequestInfo } from "../../api/utils";
import TableCard from "../../components/DashboardCard/TableCard";

const AgentWriteoffs = ({
  startDate,
  headerBgColor,
  setTableData = null,
  selectedDate,
  title,
}) => {
  const [apiData, setApiData] = useState(null);
  const [nAgents, setNAgents] = useState(20); // State for the number of agents to display
  const [isFetching, setIsFetching] = useState(false);
  const [asOfDateString, setAsOfDateString] = useState("");
  useEffect(() => {
    const getAgentWriteoffs = async () => {
      const requestInfo = await getApiRequestInfo(null);

        // HCSNote:  we know the API looks back 1 year, so make the date here, but this probably isn't a good idea in case we change the API
      setAsOfDateString( " since " + dayjs(startDate).subtract(1, 'year').format("MMM DD, YYYY"));

      const selectedDate = `selectedDate=${dayjs(startDate).format("YYYYMMDD")}`;
      const nAgentsQP = `nAgents=${nAgents}`;
      const url = `/agentwriteoffs?${selectedDate}&${nAgentsQP}`;
      try {
        const response = await API.get("idpro", url, requestInfo);
        setApiData(response.items);
        setTableData && setTableData(response.items);
      } catch (error) {
        console.log("error: " + JSON.stringify(error));
      }
    };
    setIsFetching(true);
    getAgentWriteoffs();
    setIsFetching(false);
  }, [startDate, nAgents]);

  const handleNAgentsChange = (event) => {
    setNAgents(event.target.value);
  };

  return (
    <div>
      <TableCard
        selectedDate={selectedDate}
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {title + asOfDateString || ""}
            <Tooltip title="This is calculated by looking at the year prior to the selected date and totalling all of the writeoffs for each agents' accounts.  Total amount financed for the past year is also included but doesn't affect the ranking.">
              <InfoIcon style={{ marginLeft: 8, fontSize: '1rem', cursor: 'pointer' }} />
            </Tooltip>
          </div>
        }
        key={title}
        tableDataProp={apiData}
        backgroundColor={"white"}
      >
        <FormControl
          variant="outlined"
          size="small"
          style={{ marginBottom: "1rem", width: "20%", marginTop: "0.5rem" }}
        >
          <InputLabel id="nAgents-select-label">Number of Agents</InputLabel>
          <Select
            labelId="nAgents-select-label"
            id="nAgents-select"
            value={nAgents}
            onChange={handleNAgentsChange}
            label="Number of Agents"
          >
            {[20, 40, 60, 80, 100].map((number) => (
              <MenuItem key={number} value={number}>
                {number}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {apiData && !isFetching ? (
          <Table caption="" highlightOnHover={true} size={"small"}>
            <TableHead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: headerBgColor,
              }}
            >
              <TableRow>
                <TableCell as="th">Rank</TableCell>
                <TableCell as="th">Agent Name</TableCell>
                <TableCell className="money" as="th">Write-Off Total</TableCell>
                <TableCell className="money" as="th">Amount Financed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apiData.slice(0, nAgents).map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{index+1}</TableCell>
                  <TableCell>{item.name} ({item.agent_code})</TableCell>
                  <TableCell className="money"><DollarValue value={item.amount} fractionDigits={2}/></TableCell>
                  <TableCell className="money"><DollarValue value={item.amount_financed} fractionDigits={2}/></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Flex direction="column" minHeight="285px">
            <Placeholder size="small" />
            <Placeholder size="small" />
            <Placeholder size="small" />
            <Placeholder size="small" />
          </Flex>
        )}
      </TableCard>
    </div>
  );
};

export default AgentWriteoffs;
