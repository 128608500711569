import React, { useContext } from "react";
import { MenuItem } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../../StateContext";
import { useAuthStatus } from "../../../hooks/useAuthStatus/useAuthStatus";

interface getMenuItemsArgs {}

export const MenuItems: React.FC = ({}: getMenuItemsArgs) => {
  const navigate = useNavigate();
  const { isAdmin, isSuperAdmin, superAdminClientId, onLogout } =
    useContext(StateContext);

  const { isAuthenticated: isAuthenticatedRoute, signOut } = useAuthStatus();

  function logOut() {
    signOut();
    onLogout();
    navigate("/login");
  }

  function openAbout() {
    window.open('https://prezi.com/view/ySuhPTJn0F7dnHvnm1ql/', '_blank');
  }

  return (
    <>
      <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
      
      {(isAdmin || (isSuperAdmin && superAdminClientId)) && (
        <>
          <MenuItem onClick={() => navigate("/users")}>Users</MenuItem>
          <MenuItem onClick={() => navigate("/budgets")}>Budgets</MenuItem>
        </>
      )}

      {isSuperAdmin && (
        <>
          <MenuItem onClick={() => navigate("/customers")}>Customers</MenuItem>
          <MenuItem onClick={() => navigate("/aiinsightlogs")}>AI Insight Logs</MenuItem>
        </>
      )}
      <MenuItem onClick={openAbout}>About</MenuItem>  
      {isAuthenticatedRoute ? (
        <MenuItem onClick={() => logOut()}>Logout</MenuItem>
      ) : (
        <MenuItem onClick={() => navigate("/login")}>Login</MenuItem>
      )}
    </>
  );
};
