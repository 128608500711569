import { API } from "aws-amplify";
import { getApiRequestInfo } from "../../utils";
import dayjs from "dayjs";
import {buildDashboardUrl} from "../buildDashboardUrl/buildDashboardUrl";

interface fetchDailySummaryTextArgs {
  selectedDate: Date;
  selectedAgent: string;
  selectedState: string;
  selectedSalesExec: string;
  selectedTerritory: string;
  yearAmountFinanced: number;
  yearAmountFinancedPlan: number;
  monthAmountFinanced: number;
  monthAmountFinancedPlan: number;
  dayAmountFinanced: number;
  yearPremium: number;
  yearPremiumPlan: number;
  monthPremium: number;
  monthPremiumPlan: number;
  dayPremium: number;
  username: string;
}

export async function fetchDailySummaryText({
  selectedDate,
  selectedAgent,
  selectedState,
  selectedSalesExec,
  selectedTerritory,
  yearAmountFinanced,
  yearAmountFinancedPlan,
  monthAmountFinanced,
  monthAmountFinancedPlan,
  dayAmountFinanced,
  yearPremium, 
  yearPremiumPlan,
  monthPremium,
  monthPremiumPlan,
  dayPremium,
  username
}: fetchDailySummaryTextArgs): Promise<any> {
  const requestInfo = await getApiRequestInfo(null);

  // I need a string of comma delimited values for yearAmountFinanced, etc
  const strData = [
    yearAmountFinanced.toFixed(0),
    yearAmountFinancedPlan.toFixed(0),
    monthAmountFinanced.toFixed(0),
    monthAmountFinancedPlan.toFixed(0),
    dayAmountFinanced.toFixed(0),
    yearPremium.toFixed(0),
    yearPremiumPlan.toFixed(0),
    monthPremium.toFixed(0),
    monthPremiumPlan.toFixed(0),
    dayPremium.toFixed(0),
    username
  ].join(",");
  const intermediateUrl = "/dailysummarytext/" + dayjs(selectedDate).format("YYYYMMDD") + "?data=" + strData; 
  console.log("strData: " + strData);
  const   url = buildDashboardUrl(intermediateUrl, selectedAgent, selectedState, selectedSalesExec, selectedTerritory);

  try {
    console.log("In fetchDailySummaryText with selectedDate: " + dayjs(selectedDate).format("YYYYMMDD") + ", yearAmountFinanced: " + yearAmountFinanced)
    return await API.get("idpro", url, requestInfo);

  } catch (error) {
    console.error("error: " + JSON.stringify(error));
    throw new Error("Failed to fetch daily summary text data");
  }

  return null;
}
