import React, { useState, useEffect, useContext, useRef } from "react";
import { Table, TableCell, TableBody, TableHead, TableRow, Button } from "@aws-amplify/ui-react";
import { Auth, API } from "aws-amplify";
import { CircularProgress } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import CreateEditCustomerPopUp from "./CreateEditCustomerPopUp";

import { StateContext } from "../../StateContext";
import { getApiRequestInfo } from "../../api/utils";

const CustomerView = () => {
  const {isSuperAdmin, currentUser} = useContext(StateContext);
  console.log('AdminUsersView isSuperAdmin: ', isSuperAdmin);
  const [customers, setCustomers] = useState([]); // To store matching users
  const [selectedCustomer, setSelectedCustomer] = useState(null); // Currently selected user to edit
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFetchingCustomers, setIsFetchingCustomers] = useState(true);
  const [fetchingUsersMsg, setIsFetchingCustomersMsg] = useState('Fetching Customers...');

	const isUpdating = useRef(false);

	//TODO
  const fetchCustomers = async () => {
    setIsFetchingCustomers(true);

    const requestInfo = await getApiRequestInfo(null);
    const url = '/customers';
    API.get("idpro", url, requestInfo)
    .then(fetchedCustomers => {
      console.log("API fetchedCustomers: \n", fetchedCustomers);
      setCustomers(fetchedCustomers);
      setIsFetchingCustomers(false);
    }).catch(error => {
      console.log("error: " + JSON.stringify(error));
    });
  };

  useEffect(() => {
    // Dummy data fetch function
    const fetchCustomersMock = async () => {
      setIsFetchingCustomers(true);
      await delay(2);
      const filteredUsers = [
				{
					"id": "0001",
					"excluded_agent_ids": "15846,17062,8743,3759,8341",
					"fiscal_year_start_month_day": "1001",
					"name": "SIUPrem, Inc.",
				},
				{
					"id": "0003",
					"excluded_agent_ids": "",
					"fiscal_year_start_month_day": "0101",
					"name": "J&J Premium Finance",
				}
      ]
      setCustomers(filteredUsers);
      setIsFetchingCustomers(false);
    };

    currentUser && fetchCustomers(); //TODO: change to fetchCustomers
  }, [currentUser]);
  
  const handleDoubleClick = (customer) => {
    console.log('User double-clicked:', customer);
    setSelectedCustomer(customer);
		isUpdating.current = true;
		setIsModalOpen(true);
  };

  const handleCreateUser = () => {
    // Logic to handle the creation of a new user
    console.log("Create Customer button clicked");
		isUpdating.current = false;
		setIsModalOpen(true);
  };

  const updateCustomerList = (fetchingMsg) => {
    setIsFetchingCustomersMsg(fetchingMsg);
    fetchCustomers();
  }

  // for testing only
  async function delay(seconds) {
    return new Promise((resolve) => {
      setTimeout(resolve, seconds * 1000);
    });
  }

  return (
    <>
      {!isSuperAdmin && (
        <div>
          {/* empty page for non admins */}
        </div>
      )}
      {
        isSuperAdmin && (
          <>
            <div>
              <h2>Customers</h2>
            </div>
            {isFetchingCustomers ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <CircularProgress size={20} color="inherit" />
                <label className="amplify-label">{fetchingUsersMsg}</label>
              </div>
            ) : (
              <>
                <Table highlightOnHover={true}>
                  <TableHead>
                    <TableRow>
                      <TableCell as="th">ID</TableCell>
                      <TableCell as="th">NAME</TableCell>
                      <TableCell as="th">FISCAL_YEAR</TableCell>
                      <TableCell as="th">EXCLUDED_AGENTS</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customers.map((customer) => (
                      <TableRow 
                        key={customer.id}
                        onDoubleClick={() => handleDoubleClick(customer)}
                      >
                        <TableCell>{customer.id}</TableCell>
                        <TableCell>{customer.name}</TableCell>
                        <TableCell>{customer.fiscal_year_start_month_day}</TableCell>
                        <TableCell>{customer.excluded_agent_ids}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Button onClick={handleCreateUser}>Create Customer</Button>
                </div>
              </>
            )}

						{isModalOpen && (
              <CreateEditCustomerPopUp
                isOpen={isModalOpen}
                setIsOpen={(boolVal) => setIsModalOpen(boolVal)}
                selectedCustomer={selectedCustomer}
                updateCustomerList={updateCustomerList}
								isUpdating={isUpdating.current}
              />
            )}
          </>
        )
      }
    </>
  );
};

export default CustomerView;
