import React, { useEffect } from "react";
import { useState } from "react";
import { Flex, Placeholder } from "@aws-amplify/ui-react";
import { Table, TableBody, TableHead, TableRow, TableCell, SelectField } from "@aws-amplify/ui-react";
import { useQuery } from "react-query";
import {tableHeaderColor} from "../../constants";
import TableCard from "../DashboardCard/TableCard";
import { fetchRevenueDetailsData } from "../../api/utils/fetchRevenueDetailsData/fetchRevenueDetailsData";
import dayjs from "dayjs";
import { DollarValue, toTitleCase } from "../../utils";

interface ReportDashboardFieldsProps {
  startDate: Date;
  endDate: Date;
  agentCode: string;
  state: string | undefined;
  solicitorCode: string | undefined;
  territoryCode: string | undefined;
}

function RevenueDetailsReport({
  startDate,
  endDate,
  agentCode,
  solicitorCode,
  territoryCode,
  state
}: ReportDashboardFieldsProps) {

  const [dataFetching, setDataFetching] = useState(true);
  const [reportData, setReportData] = useState<any[]>([]);
  const [selectedReceivableType, setSelectedReceivableType] = useState<string>("All");

  const title = "Revenue Details Report";

  // gets data for the current fiscal year
  const { isFetching } = useQuery(
    [`revenueDetailsReport`, startDate, endDate, agentCode, solicitorCode, territoryCode, state],
    () =>
      fetchRevenueDetailsData({
        // @ts-ignore
        selectedStartDate: startDate,
        selectedEndDate: endDate,
        startDate,
        endDate,
        agentCode,
        solicitorCode: solicitorCode || "",
        territoryCode: territoryCode || "",
        state: state || ""
      }),
    {
      enabled: !!startDate && !!endDate,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        //console.log('data', data);
        setReportData(data);
      },
    },
  );
  useEffect(() => {
    if (
      isFetching 
    ) {
      setDataFetching(true);
    } else {
      setDataFetching(false);
    }
  }, [
    isFetching
  ]);

  const receivableTypes = Array.from(new Set(reportData.map(item => item.receivable_type)));

  const filteredData = selectedReceivableType === "All" 
    ? reportData 
    : reportData.filter(item => item.receivable_type === selectedReceivableType);

  if (dataFetching) {
    return (
      <Flex direction="column" minHeight="285px" placeholder={undefined}>
        <Placeholder size="small" placeholder={undefined} />
        <Placeholder size="small" placeholder={undefined} />
        <Placeholder size="small" placeholder={undefined} />
        <Placeholder size="small" placeholder={undefined} />
      </Flex>
    );
  }

  return (
    <>
     <TableCard
      title={title || ""}
      selectedDate={dayjs(endDate)}
      key={title}
      tableDataProp={filteredData}
      backgroundColor={undefined}
      noData={reportData.length === 0}
      isLoading={isFetching}
      fullHeight={true}
    >
      {isFetching ? (
       <Flex direction="column" minHeight="285px" placeholder={undefined}>
       <Placeholder size="small" placeholder={undefined} />
       <Placeholder size="small" placeholder={undefined} />
       <Placeholder size="small" placeholder={undefined} />
       <Placeholder size="small" placeholder={undefined} />
     </Flex>
      ) : (
      <Table
        caption=""
        className="compact-table"
        highlightOnHover={true}
        placeholder={undefined}
        size={"small"}>
      <TableHead
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: tableHeaderColor,
        }}
        placeholder={undefined}>

      <TableRow placeholder={undefined}>
        <TableCell as="th" placeholder={undefined}>
          Account
        </TableCell>
        <TableCell as="th" placeholder={undefined}>
          Agent Code
        </TableCell>
        <TableCell as="th" placeholder={undefined}>
          State
        </TableCell>
        <TableCell as="th" placeholder={undefined}>
         Date
        </TableCell>
        <TableCell as="th" style={{ width: '200px' }} placeholder={undefined}>
          <SelectField 
              label=""
              value={selectedReceivableType}
              onChange={(event) => setSelectedReceivableType(event.target.value)}
              style={{ width: '100%', fontSize: '14px', fontWeight: 'bold'}}
            >
              <option value="All">All</option>
              {receivableTypes.map((type) => (
                <option key={type} value={type}>{toTitleCase(type)}</option>
              ))}
            </SelectField>
        </TableCell>
        <TableCell as="th" className="money" placeholder={undefined}>
         Total
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody placeholder={undefined}>
        {filteredData.map((item, index) => {

            return (
              <TableRow key={index} placeholder={undefined}>
                <TableCell placeholder={undefined}>
                  {item.account_number}
                </TableCell>
                <TableCell placeholder={undefined}>
                  {item.agent_code}
                </TableCell>
                <TableCell placeholder={undefined}>
                  {item.origination_state}
                </TableCell>
                <TableCell placeholder={undefined}>
                  {item.enter_date.substring(0, 10)}
                </TableCell>
                <TableCell placeholder={undefined}>
                  {toTitleCase(item.receivable_type)}
                </TableCell>
                <TableCell className="money" placeholder={undefined}>
                  <DollarValue value={item.sum} fractionDigits={2} />
                </TableCell>
              </TableRow>
            );
        })}
        <TableRow placeholder={undefined}>
          <TableCell placeholder={undefined}>
            Total
          </TableCell>
          <TableCell placeholder={undefined}></TableCell>
          <TableCell placeholder={undefined}></TableCell>
          <TableCell placeholder={undefined}></TableCell>
          <TableCell placeholder={undefined}></TableCell>
          <TableCell className="money" placeholder={undefined}>
            <DollarValue
              value={filteredData.reduce(
                (acc, item) => acc + item.sum,
                0
              )}
              fractionDigits={2}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
      )}
    </TableCard>
    </>
  );
}

export default RevenueDetailsReport;
