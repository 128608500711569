import styled from "styled-components";
import { Heading, ScrollView } from "@aws-amplify/ui-react";
import { LinearProgress } from "@mui/material";

export const StyledHeading = styled(Heading)``;

export const StyledTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
`;

export const ChatClientContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  height: calc(100vh - 192px);
  max-width: 60rem;
  
  background: white;
  border: 1px solid #047d95;
  padding-bottom: 2rem;
  border-radius: 15px;
  align-items: center;
`;

export const ChatScrollContainer = styled(ScrollView)`
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding-bottom: 4rem;
  padding-right: 1rem;
  padding-left: 1rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledLinearProgress = styled(LinearProgress)`
  //margin-bottom: 2rem;
  background-color: #047d9542 !important;
  width: 100%;

  & > span {
    background-color: #047d95;
  }
`;
