import React, { useState, useEffect } from 'react';
import { Auth, API } from "aws-amplify";
// import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, CircularProgress } from '@mui/material';
import { getApiRequestInfo } from '../../api/utils';

const CreateEditCustomerPopUp = ({ isOpen, setIsOpen, selectedCustomer, updateCustomerList, isUpdating }) => {
  const [editedCustomer, setEditedCustomer] = useState(isUpdating ? {
    id: selectedCustomer.id,
    name: selectedCustomer.name,
    fiscal_year_start_month_day: selectedCustomer.fiscal_year_start_month_day,
    excluded_agent_ids: selectedCustomer.excluded_agent_ids,
  } : {
    id: '',
    name: '',
    fiscal_year_start_month_day: '',
    excluded_agent_ids: '',
  });
  const [isRequestingSave, setIsRequestingSave] = useState(false);

  const handleClickedSave = async () => {
    const url = '/customers';
    // I want to make a post request and sending a body in json format
    const body = editedCustomer
    console.log('create/update body: ', body);
    const requestInfo = await getApiRequestInfo(body);

    setIsRequestingSave(true);
    if (isUpdating) {
      API.put("idpro", url, requestInfo)
      .then(response => {
        console.log("Updated Customer: ", response);
        updateCustomerList('Fetching Updated Customer...');
        handleCloseDialog();
        setIsRequestingSave(false);
      })
      .catch(error => {
        console.error("Could not create User: ", error);
        //TODO: handle error
        setIsRequestingSave(false);
      });
    } else {
      API.post("idpro", url, requestInfo)
      .then(response => {
        console.log("Created Customer: ", response);
        updateCustomerList('Fetching New Customer...');
        handleCloseDialog();
        setIsRequestingSave(false);
      })
      .catch(error => {
        console.error("Could not create User: ", error);
        //TODO: handle error
        setIsRequestingSave(false);
      });
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const customer = {
        ...editedCustomer,
        [name]: value,
    }
    // console.log('user: ', user);
    setEditedCustomer(customer);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
      <DialogTitle>{isUpdating ? "Updating Customer" : "Create Customer"}</DialogTitle>
      <DialogContent>
        <TextField
          label="ID"
          value={editedCustomer.id}
          onChange={handleInputChange}
          name="id"
          fullWidth
          margin="normal"
          disabled={isUpdating}
        />
        <TextField
          label="Name"
          value={editedCustomer.name}
          onChange={handleInputChange}
          name="name"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Fiscal Year"
          value={editedCustomer.fiscal_year_start_month_day}
          onChange={handleInputChange}
          name="fiscal_year_start_month_day"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Excluded Agents"
          value={editedCustomer.excluded_agent_ids}
          onChange={handleInputChange}
          name="excluded_agent_ids"
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Cancel</Button>
        {isRequestingSave ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <CircularProgress size={20} color="inherit" />
            <label className="amplify-label">Saving...</label>
          </div>
        ) : (
          <Button onClick={() => handleClickedSave(editedCustomer)} disabled={false}>Save</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateEditCustomerPopUp;
