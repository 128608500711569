import styled from "styled-components";
import { Heading } from "@aws-amplify/ui-react";

export const DashboardWrapper = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;
export const StyledHeading = styled(Heading)`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  color: var(--amplify-components-link-color);
`;

export const ChartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
