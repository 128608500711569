import { API, Auth } from "aws-amplify";
import { getApiRequestInfo } from "../../utils";
import dayjs from "dayjs";

interface fetchFiscalYearStartArgs {
  selectedDate: Date;
}

export async function fetchFiscalYearStart({
  selectedDate,
}: fetchFiscalYearStartArgs): Promise<any> {
  const requestInfo = await getApiRequestInfo(null);

  let url = "/config/" + dayjs(selectedDate).format("YYYYMMDD");

  try {
    return await API.get("idpro", url, requestInfo);
  } catch (error) {
    console.error("error: " + JSON.stringify(error));
    throw new Error("Failed to fetch Income data");
  }

  return null;
}
