import React, { SetStateAction, useEffect } from "react";
import { useState } from "react";
import { Flex, Placeholder } from "@aws-amplify/ui-react";
import { Table, TableBody, TableHead, TableRow, TableCell } from "@aws-amplify/ui-react";
import { useQuery } from "react-query";
import {tableHeaderColor} from "../../constants";
import TableCard from "../DashboardCard/TableCard";
import { fetchWriteoffData } from "../../api/utils/fetchWriteoffData/fetchWriteoffData";
import dayjs from "dayjs";
import { DollarValue } from "../../utils";

interface ReportDashboardFieldsProps {
  startDate: Date;
  endDate: Date;
  agentCode: string;
  state: string | undefined;
  solicitorCode: string | undefined;
  territoryCode: string | undefined;
}

function WriteoffReport({
  startDate,
  endDate,
  agentCode,
  solicitorCode,
  territoryCode,
  state
}: ReportDashboardFieldsProps) {

  const [dataFetching, setDataFetching] = useState(true);
  const [reportData, setReportData] = useState<any[]>([]);
  const title = "Writeoff Report";

  // gets data for the current fiscal year
  const { isFetching } = useQuery(
    [`writeOffReport`, startDate, endDate, agentCode, solicitorCode, territoryCode, state],
    () =>
      fetchWriteoffData({
        // @ts-ignore
        selectedStartDate: startDate,
        selectedEndDate: endDate,
        startDate,
        endDate,
        agentCode,
        solicitorCode: solicitorCode || "",
        territoryCode: territoryCode || "",
        state: state || ""
      }),
    {
      enabled: !!startDate && !!endDate,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        //console.log('data', data);
        setReportData(data);
      },
    },
  );
  useEffect(() => {
    if (
      isFetching 
    ) {
      setDataFetching(true);
    } else {
      setDataFetching(false);
    }
  }, [
    isFetching
  ]);

  if (dataFetching) {
    return (
      <Flex direction="column" minHeight="285px" placeholder={undefined}>
        <Placeholder size="small" placeholder={undefined} />
        <Placeholder size="small" placeholder={undefined} />
        <Placeholder size="small" placeholder={undefined} />
        <Placeholder size="small" placeholder={undefined} />
      </Flex>
    );
  }

  return (
    <>
     <TableCard
      title={title || ""}
      selectedDate={dayjs(endDate)}
      key={title}
      tableDataProp={reportData}
      backgroundColor={undefined}
      noData={reportData.length === 0}
      isLoading={isFetching}
      fullHeight={true}
    >
      {isFetching ? (
       <Flex direction="column" minHeight="285px" placeholder={undefined}>
       <Placeholder size="small" placeholder={undefined} />
       <Placeholder size="small" placeholder={undefined} />
       <Placeholder size="small" placeholder={undefined} />
       <Placeholder size="small" placeholder={undefined} />
     </Flex>
      ) : (

      <Table
        caption=""
        highlightOnHover={true}
        placeholder={undefined}
        size={"small"}
        height={"100%"}
      >
      <TableHead
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: tableHeaderColor,
        }}
        placeholder={undefined}>

      <TableRow placeholder={undefined}>
        <TableCell as="th" placeholder={undefined}>
         Date
        </TableCell>
        <TableCell as="th" placeholder={undefined}>
          Account
        </TableCell>
        <TableCell as="th" placeholder={undefined}>
          Policy
        </TableCell>
        <TableCell as="th" placeholder={undefined}>
          Agent Code
        </TableCell>
        <TableCell as="th" placeholder={undefined}>
          State
        </TableCell>
        <TableCell as="th" className="money" placeholder={undefined}>
          Amount
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody placeholder={undefined}>
        {reportData.map((item, index) => {

            return (

              <TableRow key={index} placeholder={undefined}>
                <TableCell placeholder={undefined}>
                  {item.date.substring(0, 10)}
                </TableCell>
                <TableCell placeholder={undefined}>
                  {item.account_number}
                </TableCell>
                <TableCell placeholder={undefined}>
                  {item.policy_number}
                </TableCell>
                <TableCell placeholder={undefined}>
                  {item.agent_code}
                </TableCell>
                <TableCell placeholder={undefined}>
                  {item.origination_state}
                </TableCell>
                <TableCell className="money" placeholder={undefined}>
                  <DollarValue value={item.amount} fractionDigits={2} />
                </TableCell>
              </TableRow>
            );
        })}
        <TableRow placeholder={undefined}>
          <TableCell placeholder={undefined}>
            <b>Total</b>
          </TableCell>
          <TableCell placeholder={undefined}></TableCell>
          <TableCell placeholder={undefined}></TableCell>
          <TableCell placeholder={undefined}></TableCell>
          <TableCell placeholder={undefined}></TableCell>
          <TableCell className="money" placeholder={undefined}>
            <DollarValue
                value={reportData.reduce((acc, item) => acc + item.amount,0)}
                fractionDigits={2}
              />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
      )}
    </TableCard>
    </>
  );
}

export default WriteoffReport;
