import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';

const CurrencyInput = ({ label, value, onChange, isDollarValue=true }) => {

    const [totalValue, setTotalValue] = useState(parseFloat(value));
    const [userInput, setUserInput] = useState('' + value);
    const [formattedValue, setFormattedValue] = useState('' + value);

    useEffect(() => {
        console.log('user effect userInput: ', userInput);
        if (userInput !== '' && isValidNumber(userInput)) {
            const sanitizedInput = userInput.replace(/,/g, '');
            setTotalValue(parseFloat(sanitizedInput));
        } else {
            setTotalValue(0);
        }
    }, [userInput]);

    useEffect(() => {
        setFormattedValue(formatCurrency('' + totalValue));
        onChange(totalValue);
    }, [totalValue]);

    const isValidNumber = (input) => {
        const sanitizedInput = input.replace(/,/g, '').replace(/^\$/, '');
        return !isNaN(sanitizedInput) && !isNaN(parseFloat(sanitizedInput));
    };

    const formatCurrency = (value) => {
        const numValue = Number(value.replace(/,/g, '').replace(/^\$/, ''));
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(numValue);
    };

    const handleChange = (event) => {
        const input = event.target.value.replace(/\$/g, '');
        if (input === '' || isValidNumber(input)){
            setUserInput(input);
        }
    };

    return (
        <>
            {isDollarValue && (
                <TextField
                    label={label}
                    value={'$'+userInput}
                    // value={userInput === '' ? '' : formattedValue}
                    onChange={handleChange}
                    // onFocus={() => setUserInput('')}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                />
            )}
            {!isDollarValue && (
                <TextField
                    label={label}
                    value={userInput}
                    // value={userInput === '' ? '' : formattedValue}
                    onChange={handleChange}
                    // onFocus={() => setUserInput('')}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    type='number'
                />
            )}
        </>
    );
};

export default CurrencyInput;