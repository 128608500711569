import { Auth } from "aws-amplify";
import { FormValues } from "./ChangeUserDetailsForm";

export const handleSubmit = async (
  values: FormValues,
  setSubmitting: (isSubmitting: boolean) => void,
) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      name: values.name,
    });
    setSubmitting(false);
    return;
  } catch (error: any) {
    alert(error.message);
  }
};
