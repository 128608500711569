import styled from "styled-components";
import { Card } from "@aws-amplify/ui-react";


export const DashboardWrapper = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const StyledCard = styled(Card)``;

