import styled from "styled-components";
import { View, Heading } from "@aws-amplify/ui-react";

export const StyledView = styled(View)`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
`;

export const StyledHeading = styled(Heading)`
  margin-top: 0.75rem;
  margin-bottom: 1rem;
  color: var(--amplify-components-link-color);
`;