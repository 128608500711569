import styled from "styled-components";
import { Heading } from "@aws-amplify/ui-react";
import { CircularProgress } from "@mui/material";

export const DashboardWrapper = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;
export const StyledHeading = styled(Heading)`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  color: var(--amplify-components-link-color);
`;

export const ChartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-height: 50vh;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  margin: auto;
  align-self: center;
  justify-self: center;
  path {
    fill: #047d95;
  }

  circle {
    stroke: #047d95;
  }
`;
