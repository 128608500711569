import React, { useEffect, useState, useContext, useCallback } from "react";
import { Outlet } from "react-router-dom";
import { baseConfig } from "../../config";
import { useAuthenticator} from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import SideBar from "../SideBar";
import Header from "../Header";
import Footer from "../Footer";
import Login from "../Login";
import "./Layout.css";
import { API } from "aws-amplify";
import dayjs from "dayjs";
import { getApiRequestInfo } from "../../api/utils";


import { StateContext } from "../../StateContext";

import { debounce, throttle } from 'lodash';
import useActivityMonitor from "../../ActivityMonitor";
import { postWarmUpRequest } from "../../api/utils/postWarmUpRequest/postWarmUpRequest";

const Layout = () => {

  const {setIsSuperAdmin, setIsAdmin, setCurrentUser, superAdminClientId, setClientType, clientId, setClientId} = useContext(StateContext);

  const { user, route } = useAuthenticator((context) => [
    context.route,
  ]);

  const [username, setUsername] = useState('');
  const [customerName, setCustomerName] = useState('');

  const handleUserActivity = throttle(() => {
    if (route === 'authenticated') {
      postWarmUpRequest();
    }
  }, 300000); // reducing the can number can cause err request since it only allows single concurrency for warmup lambda

  // activates the activity monitor
	useActivityMonitor(handleUserActivity);

  useEffect(() => {
    async function getUsername() {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser) {
          setUsername(currentUser.attributes.name);
          setCurrentUser(currentUser);

          if (currentUser.attributes['custom:role'] === 'superadmin') {
            setIsSuperAdmin(true);
            // should be Admin?
          } else if (currentUser.attributes['custom:role'] === 'admin') {
            setIsSuperAdmin(false);
            setIsAdmin(true);
          } else {
            setIsSuperAdmin(false);
            setIsAdmin(false);
          }
          
        } else {
          setUsername(null);
        }
      } catch (error) {
        setUsername('');
        console.log('Error getting username:', error);
      }
    }
    getUsername();

    // Fetch customer name, clientId, and client_type
    async function fetchConfig() {
      try {
        // Replace with your actual API call to fetch the customer name
        var configurl = "/config/" + dayjs().format('YYYYMMDD');
        console.log("configurl: " + configurl);
        const requestInfo = await getApiRequestInfo(null);
        API.get("idpro", configurl, requestInfo)
          .then((response) => {
            console.log("config response: " + JSON.stringify(response));
            setCustomerName(response.name);
            setClientType(response.client_type);
            setClientId(response.client_id);
            
            console.log('Customer name:', response.name);
            console.log('Client type:', response.client_type);
            console.log('Client id:', response.client_id);
          });
      } catch (error) {
        console.log('Error fetching customer name:', error);
      }
    }
    fetchConfig();
  }, [user]);
 
  if (route !== 'authenticated') {
    return (
      <div className="layout-container">
        {baseConfig.header ? <Header username={username} /> : <></>}
        <div className="page-container">
          <Login />
        </div>
      </div>
    );
  }

  return (
    <div className="layout-container">
      {baseConfig.header ? <Header username={username} customername={customerName}/> : <></>}

      <SideBar/>

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <div className="page-container">
        {/* uses ky prop to force rerender when superAdminClientId changes*/}
        <Outlet key={superAdminClientId} />
      </div>

      {baseConfig.footer ? <Footer /> : <></>}
    </div>
  );
};

export default Layout;
