
import { ChatHistoryMessageType, AIInsightContentType } from "../types";
import ChatMessageData from "../ChatMessageData/ChatMessageData";
import { StyledMessageWrapper } from "./styles";
import { isAIInsightContentType } from "../utils";

interface ChatMessageProps {
  conversationTitle: string;
  chatItem: ChatHistoryMessageType
}

export const ChatMessage = ({ conversationTitle, chatItem }: ChatMessageProps) => {

  
  if (chatItem.type === "loading") {
    return null;
  }

  // handles type difference of user content vs aiinsight content
  let content, query;
  if (typeof chatItem.content === 'string') {
    content = chatItem.content;
  } else {
    content = chatItem.content.chatResponse;
    query = chatItem.content.query;
  }

  // return (
  //   <>
  //     {chatItem.role !== "system" && (
  //       <StyledMessageWrapper
  //         isGPT={chatItem.role === "assistant"}
  //         isError={chatItem.type === "gptResponseError"}
  //       >
  //         <p>
  //           <strong>{chatItem.role === "user" ? "You" : "ChatPFC"}:</strong>
  //           <br />
  //           content
  //         </p>

  //         {Array.isArray(chatItem.tableData) && 
  //         isAIInsightContentType(chatItem.content) && 
  //         chatItem.content.query && chatItem.content.query.trim() !== '' && (
  //           <ChatMessageData conversationTitle={conversationTitle} data={chatItem.tableData} />
  //         )}

  //       </StyledMessageWrapper>
  //     )}
  //   </>
    
  // );

  return (
    <>
      {chatItem.role !== "system" && (
        <StyledMessageWrapper
          isGPT={chatItem.role === "assistant"}
          isError={chatItem.type === "gptResponseError"}
        >
          <p>
            <strong>{chatItem.role === "user" ? "You" : "ChatPFC"}:</strong>
            <br />
            {content}
          </p>
  
          {/* {(!!chatItem.tableData?.length || chatItem.tableData?.length === 0) && typeof chatItem.content === AIInsightContentType && (
            <ChatMessageData conversationTitle={conversationTitle} data={chatItem.tableData} />
          )} */}
  
          {Array.isArray(chatItem.tableData) &&
            isAIInsightContentType(chatItem.content) &&
            chatItem.content.query &&
            chatItem.content.query.trim() !== '' && (
              <ChatMessageData conversationTitle={conversationTitle} data={chatItem.tableData} />
            )}
        </StyledMessageWrapper>
      )}
    </>
  );
  
};
