export function buildDashboardUrl(
  baseUrl: string,
  agentCode: string | undefined,
  state: string | undefined,
  solicitorCode: string | undefined,
  territoryCode: string | undefined
) {
  let url = baseUrl;
  let hasParams = url.includes("?");

  if (agentCode && agentCode !== "All") {
    url += (hasParams ? "&" : "?") + "agent_code=" + agentCode;
    hasParams = true;
  }
  if (state && state !== "All") {
    url += (hasParams ? "&" : "?") + "state=" + state;
    hasParams = true;
  }
  if (solicitorCode && solicitorCode !== "All") {
    url += (hasParams ? "&" : "?") + "solicitor_code=" + solicitorCode;
  }
  if (territoryCode && territoryCode !== "All") {
    url += (hasParams ? "&" : "?") + "territory_code=" + territoryCode;
  } 
  // console.log("buildDashboardUrl: ", url);
  return url;
}
