import { API } from "aws-amplify";
import { getApiRequestInfo } from "../../utils";

interface fetchRankingDataArgs {
  chatHistoryId?: string;
  userMessage?: string;
  isConsecutiveRequest?: boolean;
  logHistory?: boolean
}

export async function fetchGptChatResponse({
  chatHistoryId,
  userMessage,
  isConsecutiveRequest,
  logHistory,
}: fetchRankingDataArgs): Promise<any> {
  // remove parts o the chat history that are not needed for chatGPT eg. tableData

  const requestInfo = await getApiRequestInfo({
    chatHistoryId: chatHistoryId ?? null,
    userMessage: userMessage ?? null,
    isConsecutiveRequest: isConsecutiveRequest ?? null,
    logHistory: logHistory ?? null,
  });
  console.log('send request with requestInfo\n', requestInfo);
  const url = "/aiinsight";

  try {
    return await API.post("idpro", url, requestInfo);
  } catch (error: unknown) {
    console.error("Error querying based on GPT's response: ", error, typeof error);
    console.log("error log ", error, typeof error);

    if (typeof error === 'object' && error !== null && 'response' in error) {
      return { chatHistoryTitle: null, query: null, chatResponse: null, needConsecutiveRequest: true, hadFailure: true }
    }

    throw new Error("Failed to fetch Redshift Insights");
  }
}
