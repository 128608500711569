import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import ChangeUserDetailsForm from "../../components/Profile/changeUserDetailsForm/ChangeUserDetailsForm";
import ChangePasswordForm from "../../components/Profile/changePasswordForm/ChangePasswordForm";
import { SectionWrapper, StyledHeading } from "./styles";

export function Profile() {
  const { route } = useAuthenticator((context) => [context.route]);
  const message = route === "authenticated" ? "My Profile" : "Loading...";

  return (
    <>
      <StyledHeading level={4} placeholder={undefined}>
        {message}
      </StyledHeading>
      <SectionWrapper>
        <StyledHeading level={4} placeholder={undefined}>
          {"User Details"}
        </StyledHeading>

        <ChangeUserDetailsForm />
      </SectionWrapper>
      <SectionWrapper>
        <StyledHeading level={4} placeholder={undefined}>
          {"Change Password"}
        </StyledHeading>
        <ChangePasswordForm />
      </SectionWrapper>
    </>
  );
}
